<template>
  <div class="calibration-peaks">
    <div v-if="peaks && peaks.length > 0" class="calibration-peaks__wrapper-table">
      <table class="table-peaks">
        <thead class="table-peaks__thead">
          <tr>
            <th align="left">#</th>
            <th align="left">Time</th>
            <th align="right" style="white-space: nowrap; text-align: right">Peak area</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody class="table-peaks__tbody">
          <tr
            v-for="(i, index) in peaks"
            :key="i.id"
            :class="{
              'tr--selected': i.id === selectedPeakID,
              'tr--highlighted': i.id === highlightedPeakId,
            }"
            @mouseenter="onMouseEnter(i.id)"
            @mouseleave="onMouseLeave(i.id)"
          >
            <td>
              <span class="font-monospace" :class="{ 'font-40': i.id !== selectedPeakID }">
                {{ index + 1 }}
              </span>
            </td>
            <td class="font-monospace">
              {{ i.apex }}
            </td>
            <td align="right" class="font-monospace font-bold text-info">
              {{ i.area }}
            </td>
            <td style="padding-left: 64px">
              <button v-if="i.id !== selectedPeakID" @click="peakSelect(i.id)">Select</button>
              <div v-else>
                <i class="material-icons material-icon--16">check</i>
                Selected
              </div>
            </td>
            <td style="padding-left: 0" align="right">
              <button @click="peakRemove(i.id)">
                <i
                  class="material-icons material-icon--16"
                  :class="{ 'font-40': i.id !== selectedPeakID }"
                >
                  close
                </i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <nothing-there-component v-else style="margin-top: 64px">
      No peaks. Press '<span class="calibration-peaks__label-integration-type">A∫</span>' to find
      peaks automatically. You can also press '<span
        class="calibration-peaks__label-integration-type"
        >M∫</span
      >' to enable manual peak integration and draw a line to add a peak.
    </nothing-there-component>
  </div>
</template>

<script>
  import _ from 'lodash';
  import NothingThereComponent from 'components/element/NothingThereComponent.vue';

  const EVENT_SELECT_PEAK = 'selectPeak';

  export default {
    name: 'CalibrationPeaksComponent',

    components: {
      NothingThereComponent,
    },

    props: {
      ppeaks: Array,
      measurementSocket: Object,
      injectionSocket: Object,
      selectedPeakID: Number,
      highlightedPeakId: Number,
    },

    data() {
      return {
        peaks: this.modifyPeaks(this.ppeaks),
        selectedPeakId: null,
      };
    },

    watch: {
      ppeaks() {
        this.setPeaks(this.ppeaks);
      },
      selectedPeakID: {
        async handler(newValue, oldValue) {
          await this.$nextTick();
          oldValue && this.emitSelectionEvent(oldValue, false);
          this.emitSelectionEvent(newValue, true);
        },
        immediate: true,
      },
    },

    methods: {
      emitSelectionEvent(peakId, isSelected) {
        this.$emit('selection', { peakId, isSelected });
      },

      modifyPeaks(peaks) {
        if (peaks == null) return null;
        // eslint-disable-next-line no-param-reassign
        peaks = _.cloneDeep(peaks);
        for (let i = 0; i < peaks.length; i++) {
          // eslint-disable-next-line no-param-reassign
          peaks[i].apex = peaks[i].apex.toFixed(3);
          // eslint-disable-next-line no-param-reassign
          peaks[i].area = peaks[i].area.toFixed(3);
        }
        return peaks;
      },

      onMouseEnter(peakId) {
        this.emitSelectionEvent(peakId, true);
      },
      onMouseLeave(peakId) {
        if (this.selectedPeakID !== peakId) {
          this.emitSelectionEvent(peakId, false);
        }
      },

      setPeaks(peaks) {
        this.peaks = this.modifyPeaks(peaks);
      },

      peakSelect(peakId) {
        this.showNotificationIfRpcError(async () => {
          const {
            injection: { peak_id, peak_area, peak_apex_time_str, id },
          } = await this.injectionSocket.peakSelect(peakId);
          this.$emit(EVENT_SELECT_PEAK, { peak_id, peak_area, peak_apex_time_str, id });
        });
      },

      peakRemove(id) {
        this.showNotificationIfRpcError(() => this.measurementSocket.peakRemove(id));
      },
    },
  };
</script>

<style lang="scss" scoped>
  .calibration-peaks {
    &__wrapper-table {
      overflow-x: auto;
    }

    &__label-integration-type {
      font-weight: $weight-bold;
    }
  }

  .table-peaks {
    width: 100%;
    height: 1px;
  }

  .table-peaks button {
    display: block;
    visibility: hidden;
    min-width: 16px;
    padding: 4px 8px;
    margin: 0;
    background: transparent;
    color: $color-text-primary;
  }

  .table-peaks button:hover {
    background: #00000022;
  }

  .table-peaks button:active {
    background: #00000044;
  }

  .table-peaks input {
    border: 0 solid transparent;
    border-bottom-width: 2px;
    background: transparent;
    border-radius: 3px;
    padding: 6px 6px 0 6px;
    font-size: 13px;
  }

  .table-peaks input:focus {
    border-width: 0 0 2px 0;
    border-color: $color-bg-primary;
    outline: none !important;
  }

  .table-peaks__thead th {
    font-size: 13px;
    font-weight: bold;
    color: #00000066;
    padding: 2px 8px 6px 8px;
  }

  .table-peaks__thead th:first-child {
    padding-left: 32px;
  }

  .table-peaks__thead th:last-child {
    padding-right: 32px;
  }

  .table-peaks__tbody td {
    font-size: 13px;
    white-space: nowrap;
    padding: 4px 8px 3px 8px;
    width: 1px;
  }

  .table-peaks__tbody td:first-child {
    padding-left: 32px;
  }

  .table-peaks__tbody td:last-child {
    padding-right: 32px;
  }

  .table-peaks__tbody tr:hover button {
    visibility: visible;
  }

  .table-peaks__tbody tr:nth-child(2n-1) {
    background-color: #00000008;
  }

  .table-peaks__tbody tr:hover,
  .table-peaks__tbody tr.tr--highlighted {
    background-color: #00000011;
  }

  .table-peaks__tbody input {
    padding-bottom: 4px;
    height: 24px;
  }

  .table-peaks__tbody tr input::placeholder {
    color: #00000000;
  }

  .table-peaks__tbody tr:hover input::placeholder {
    color: #00000066;
  }

  .tr--selected {
    background: #2a2398 !important;
    color: #ffffff !important;
  }

  .tr--selected td {
    background: #2a2398 !important;
    color: #ffffff !important;
  }

  .tr--selected button {
    color: #ffffff !important;
  }
</style>
