<template>
  <div ref="wrapper" class="chromatogram">
    <div style="position: relative; width: 100%" class="chromatogram__wrapper-chart" @click.stop>
      <div ref="chart" class="chromatogram__chart" />

      <Btn
        v-if="hasData && toolbar && !isShowEditPanel"
        padding="xl"
        class="chromatogram__btn-show-edit-panel"
        @click="showEditPanel"
      >
        <IconMaterial title="build" class="chromatogram__icon-build" />
      </Btn>
    </div>

    <div v-if="hasData && toolbar && isShowEditPanel" class="chromatogram__wrapper-edit-panel">
      <ChromatogramEdit
        :id="NaN"
        :hasJustOneChart="false"
        mode="zoom"
        :isLoadingFindPeaks="false"
        :hasBaseline="false"
        :isFullChart="!isApplyDetectionTime"
        :hasDetectionTime="hasDetectionTime"
        :isEditable="editable"
        :canCompare="editable"
        class="chromatogram__edit-panel"
        @showFullChart="$emit('showFullChart')"
        @showDetectionTime="$emit('showDetectionTime')"
        @updateAppearance="updateAppearance"
        @close="hideEditPanel"
      />
    </div>
  </div>
</template>

<script>
  import resize from 'vue-resize-directive';

  import { ChromatogramDrawer } from '@/uikitProject/charts/new/ChromatogramDrawer';
  import { isInjectionInProgress } from '@/utils/injectionHelpers';
  import ChromatogramEdit from '@/uikitProject/chromatogram/panels/vueChromatogramEdit/ChromatogramEdit.vue';
  import Btn from '@/uikitBase/btns/Btn.vue';
  import IconMaterial from '@/uikitBase/icons/IconMaterial.vue';
  import { persistentStorage, PersistentStorageKeys } from '@/utils/persistentStorage';

  const EVENT_CREATED = 'created';
  const EVENT_ZOOM = 'zoom';
  const EVENT_ZOOM_ACTIVATION = 'zoomActivation';

  export default {
    name: 'PrChartChromatogramMultiple',

    components: { IconMaterial, Btn, ChromatogramEdit },

    directives: { resize },

    props: {
      measurements: {
        type: Array,
        required: true,
      },
      timeMin: {
        type: Number,
        default: 0,
      },
      // Draw chart on the plot not smaller than 'timeMax', value in minutes
      timeMax: {
        type: Number,
      },
      // measurementColors: {
      //   type: Object,
      // },
      // hasOnlyHorizontalZoom: {
      //   type: Boolean,
      // },
      // doShowExactBounds: {
      //   type: Boolean,
      // },
      // hasMauScale: {
      //   type: Boolean,
      // },
      //
      // hasSpacesIfOutOfDetectionTime: {
      //   type: Boolean,
      // },
      isApplyDetectionTime: {
        type: Boolean,
      },
      detectionTime: {
        type: Object,
      },
      postprocessings: {
        type: Array,
      },
      //
      // creationDate: {
      //   type: Date,
      // },

      injection: {
        type: Object,
      },
      method: {
        type: Object,
      },
      width: {
        type: Number,
      },
      editable: { type: Boolean, default: true },
      toolbar: { type: Boolean, default: true },

      isApplyPostprocessingSignal: {
        type: Object,
      },

      initialZoom: {
        type: Object,
      },

      isFetchingData: {
        type: Boolean,
        required: true,
      },

      hasZoomActivationOverlay: {
        type: Boolean,
        required: true,
      },
    },

    data: () => ({
      drawer: null,

      isShowEditPanel: true,
    }),

    computed: {
      minutesMin() {
        return this.privateTimeMin;
      },
      // Максимальное значение по оси X (время)
      minutesMax() {
        return this.privateTimeMax;
      },

      privateTimeMin() {
        if (!this.isApplyDetectionTime) {
          return this.timeMin;
        }

        return this.detectionTime?.start ?? this.timeMin;
      },
      privateTimeMax() {
        if (!this.isApplyDetectionTime) {
          return this.timeMax;
        }

        if (this.detectionTime && this.detectionTime.end) {
          return this.timeMax
            ? Math.min(this.detectionTime.end, this.timeMax)
            : this.detectionTime.end;
        }
        return this.timeMax;
      },
      hasData() {
        return this.measurements?.some((measurement) => measurement?.data?.length > 1);
      },
      hasDetectionTime() {
        return Boolean(this.detectionTime && (this.detectionTime.start || this.detectionTime.end));
      },
    },

    watch: {
      minutesMin() {
        this.refresh();
      },
      minutesMax() {
        this.refresh();
      },
      hasMauScale() {
        this.refresh();
      },
      measurements() {
        this.refresh();
      },
      measurementColors() {
        this.refresh();
      },
      width(value) {
        this.drawer?.actions.setWidth(value);
      },
      isApplyDetectionTime(value) {
        this.drawer?.actions.setIsApplyDetectionTime(value);
      },
      isApplyPostprocessingSignal() {
        this.drawer?.actions.setIsShowBaseline(!this.isApplyPostprocessingSignal.value);
        this.drawer?.actions.setIsShowPeaks(this.isApplyPostprocessingSignal.value);
        this.refresh();
      },
    },

    created() {
      this.$emit(EVENT_CREATED);
    },

    mounted() {
      this.initChart();
    },

    methods: {
      // Init
      initChart() {
        const { hasGridLines, hasTooltipOnHover } = this.getAppearance();

        this.drawer = new ChromatogramDrawer({
          container: this.$refs.chart,
          measurements: this.measurements,
          expectedDurationSeconds:
            this.method?.run_time_min * 60 ?? this.injection.expected_duration_sec,
          detectionTime: this.detectionTime
            ? {
                start: this.detectionTime.start,
                end: this.detectionTime.end,
              }
            : null,
          isInProgress: isInjectionInProgress(this.injection),
          isFetchingData: this.isFetchingData,
          isApplyBaseline: !this.isApplyPostprocessingSignal.value,
          widthPx: this.$refs.wrapper.getBoundingClientRect().width,
          hasGridLines,
          hasTooltipOnHover,
          zoomBounds: this.initialZoom,
          hasZoomActivationOverlay: this.hasZoomActivationOverlay,
        });

        this.drawer.on('zoom', (zoomBounds) => {
          this.$emit(EVENT_ZOOM, zoomBounds);
        });

        this.drawer.on('zoomActivation', () => {
          this.$emit(EVENT_ZOOM_ACTIVATION);
        });
      },

      refresh() {
        if (
          this.measurements?.some(
            (measurement) => measurement.data == null || measurement.baseline == null,
          )
        ) {
          return;
        }

        if (this.drawer) {
          this.drawer.actions.setIsInProgress(isInjectionInProgress(this.injection));
          this.drawer.actions.setIsFetchingData(this.isFetchingData);
          this.drawer.actions.setDetectionTime(
            this.detectionTime
              ? {
                  start: this.detectionTime.start,
                  end: this.detectionTime.end,
                }
              : null,
          );
          this.drawer.actions.setExpectedDurationSeconds(
            this.method?.run_time_min * 60 ?? this.injection.expected_duration_sec,
          );
          this.drawer.refresh(this.measurements);
        } else {
          this.initChart();
        }
      },

      // Zoom
      resetZoom() {
        // this.svgChart.resetZoom();
      },

      showEditPanel() {
        this.isShowEditPanel = true;
      },
      hideEditPanel() {
        this.isShowEditPanel = false;
      },

      getAppearance() {
        return (
          persistentStorage.get(PersistentStorageKeys.CHROMATOGRAM_APPEARANCE) ?? {
            hasGridLines: false,
            hasTooltipOnHover: false,
          }
        );
      },

      updateAppearance(appearance) {
        this.drawer?.actions.setHasGridLines(appearance.hasGridLines);
        this.drawer?.actions.setHasTooltipOnHover(appearance.hasTooltipOnHover);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chromatogram {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &__preferences {
    }

    &__wrapper-chart {
      position: relative;
      width: 100%;
    }

    &__chart {
      width: 100%;
      margin: 0;
      height: 300px;
    }

    &__wrapper-edit-panel {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 10px 0;
      padding-top: 16px;

      @media print {
        display: none;
      }

      @media (max-width: $screen-xs-max) {
        overflow-x: auto;
        justify-content: flex-start;

        &::before,
        &::after {
          content: '';
          display: block;
          width: 16px;
          flex: none;
        }
      }
    }
  }
</style>
