import SocketCentrifugeBase from '@/api/sockets/SocketCentrifugeBase';
import { Callback, Dict } from '@/types/utility';

export enum MeasurementSocketEvents {
  PEAKS = 'measurement.peaks',
  NEW_VALUES = 'measurement.newValues',
}

enum MeasurementSocketRPC {
  PEAK_REMOVE = 'measurement.peakRemove',
  PEAK_LIST_REMOVE = 'measurement.peaksListRemove',
  PEAK_ADD = 'measurement.peakAdd',
  ESTIMATION_UPDATE = 'measurement.estimationUpdate',
  ESTIMATION_DELETE = 'measurement.estimationDelete',
  APPLY_CALIBRATION = 'measurement.applyCalibration',
}

export default class MeasurementSocket extends SocketCentrifugeBase<
  MeasurementSocketEvents,
  MeasurementSocketRPC
> {
  private static connections: Dict<MeasurementSocket> = {};

  protected listeners = {
    // TODO Try to remove after the future TS updates. Now TS version is 4.6.3
    // [] as Callback[] is a hack to fix a TS bug. Otherwise, the type of the abstract member will be set to never[]
    [MeasurementSocketEvents.PEAKS]: [] as Callback<[data: object]>[],
    [MeasurementSocketEvents.NEW_VALUES]: [] as Callback<[from: number, values: number[]]>[],
  };

  private constructor(
    public id: number,
    onConnect?: Callback<[SocketCentrifugeBase<MeasurementSocketEvents, MeasurementSocketRPC>]>,
  ) {
    super(`personal:measurement-${id}`, {
      handleEvents: ({ data }) => {
        switch (data.event) {
          case MeasurementSocketEvents.PEAKS: {
            this.callListeners(MeasurementSocketEvents.PEAKS, data);
            break;
          }
          case MeasurementSocketEvents.NEW_VALUES:
            this.callListeners(MeasurementSocketEvents.NEW_VALUES, data.from, data.values);
            break;
        }
      },
      onConnect,
    });

    this.dataAddedToEveryRequest = { measurement_id: this.id };
  }

  public static start(
    id: number,
    onConnect?: Callback<[SocketCentrifugeBase<MeasurementSocketEvents, MeasurementSocketRPC>]>,
  ) {
    const connection = MeasurementSocket.connections[id];
    if (connection) {
      connection.addSession();
      onConnect?.(connection);
      return connection;
    }

    MeasurementSocket.connections[id] = new MeasurementSocket(id, onConnect);
    return MeasurementSocket.connections[id];
  }

  public close(listenersGroupId?: string): boolean {
    const isClosed = super.close(listenersGroupId);

    if (isClosed) {
      delete MeasurementSocket.connections[this.id];
    }

    return isClosed;
  }

  public peakRemove(id) {
    return this.sendNamedRPC(MeasurementSocketRPC.PEAK_REMOVE, { id });
  }

  public peaksListRemove(ids) {
    return this.sendNamedRPC(MeasurementSocketRPC.PEAK_LIST_REMOVE, { ids });
  }

  public peakAdd(start, end) {
    return this.sendNamedRPC(MeasurementSocketRPC.PEAK_ADD, { start, end });
  }

  public saveEstimation(estimation) {
    return this.sendNamedRPC(MeasurementSocketRPC.ESTIMATION_UPDATE, {
      estimation,
    });
  }

  public applyCalibration(peakID, calibrationID) {
    return this.sendNamedRPC(MeasurementSocketRPC.APPLY_CALIBRATION, {
      peak_id: peakID,
      calibration_id: calibrationID,
    });
  }

  public deleteEstimation(peakID) {
    return this.sendNamedRPC(MeasurementSocketRPC.ESTIMATION_DELETE, {
      id: peakID,
    });
  }
}
