var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sequence-table",class:{
    'sequence-table--dragdrop-active': _vm.isDragging,
    'sequence-table--dragdrop--disabled': _vm.disableDragDrop,
  }},[_c('table',{staticClass:"table-default table-default--offset-32 table"},[_c('thead',{staticStyle:{"background-color":"#0000000a"}},[_c('tr',[_c('th'),_c('th',[_vm._v("Sample name")]),_vm._m(0),(!_vm.isCromite)?_c('th',{staticClass:"right",staticStyle:{"border-left":"#ddd solid 2px","padding-left":"8px"}},[_vm._v(" Vial ")]):_vm._e(),_c('th',{staticStyle:{"text-align":"right"}},[_vm._v("Repeat")]),_c('th',{staticStyle:{"padding-right":"0"}},[_vm._v("Run")]),_c('th')])]),_c('Draggable',{staticClass:"tbody__td--nowrap table__tbody",attrs:{"animation":350,"disabled":_vm.disableDragDrop,"fallbackOnBody":false,"fallbackTolerance":1,"forceFallback":true,"scroll":true,"scrollSensitivity":100,"scrollSpeed":5,"dragClass":"sequence-table__dragdrop-dragging","draggable":".sequence-table__row--draggable","easing":"cubic-bezier(1, 0, 0, 1)","ghostClass":"sequence-table__dragdrop-placeholder","group":"sequences","handle":".sequence-table__draggable","tag":"tbody"},on:{"end":_vm.finishDrag,"start":_vm.startDrag},model:{value:(_vm.tableRows),callback:function ($$v) {_vm.tableRows=$$v},expression:"tableRows"}},[_vm._l((_vm.tableRows),function(d){return _c('tr',{key:((d.type) + "-" + (d.id)),staticClass:"row--clickable sequence-table__row",class:{
          'sequence-table__row--draggable': !_vm.draftDisableEdit(d),
        }},[_c('td',{staticClass:"td--shrink",staticStyle:{"padding-right":"9px","position":"relative"}},[_c('div',{staticClass:"sequence-table__draggable",class:{
              'sequence-table__draggable--disabled': _vm.draftDisableEdit(d),
            }},[_c('IconMaterial',{staticClass:"sequence-table__draggable-icon",attrs:{"title":"drag_indicator"}})],1),(Boolean(d.sample) || _vm.draftDisableEdit(d))?_c('IconMaterial',{staticStyle:{"padding-left":"7px"},attrs:{"title":d.type === 'S' ? 'opacity' : 'star',"size":16}}):_vm._e()],1),_c('td',{staticClass:"sequence-table__cell-name"},[(_vm.draftDisableEdit(d))?[(_vm.isCurrentDraft(d.id))?_c('b',[_vm._v(" "+_vm._s(d.sample != null ? _vm.samples[d.sample].name : d.name)+" ")]):_c('span',[_vm._v(_vm._s(d.sample != null ? _vm.samples[d.sample].name : d.name))])]:_c('Select',{staticStyle:{"width":"100%"},attrs:{"itemSelected":_vm.samples[d.sample],"items":_vm.samplesWithoutArchived,"labelProp":"name","isTransparent":true,"hasSearch":true,"placeholderSearch":"Search sample","titleBottomSheet":"Search sample"},on:{"update:itemSelected":function($event){return _vm.changeSample(d, $event)}},scopedSlots:_vm._u([{key:"leftSideInsideItem",fn:function(ref){
            var item = ref.item;
return [_c('PackIconsEntity',{attrs:{"entity":item.type === 'S' ? 'sample' : 'calibration'}})]}}],null,true)})],2),_c('td',{staticClass:"text-gray sequence-table__cell-method",staticStyle:{"text-align":"left"}},[_c('PopupInfoMethodColumn',{staticStyle:{"height":"32px","width":"100%","max-width":"350px"},attrs:{"hasMethodName":true,"method":_vm.getDraftMethod(d),"isDisabled":_vm.isOpenMethodSelect || !_vm.getDraftMethod(d)}},[_c('SelectMethod',{ref:"selectMethod",refInFor:true,attrs:{"methodId":d.method,"preloadedMethod":_vm.getDraftMethod(d),"isDisabled":_vm.draftDisableEdit(d),"isEditable":_vm.hasPermissionToEdit,"configuration":_vm.configurationForMethod},on:{"update:method":function($event){return _vm.changeMethod(d, $event)},"showPopup":function($event){return _vm.onMethodSelectPopupStateChanged(true)},"hidePopup":function($event){return _vm.onMethodSelectPopupStateChanged(false)},"createMethod":function($event){return _vm.showCreateMethodModal(d)},"editMethod":function($event){return _vm.showEditMethodModal($event, d)}}})],1)],1),(!_vm.isCromite)?_c('td',{staticClass:"td--shrink text-gray td--separate",class:{
            'td--separate-executing': _vm.isCurrentDraft(d.id) && _vm.current.state === 'EXE',
            'td--separate-paused': _vm.isCurrentDraft(d.id) && _vm.current.state === 'PAU',
          }},[(_vm.draftDisableEdit(d))?_c('div',{staticStyle:{"width":"40px"}},[_vm._v(" "+_vm._s(d.vial)+" ")]):_c('InputVials',{staticStyle:{"display":"inline-block"},attrs:{"trayConfig":_vm.trayConfig,"placement":"bottom-center","isLazy":true},on:{"model":function($event){return _vm.saveDraft(d)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var vial = ref.vial;
          var setVial = ref.setVial;
          var isMobile = ref.isMobile;
          var handleBlur = ref.handleBlur;
return [_c('input',{staticClass:"font-monospace",staticStyle:{"width":"40px","text-align":"right"},attrs:{"disabled":isMobile},domProps:{"value":vial},on:{"input":function($event){return setVial($event.target.value)},"blur":handleBlur}})]}}],null,true),model:{value:(d.vial),callback:function ($$v) {_vm.$set(d, "vial", $$v)},expression:"d.vial"}})],1):_vm._e(),_c('td',{staticClass:"td--shrink text-gray",staticStyle:{"text-align":"right"}},[(_vm.isCurrentDraft(d.id))?_c('div',{staticClass:"sequence-table__cell-repeat"},[_c('StatesForEntity',{staticStyle:{"margin":"0 8px 0 0","display":"inline-block"},attrs:{"has-title":false,"hasTitleOnAdaptive":false,"state":_vm.current.state}}),_c('span',{class:{
                'text-process': _vm.current.state === 'EXE',
                'text-yellow': _vm.current.state === 'PAU',
                'text-danger': _vm.current.state === '-',
              },staticStyle:{"font-weight":"bold","margin-left":"auto"}},[_vm._v(" "+_vm._s(_vm.current.iteration)+" of "+_vm._s(d.repeat)+" ")])],1):(_vm.draftDisableEdit(d))?_c('div',{staticClass:"sequence-table__cell-repeat"},[(d.number_of_terminated_injections !== d.repeat)?_c('StatesForEntity',{staticStyle:{"margin":"0 8px 0 0","display":"inline-block"},attrs:{"hasTitle":false,"hasTitleOnAdaptive":false,"state":"FAI"}}):_vm._e(),_c('span',{staticClass:"sequence-table__run-counter"},[_vm._v(_vm._s(d.number_of_terminated_injections)+" of "+_vm._s(d.repeat))])],1):_c('input',{directives:[{name:"model",rawName:"v-model.number.lazy",value:(d.repeat),expression:"d.repeat",modifiers:{"number":true,"lazy":true}}],staticClass:"font-monospace",staticStyle:{"width":"40px","text-align":"right","display":"inline-block"},attrs:{"max":"1000","min":"1","type":"number"},domProps:{"value":(d.repeat)},on:{"change":[function($event){_vm.$set(d, "repeat", _vm._n($event.target.value))},function($event){return _vm.saveDraft(d)}],"blur":function($event){return _vm.$forceUpdate()}}})]),_c('td',{staticClass:"td--shrink text-gray",staticStyle:{"padding-right":"0","vertical-align":"middle","text-align":"center"}},[_c('div',{staticClass:"sequence-table__wrapper-checkbox"},[_c('label',{staticClass:"sequence-table__wrapper-enabled"},[(
                  _vm.isCurrentDraft(d.id) ||
                  (d.sample_injection && d.number_of_terminated_injections === d.repeat)
                )?_c('Checkbox',{attrs:{"checked":true,"isDisabled":_vm.isCurrentDraft(d.id) ||
                  (d.sample_injection && d.number_of_terminated_injections === d.repeat)}}):_c('Checkbox',{attrs:{"isDisabled":!_vm.isValidDraft(d)},on:{"change":function($event){return _vm.saveDraft(d)}},model:{value:(d.enabled),callback:function ($$v) {_vm.$set(d, "enabled", $$v)},expression:"d.enabled"}})],1),(_vm.draftDisableEdit(d))?_c('BtnIcon',{staticClass:"sequence-table__btn-switcher",attrs:{"iconMaterial":"visibility"},on:{"click":function($event){return _vm.$emit('view', d)}}}):_vm._e()],1)]),_c('td',{staticClass:"td--shrink text-gray",staticStyle:{"padding":"0"}},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.draftDisableEdit(d) && _vm.tableRows.length > 1),expression:"!draftDisableEdit(d) && tableRows.length > 1"}],staticClass:"material-icons material-icon--14 transparent compact sequence-table__button-delete",on:{"click":function($event){return _vm.deleteDraft(d)}}},[_vm._v(" clear ")])])])}),_c('tr',{staticClass:"sequence-table__row-add",attrs:{"slot":"footer"},slot:"footer"},[_c('td',{staticClass:"td--shrink content sequence-table__cell-add",attrs:{"colspan":!_vm.isAllDraftsEnabled ? 3 : 7},on:{"click":function($event){return _vm.$emit('add')}}},[_c('IconMaterial',{staticClass:"sequence-table__icon-add",attrs:{"title":"add","size":"16"}}),_vm._v(" Add a new line ")],1),(!_vm.isAllDraftsEnabled)?_c('td',{staticClass:"td--shrink td--separate",attrs:{"colspan":"4"}},[_c('Btn',{attrs:{"height":"s"},on:{"click":function($event){$event.stopPropagation();return _vm.enableAllDrafts($event)}}},[_vm._v("Select all")])],1):_vm._e()])],2)],1),_c('MethodEditModal',{attrs:{"method":_vm.methodForEdit,"configuration":_vm.configurationForMethod,"deviceId":_vm.device.id},on:{"created":_vm.handleMethodCreation,"update:methodForm":_vm.handleMethodUpdate}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_c('span',{staticClass:"sequence-table__th-method"},[_c('span',[_vm._v("Method")]),_vm._v(" "),_c('span',[_vm._v("Method ID")])])])}]

export { render, staticRenderFns }