<template>
  <div class="device-method-column-inputs">
    <ApiDeviceSelector
      :deviceSelected="model.device"
      v-bind="{ isRequired, isReadonly }"
      @update:deviceSelected="setDevice"
    />

    <TransitionExpand v-if="canSelectMethod">
      <!--TODO why was it here? model.method !== undefined-->
      <div v-if="model.device">
        <DecoratorInput iconSize="sm" iconTitle="ballot" label="Method" class="mt-4">
          <div
            v-if="isReadonly"
            class="input--faked input--faked-readonly"
            v-html="model.method == null ? 'No method' : model.method.name"
          />
          <SelectMethodComponent
            v-else
            :method="model.method"
            :configuration="deviceConfiguration"
            :deviceId="model.device.id"
            @update:method="setMethod"
          />
        </DecoratorInput>
        <div v-if="showMethodDifferent" class="device-method-column-inputs__warning-block">
          <div>
            <i class="material-icons material-icon--14">info</i>
            Device method is different
          </div>
          <Btn
            type="transparent"
            height="s"
            icon-left="keyboard_arrow_up"
            @click="setMethod(model.device.method)"
          >
            use device method
          </Btn>
        </div>
      </div>
    </TransitionExpand>
    <TransitionExpand>
      <!-- remove? model.column !== undefined-->
      <div v-if="model.device && model.column !== undefined">
        <DecoratorInput
          v-if="!hideColumn"
          iconSize="sm"
          iconTitle="hourglass_empty"
          label="Column"
          class="mt-4"
        >
          <div
            v-if="isReadonly"
            class="input--faked input--faked-readonly"
            v-html="model.column == null ? 'No column' : model.column.name"
          />
          <select-column-component v-else :column="model.column" @change="setColumn" />
        </DecoratorInput>
        <div
          v-if="!hideColumn && showColumnDifferent"
          class="device-method-column-inputs__warning-block"
        >
          <div>
            <i class="material-icons material-icon--14">info</i>
            Device column is different
          </div>
          <Btn
            type="transparent"
            height="s"
            icon-left="keyboard_arrow_up"
            @click="setColumn(model.device.column)"
          >
            use device column
          </Btn>
        </div>
      </div>
    </TransitionExpand>
  </div>
</template>

<script>
  import SelectMethodComponent from 'components/element/SelectMethodComponent.vue';
  import SelectColumnComponent from 'components/element/SelectColumnComponent.vue';
  import DecoratorInput from '@/uikitProject/decorators/DecoratorInput';
  import ApiDeviceSelector from '@/containers/devices/ApiDeviceSelector';
  import Btn from '@/uikitBase/btns/Btn';
  import TransitionExpand from '@/uikitProject/transitions/TransitionExpand';
  import { apiMethods } from '@/api/graphql/cloud/methods';
  import { canUseMethodWithDevice } from '@/utils/methodHelpers';

  const EVENT_CHANGE_MODEL = 'update:model';

  export default {
    name: 'DeviceMethodColumnInputs',

    components: {
      TransitionExpand,
      Btn,
      SelectColumnComponent,
      SelectMethodComponent,
      DecoratorInput,
      ApiDeviceSelector,
    },

    model: {
      prop: 'model',
      event: EVENT_CHANGE_MODEL,
    },

    props: {
      model: {
        type: Object,
        required: true,
        default() {
          return { device: undefined, method: undefined, column: undefined };
        },
      },
      isReadonly: {
        type: Boolean,
        default: false,
      },
      isRequired: {
        type: Boolean,
        default: false,
      },
      hideColumn: {
        type: Boolean,
        default: false,
      },
      canSelectMethod: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      deviceConfiguration() {
        return this.model.device?.configuration?.configuration_for_method;
      },

      showMethodDifferent() {
        return (
          !this.isReadonly &&
          this.model.device?.method &&
          this.model.device?.method?.id !== this.model.method?.id
        );
      },

      showColumnDifferent() {
        return (
          !this.isReadonly &&
          this.model.device?.column &&
          this.model.device?.column?.id !== this.model.column?.id
        );
      },
    },

    watch: {
      model: {
        handler() {
          const deviceConfigurationForMethod = this.model.device?.hardware
            ?.configuration_for_method;
          const methodConfiguration = this.model.method?.hardware_configuration;

          if (
            deviceConfigurationForMethod != null &&
            methodConfiguration != null &&
            !canUseMethodWithDevice(deviceConfigurationForMethod, methodConfiguration)
          ) {
            this.setMethod(undefined);
          }
        },
        deep: true,
        immediate: true,
      },
    },

    methods: {
      async getFullMethod(methodId) {
        try {
          return await apiMethods.getMethod(methodId);
        } catch {
          this.notifyError('Unable to get a method!');
        }
      },

      setModel(data) {
        if (this.hideColumn) {
          data.column = undefined;
        }
        this.$emit(EVENT_CHANGE_MODEL, { ...this.model, ...data });
      },

      async setDevice(device) {
        if (this.isReadonly) {
          this.setModel({ device });
        } else {
          this.setModel({
            device,
            method:
              this.model.method ||
              (device.method ? await this.getFullMethod(device.method.id) : null),
            column: this.model.column || device.column,
          });
        }
      },
      setMethod(method) {
        this.setModel({ method });
      },
      setColumn(column) {
        this.setModel({ column });
      },

      focus() {
        // stub
      },
    },
  };
</script>

<style lang="scss" scoped>
  .device-method-column-inputs {
    &__loading-devices {
      margin-left: 48px;

      @media (max-width: 500px) {
        margin-left: 10px;
      }
    }

    &__warning-block {
      margin-top: 4px;
      margin-left: 44px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
</style>
