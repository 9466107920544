var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormCreation',{staticClass:"calibration-new",staticStyle:{"margin-top":"16px","padding":"32px 16px 32px 16px"},attrs:{"isValid":_vm.isValid,"isRequiredDevice":true,"canSelectMethod":true,"requireColumn":""},on:{"submit":_vm.createCalibration},model:{value:(_vm.dmc),callback:function ($$v) {_vm.dmc=$$v},expression:"dmc"}},[_c('DecoratorInput',{staticStyle:{"margin-bottom":"16px"},attrs:{"iconSize":"md","iconTitle":"star","label":"Compound name"}},[_c('CompoundAutosuggest',{ref:"name",attrs:{"placeholder":_vm.defaultName,"isShowAllSuggestionsOnFocus":true},on:{"mounted":_vm.focusInputName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var value = ref.value;
        var setValue = ref.setValue;
        var handleInputFocus = ref.handleInputFocus;
        var handleInputBlur = ref.handleInputBlur;
        var isDisabled = ref.isDisabled;
        var id = ref.id;
        var placeholder = ref.placeholder;
return [_c('input',{staticClass:"calibration-new__input-title",attrs:{"id":id,"type":"text","disabled":isDisabled,"placeholder":placeholder,"autocomplete":"off","maxlength":"200"},domProps:{"value":value},on:{"input":function($event){return setValue($event.target.value)},"focus":handleInputFocus,"blur":handleInputBlur}})]}},{key:"mobileTrigger",fn:function(ref){
        var value = ref.value;
return [_c('input',{staticClass:"calibration-new__input-title",attrs:{"type":"text","disabled":""},domProps:{"value":value}})]}}]),model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('DecoratorInput',{staticStyle:{"margin-bottom":"16px"},attrs:{"iconSize":"sm","iconTitle":"notes","label":"Description","isOptional":true}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],staticClass:"field",attrs:{"rows":"2"},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.description=$event.target.value}}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }