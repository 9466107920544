<template>
  <div v-show="isShowPressure" ref="wrapper" class="chart-pressure">
    <div v-show="hasData">
      <div style="position: relative; width: 100%">
        <div ref="chart" class="chart-pressure__chart" />
      </div>
    </div>
  </div>
</template>

<script>
  import resize from 'vue-resize-directive';

  import { PressureDrawer } from '@/uikitProject/charts/new/PressureDrawer';
  import { isInjectionInProgress } from '@/utils/injectionHelpers';
  import { UNKNOWN_VALUE } from '@/utils/chart/chart-constants';
  import SharedMeasurementSocket, {
    SharedMeasurementSocketEvents,
  } from '@/api/sockets/SharedMeasurementSocket';
  import MeasurementSocket, { MeasurementSocketEvents } from '@/api/sockets/MeasurementSocket';
  import { apiMeasurements } from '@/api/graphql/cloud/measurements';
  import { sendDebugEvent } from '@/utils/sentryHelpers';

  const EVENT_CURRENT_PRESSURE = 'currentPressure';

  export default {
    name: 'PrChartPressure',

    directives: { resize },

    props: {
      measurement: Object,
      injection: {
        type: Object,
      },
      method: {
        type: Object,
      },
      detectionTime: {
        type: Object,
      },
      initialZoom: {
        type: Object,
      },
      isApplyDetectionTime: {
        type: Boolean,
      },
      hasLiveUpdate: {
        type: Boolean,
        default: true,
      },
      sampleToken: {
        type: String,
        default: null,
      },
      width: {
        type: Number,
      },
      isShowPressure: {
        type: Boolean,
      },
    },

    data() {
      return {
        drawer: null,
        measurementSocket: this.createSocket(this.measurement?.id),
        internalMeasurement: this.measurement?.data ? this.measurement : null,
      };
    },

    computed: {
      hasData() {
        return this.internalMeasurement?.data_length > 0;
      },
      data() {
        return this.internalMeasurement?.data;
      },
    },

    watch: {
      async isShowPressure() {
        await this.$nextTick();
        this.initChart();
      },

      data(data) {
        this.$emit(EVENT_CURRENT_PRESSURE, data ? data[data.length - 1] : null);
        this.refresh();
      },
      width(value) {
        this.drawer?.actions.setWidth(value);
      },
      isApplyDetectionTime(value) {
        this.drawer?.actions.setIsApplyDetectionTime(value);
        this.refresh();
      },
      detectionTime() {
        this.refresh();
      },
      measurement(m, oldM) {
        if (m === this.internalMeasurement) return;

        const idChanged = oldM?.id !== m?.id;

        if (!idChanged && m.data == null) return;
        this.internalMeasurement = m;

        if (idChanged) {
          this.destroySocket();

          if (m?.id) {
            this.measurementSocket = this.createSocket(m.id);
          }
        }
      },
    },

    beforeDestroy() {
      this.destroySocket();
    },

    methods: {
      createSocket(id) {
        if (!id) {
          throw new Error(`Can't establish a socket connection without id`);
        }

        if (!this.hasLiveUpdate) {
          return {};
        }

        const onMeasurement = async (m) => {
          if (m.id === this.id && m.data == null) {
            return;
          }
          const data = await m.getData(false);

          this.internalMeasurement = {
            ...this.internalMeasurement,
            ...m,
            // HOTFIX: m.data can be empty after start
            data: this.internalMeasurement?.data
              ? data?.length >= this.internalMeasurement.data?.length
                ? data
                : this.internalMeasurement.data
              : data,
          };

          this.refresh();
        };

        const onNewData = (from, values) => {
          const currentData = this.internalMeasurement?.data;

          if (currentData?.length === from) {
            const data = [...this.internalMeasurement.data, ...values];
            this.internalMeasurement = {
              ...this.internalMeasurement,
              data,
              data_length: data.length,
            };
          } else if (currentData?.length < from) {
            const difference = from - currentData.length;
            const unknownValues = Array(difference).fill(UNKNOWN_VALUE);
            const data = [...this.internalMeasurement.data, ...unknownValues, ...values];

            this.internalMeasurement = {
              ...this.internalMeasurement,
              data,
              data_length: data.length,
            };
          } else {
            const data = [...(this.internalMeasurement?.data ?? [])];
            data.splice(from, values.length, ...values);

            this.internalMeasurement = {
              ...this.internalMeasurement,
              data,
              data_length: data.length,
            };
          }
        };

        const onConnection = async () => {
          const measurement = await apiMeasurements.getMeasurement(
            this.measurement.id,
            this.measurement.ulid,
            this.injection.sample_id,
            this.sampleToken,
          );
          onMeasurement(measurement);
        };

        const socket = this.sampleToken
          ? SharedMeasurementSocket.start(id, onConnection, {
              shareToken: this.sampleToken,
            })
          : MeasurementSocket.start(id, onConnection);

        const socketEvents = this.sampleToken
          ? SharedMeasurementSocketEvents
          : MeasurementSocketEvents;

        const listenersGroup = socket.createEventListenersGroup();
        this.listenersGroupId = listenersGroup.id;

        listenersGroup.addEventListener(socketEvents.NEW_VALUES, onNewData);

        return socket;
      },
      destroySocket() {
        if (!this.hasLiveUpdate) {
          return;
        }

        if (this.measurementSocket) {
          this.measurementSocket.close(this.listenersGroupId);
        }
      },

      initChart() {
        if (this.internalMeasurement?.data == null) {
          return;
        }

        if (this.$refs.wrapper == null) {
          sendDebugEvent('#2413: Front: Fix sentry issues', [
            ['measurementId', this.internalMeasurement?.id],
            [
              'Sentry issue',
              'https://newcrom.sentry.io/issues/5729846107/?project=6192768&query=is:unresolved+issue.priority:%5Bhigh,+medium%5D&statsPeriod=30d&stream_index=2&utc=true',
            ],
          ]);

          return;
        }

        this.drawer = new PressureDrawer({
          container: this.$refs.chart,
          measurement: this.internalMeasurement,
          expectedDurationSeconds:
            this.method?.run_time_min * 60 ?? this.injection.expected_duration_sec,
          detectionTime: this.detectionTime
            ? {
                start: this.detectionTime.start,
                end: this.detectionTime.end,
              }
            : null,
          isApplyDetectionTime: this.isApplyDetectionTime,
          isInProgress: isInjectionInProgress(this.injection),
          widthPx: this.$refs.wrapper.getBoundingClientRect().width,
        });

        this.zoom(this.initialZoom);
      },

      refresh() {
        if (this.internalMeasurement.data == null || !this.isShowPressure) {
          return;
        }

        if (this.drawer) {
          this.drawer.actions.setIsInProgress(isInjectionInProgress(this.injection));
          this.drawer.actions.setDetectionTime(
            this.detectionTime
              ? {
                  start: this.detectionTime.start,
                  end: this.detectionTime.end,
                }
              : null,
          );
          this.drawer.actions.setExpectedDurationSeconds(
            this.method?.run_time_min * 60 ?? this.injection.expected_duration_sec,
          );
          this.drawer.refresh(this.internalMeasurement);
        } else {
          this.initChart();
        }
      },

      zoom(zoomBounds) {
        this.drawer?.actions.zoom({
          x0: zoomBounds?.x0 ?? null,
          x1: zoomBounds?.x1 ?? null,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chart-pressure {
    &__chart {
      width: 100%;
      height: 100px;
    }
  }
</style>
