import { makeGraphqlRequestCloud } from 'api/graphql/graphQLClient';
import { MeasurementShort } from '@/graphql/cloud/measurements/queries/MeasurementShort.graphql';
import { MeasurementsShort } from '@/graphql/cloud/measurements/queries/MeasurementsShort.graphql';
import { ResetMeasurementPostprocessing } from '@/graphql/cloud/measurements/mutations/ResetMeasurementPostprocessing.graphql';
import { ApplyMeasurementPostprocessing } from '@/graphql/cloud/measurements/mutations/ApplyMeasurementPostprocessing.graphql';
import { MeasurementPostprocessings } from '@/graphql/cloud/measurements/queries/MeasurementPostprocessings.graphql';
import { MeasurementsDetectionTime } from '@/graphql/cloud/measurements/queries/MeasurementsDetectionTime.graphql';
import { MeasurementsWithUlid } from '@/graphql/cloud/measurements/queries/MeasurementsWithUlid.graphql';
import { Measurement } from '@/graphql/cloud/measurements/queries/Measurement.graphql';
import { MeasurementForCompare } from '@/graphql/cloud/measurements/queries/MeasurementForCompare.graphql';
import {
  parseMeasurementsWithPostprocessing,
  reapplyPostprocessing,
} from '@/utils/postprocessingHelpers';
import { getDataMeasurementFromCDN } from '@/utils/deviceDataConvertors';
import { sendDebugEvent } from '@/utils/sentryHelpers';

export const apiMeasurements = {
  async getMeasurementShort(measurementId) {
    const { measurement } = await makeGraphqlRequestCloud(MeasurementShort, {
      id: measurementId,
    });

    if (!measurement) {
      throw new Error('Measurement is not found!');
    }

    return measurement;
  },
  async getMeasurementsShort(injectionId) {
    const { measurements } = await makeGraphqlRequestCloud(MeasurementsShort, {
      injectionId,
    });

    if (!measurements) {
      throw new Error('Measurements are not found!');
    }

    return { measurements };
  },
  async getMeasurementsWithUlid(measurementIds) {
    const { measurements } = await makeGraphqlRequestCloud(MeasurementsWithUlid, {
      measurementIds,
    });

    if (!measurements) {
      throw new Error('Measurements are not found!');
    }

    return measurements;
  },
  async getMeasurementsDetectionTime(measurementIds) {
    const { measurementsDetectionTime } = await makeGraphqlRequestCloud(MeasurementsDetectionTime, {
      measurementIds,
    });

    if (!measurementsDetectionTime) {
      throw new Error('No detection times were found for the selected measurements!');
    }

    return {
      measurementsDetectionTime: Object.fromEntries(
        measurementsDetectionTime.map(({ measurement_id, params }) => [measurement_id, params]),
      ),
    };
  },
  resetPostprocessing(measurementId) {
    return makeGraphqlRequestCloud(ResetMeasurementPostprocessing, {
      measurementId,
    });
  },
  applyPostprocessing(postprocessing) {
    return makeGraphqlRequestCloud(ApplyMeasurementPostprocessing, {
      postprocessing,
    });
  },
  async getPostprocessings(injectionId, sampleId, shareToken) {
    const { measurementsWithPostprocessing } = await makeGraphqlRequestCloud(
      MeasurementPostprocessings,
      {
        injectionId,
      },
      1,
      shareToken,
      sampleId,
    );

    if (measurementsWithPostprocessing == null) {
      sendDebugEvent('#2413: Front: Fix sentry issues', [
        ['injectionId', injectionId],
        ['sampleId', sampleId],
        ['shareToken', shareToken],
        [
          'Sentry issue',
          'https://newcrom.sentry.io/issues/5644525960/?project=6192768&query=is:unresolved&statsPeriod=30d&stream_index=21&utc=true',
        ],
      ]);
    }

    const postprocessings = parseMeasurementsWithPostprocessing(measurementsWithPostprocessing);

    return postprocessings.length > 0 ? postprocessings : null;
  },
  async getMeasurement(measurementId, measurementUlid, sampleId, shareToken) {
    const { measurement, dataUrls } = await makeGraphqlRequestCloud(
      Measurement,
      {
        measurementId,
        measurementUlid,
      },
      1,
      shareToken,
      sampleId,
    );

    if (measurement == null) {
      sendDebugEvent('#2413: Front: Fix sentry issues', [
        ['measurementId', measurementId],
        ['measurementUlid', measurementUlid],
        ['sampleId', sampleId],
        ['shareToken', shareToken],
        [
          'Sentry issue',
          'https://newcrom.sentry.io/issues/5644525950/?project=6192768&query=is:unresolved&statsPeriod=30d&stream_index=22&utc=true',
        ],
      ]);
    }

    // Handle the edge case when a postprocessing was applied but there are no computations
    const hasPostprocessing = measurement.postprocessings?.length > 0;
    const wasComputed = dataUrls.processedUrl?.length > 0;
    if (hasPostprocessing && !wasComputed) {
      await reapplyPostprocessing(measurement);
      return this.getMeasurement(measurementId, measurementUlid, sampleId, shareToken);
    }

    return {
      ...measurement,
      // stub to avoid some errors
      data: [],
      getData(isApplyPostprocessing) {
        const url = isApplyPostprocessing ? dataUrls.processedUrl : dataUrls.dataUrl;
        if (url) {
          return getDataMeasurementFromCDN(url);
        }
        return [];
      },
      getBaseline() {
        if (dataUrls.baselineUrl) {
          return getDataMeasurementFromCDN(dataUrls.baselineUrl);
        }
        return [];
      },
    };
  },
  async getMeasurementForCompare(measurementId, measurementUlid, sampleId, shareToken) {
    const { measurement, dataUrls } = await makeGraphqlRequestCloud(
      MeasurementForCompare,
      {
        measurementId,
        measurementUlid,
      },
      1,
      shareToken,
      sampleId,
    );

    // Handle the edge case when a postprocessing was applied but there are no computations
    const hasPostprocessing = measurement.postprocessings?.length > 0;
    const wasComputed = dataUrls.processedUrl?.length > 0;
    if (hasPostprocessing && !wasComputed) {
      await reapplyPostprocessing(measurement);
      return this.getMeasurementForCompare(measurementId, measurementUlid, sampleId, shareToken);
    }

    const detectionTime = measurement.postprocessings.find(
      (postprocessing) => postprocessing.name === 'DTIM',
    );

    return {
      ...measurement,
      injection: {
        ...measurement.injection,
        type: measurement.injection.calibration_injection_ptr_id ? 'calibration' : 'sample',
      },
      detectionTime: {
        start_sec: detectionTime?.params?.detection_time_start_sec,
        end_sec: detectionTime?.params?.detection_time_end_sec,
      },
      data: await getDataMeasurementFromCDN(
        measurement.postprocessings.length > 0 ? dataUrls.processedUrl : dataUrls.dataUrl,
      ),
    };
  },
};
