import { apiMeasurements } from '@/api/graphql/cloud/measurements';

export const parseMeasurementsWithPostprocessing = (data) =>
  data.measurements.map((measurement) => {
    const transformedPostprocessings = Object.fromEntries(
      measurement.postprocessings.map(({ name, params, ...algorithms }) => [
        name,
        {
          algorithm: algorithms[`algorithm_${name.toLocaleLowerCase()}`],
          ...params,
        },
      ]),
    );

    return {
      measurement: {
        id: measurement.id,
        name: measurement.name,
        waveLength: measurement.meta?.nm,
      },
      postprocessings: transformedPostprocessings,
    };
  });

type PostprocessingsDTO = Array<{
  name: string;
  algorithm_base: string;
  algorithm_binj: string;
  algorithm_nois: string;
  algorithm_pdet: string;
  algorithm_peak: string;
  params: object;
}>;
const parseAppliedPostprocessings = (postprocessings: PostprocessingsDTO) => {
  return Object.fromEntries(
    postprocessings.map(({ name, params, ...algorithms }) => [
      name.toLocaleLowerCase(),
      {
        algorithm: algorithms[`algorithm_${name.toLocaleLowerCase()}`],
        params,
      },
    ]),
  );
};

export const reapplyPostprocessing = (measurement: any) => {
  const appliedPostprocessings = parseAppliedPostprocessings(measurement.postprocessings);

  const postprocessings = {
    measurementId: measurement.id,
    measurementUlid: measurement.ulid,
    algorithm_pdet: appliedPostprocessings.pdet.algorithm,
    algorithm_peak: appliedPostprocessings.peak.algorithm,
    algorithm_base: appliedPostprocessings.base.algorithm,
    algorithm_binj: appliedPostprocessings.binj.algorithm,
    algorithm_nois: appliedPostprocessings.nois.algorithm,
    params_pdet: appliedPostprocessings.pdet.params,
    params_peak: appliedPostprocessings.peak.params,
    params_base: appliedPostprocessings.base.params,
    params_binj: appliedPostprocessings.binj.params,
    params_nois: appliedPostprocessings.nois.params,
    params_dtim: appliedPostprocessings.dtim.params,
  };

  return apiMeasurements.applyPostprocessing(postprocessings);
};
