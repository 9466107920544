var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"compound-with-amount"},[(_vm.isEditMode)?_c('GlobalEvents',{on:{"!keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.preventDefault();$event.stopPropagation();return _vm.finishEditMode($event)}}}):_vm._e(),(_vm.isEditMode || !_vm.compound)?_c('form',{staticClass:"compound-with-amount__form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.submit($event)}}},[_c('CompoundAutosuggest',{ref:"name",attrs:{"placeholder":"Compound","isShowAllSuggestionsOnFocus":true},on:{"mobileSave":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var value = ref.value;
        var setValue = ref.setValue;
        var handleInputFocus = ref.handleInputFocus;
        var handleInputBlur = ref.handleInputBlur;
        var isDisabled = ref.isDisabled;
        var id = ref.id;
        var placeholder = ref.placeholder;
return [_c('input',{ref:"inputDesktop",staticClass:"compound-with-amount__input-compound",attrs:{"id":id,"type":"text","disabled":isDisabled,"placeholder":placeholder,"autocomplete":"off","required":""},domProps:{"value":value},on:{"input":function($event){return setValue($event.target.value)},"focus":handleInputFocus,"blur":handleInputBlur}})]}},{key:"mobileTrigger",fn:function(ref){
        var value = ref.value;
return [_c('input',{staticClass:"compound-with-amount__input-compound",class:{ 'compound-with-amount__input-compound--empty': !value },attrs:{"type":"text","placeholder":"Compound","disabled":"","required":""},domProps:{"value":value}})]}}],null,false,1855805650),model:{value:(_vm.inputs.name),callback:function ($$v) {_vm.$set(_vm.inputs, "name", $$v)},expression:"inputs.name"}}),(_vm.hasAmount)?_c('Input',{staticClass:"compound-with-amount__input-amount",attrs:{"placeholder":"Amount, mg/mL","type":"number"},model:{value:(_vm.inputs.amount),callback:function ($$v) {_vm.$set(_vm.inputs, "amount", $$v)},expression:"inputs.amount"}}):_vm._e(),_c('Btn',{staticClass:"compound-with-amount__btn-done",attrs:{"type":"primary","isSubmit":true,"padding":"none"}},[_c('IconMaterial',{attrs:{"title":_vm.compound ? 'done' : 'add'}})],1),(_vm.compound)?_c('Btn',{staticClass:"compound-with-amount__btn-cancel",attrs:{"padding":"none"},on:{"click":_vm.finishEditMode}},[_c('IconMaterial',{attrs:{"title":"close"}})],1):_vm._e()],1):_c('div',{staticClass:"compound-with-amount__data",on:{"click":_vm.startEditMode}},[_c('div',{staticClass:"compound-with-amount__compound"},[_vm._v(_vm._s(_vm.compound.name))]),(_vm.hasAmount && _vm.compound.amount)?_c('div',{staticClass:"compound-with-amount__amount"},[_c('span',{staticClass:"compound-with-amount__divider"},[_vm._v("—")]),_vm._v(" "+_vm._s(_vm.compound.amount)+" mg/mL ")]):_vm._e()]),_c('div',{staticClass:"compound-with-amount__actions"},[(_vm.compound && !_vm.isEditMode)?_c('BtnIcon',{staticClass:"compound-with-amount__btn-edit",attrs:{"iconMaterial":"edit"},on:{"click":_vm.startEditMode}}):_vm._e(),(_vm.compound && !_vm.isEditMode)?_c('BtnIcon',{staticClass:"compound-with-amount__btn-remove",attrs:{"iconMaterial":"delete"},on:{"click":_vm.remove}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }