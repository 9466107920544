<template>
  <div
    :class="{
      'sequence-table--dragdrop-active': isDragging,
      'sequence-table--dragdrop--disabled': disableDragDrop,
    }"
    class="sequence-table"
  >
    <table class="table-default table-default--offset-32 table">
      <thead style="background-color: #0000000a">
        <tr>
          <th></th>
          <th>Sample name</th>
          <th>
            <span class="sequence-table__th-method"
              ><span>Method</span> <span>Method ID</span></span
            >
          </th>
          <th
            v-if="!isCromite"
            class="right"
            style="border-left: #ddd solid 2px; padding-left: 8px"
          >
            Vial
          </th>
          <th style="text-align: right">Repeat</th>
          <th style="padding-right: 0">Run</th>
          <th />
        </tr>
      </thead>
      <Draggable
        v-model="tableRows"
        :animation="350"
        :disabled="disableDragDrop"
        :fallbackOnBody="false"
        :fallbackTolerance="1"
        :forceFallback="true"
        :scroll="true"
        :scrollSensitivity="100"
        :scrollSpeed="5"
        class="tbody__td--nowrap table__tbody"
        dragClass="sequence-table__dragdrop-dragging"
        draggable=".sequence-table__row--draggable"
        easing="cubic-bezier(1, 0, 0, 1)"
        ghostClass="sequence-table__dragdrop-placeholder"
        group="sequences"
        handle=".sequence-table__draggable"
        tag="tbody"
        @end="finishDrag"
        @start="startDrag"
      >
        <tr
          v-for="d in tableRows"
          :key="`${d.type}-${d.id}`"
          class="row--clickable sequence-table__row"
          :class="{
            'sequence-table__row--draggable': !draftDisableEdit(d),
          }"
        >
          <td class="td--shrink" style="padding-right: 9px; position: relative">
            <div
              class="sequence-table__draggable"
              :class="{
                'sequence-table__draggable--disabled': draftDisableEdit(d),
              }"
            >
              <IconMaterial class="sequence-table__draggable-icon" title="drag_indicator" />
            </div>
            <IconMaterial
              v-if="Boolean(d.sample) || draftDisableEdit(d)"
              :title="d.type === 'S' ? 'opacity' : 'star'"
              :size="16"
              style="padding-left: 7px"
            />
          </td>

          <td class="sequence-table__cell-name">
            <template v-if="draftDisableEdit(d)">
              <b v-if="isCurrentDraft(d.id)">
                {{ d.sample != null ? samples[d.sample].name : d.name }}
              </b>
              <span v-else>{{ d.sample != null ? samples[d.sample].name : d.name }}</span>
            </template>

            <Select
              v-else
              :itemSelected="samples[d.sample]"
              :items="samplesWithoutArchived"
              labelProp="name"
              style="width: 100%"
              :isTransparent="true"
              :hasSearch="true"
              placeholderSearch="Search sample"
              titleBottomSheet="Search sample"
              @update:itemSelected="changeSample(d, $event)"
            >
              <template #leftSideInsideItem="{ item }">
                <PackIconsEntity :entity="item.type === 'S' ? 'sample' : 'calibration'" />
              </template>
            </Select>
          </td>

          <td class="text-gray sequence-table__cell-method" style="text-align: left">
            <PopupInfoMethodColumn
              :hasMethodName="true"
              :method="getDraftMethod(d)"
              :isDisabled="isOpenMethodSelect || !getDraftMethod(d)"
              style="height: 32px; width: 100%; max-width: 350px"
            >
              <SelectMethod
                ref="selectMethod"
                :methodId="d.method"
                :preloadedMethod="getDraftMethod(d)"
                :isDisabled="draftDisableEdit(d)"
                :isEditable="hasPermissionToEdit"
                :configuration="configurationForMethod"
                @update:method="changeMethod(d, $event)"
                @showPopup="onMethodSelectPopupStateChanged(true)"
                @hidePopup="onMethodSelectPopupStateChanged(false)"
                @createMethod="showCreateMethodModal(d)"
                @editMethod="showEditMethodModal($event, d)"
              />
            </PopupInfoMethodColumn>
          </td>

          <td
            v-if="!isCromite"
            class="td--shrink text-gray td--separate"
            :class="{
              'td--separate-executing': isCurrentDraft(d.id) && current.state === 'EXE',
              'td--separate-paused': isCurrentDraft(d.id) && current.state === 'PAU',
            }"
          >
            <div v-if="draftDisableEdit(d)" style="width: 40px">
              {{ d.vial }}
            </div>
            <InputVials
              v-else
              #default="{ vial, setVial, isMobile, handleBlur }"
              v-model="d.vial"
              style="display: inline-block"
              :trayConfig="trayConfig"
              placement="bottom-center"
              :isLazy="true"
              @model="saveDraft(d)"
            >
              <input
                :value="vial"
                class="font-monospace"
                style="width: 40px; text-align: right"
                :disabled="isMobile"
                @input="setVial($event.target.value)"
                @blur="handleBlur"
              />
            </InputVials>
          </td>
          <td class="td--shrink text-gray" style="text-align: right">
            <div v-if="isCurrentDraft(d.id)" class="sequence-table__cell-repeat">
              <StatesForEntity
                :has-title="false"
                :hasTitleOnAdaptive="false"
                :state="current.state"
                style="margin: 0 8px 0 0; display: inline-block"
              />
              <span
                style="font-weight: bold; margin-left: auto"
                :class="{
                  'text-process': current.state === 'EXE',
                  'text-yellow': current.state === 'PAU',
                  'text-danger': current.state === '-',
                }"
              >
                {{ current.iteration }} of {{ d.repeat }}
              </span>
            </div>
            <div v-else-if="draftDisableEdit(d)" class="sequence-table__cell-repeat">
              <StatesForEntity
                v-if="d.number_of_terminated_injections !== d.repeat"
                :hasTitle="false"
                :hasTitleOnAdaptive="false"
                state="FAI"
                style="margin: 0 8px 0 0; display: inline-block"
              />
              <span class="sequence-table__run-counter"
                >{{ d.number_of_terminated_injections }} of {{ d.repeat }}</span
              >
            </div>
            <input
              v-else
              v-model.number.lazy="d.repeat"
              class="font-monospace"
              max="1000"
              min="1"
              style="width: 40px; text-align: right; display: inline-block"
              type="number"
              @change="saveDraft(d)"
            />
          </td>
          <td
            class="td--shrink text-gray"
            style="padding-right: 0; vertical-align: middle; text-align: center"
          >
            <div class="sequence-table__wrapper-checkbox">
              <label class="sequence-table__wrapper-enabled">
                <Checkbox
                  v-if="
                    isCurrentDraft(d.id) ||
                    (d.sample_injection && d.number_of_terminated_injections === d.repeat)
                  "
                  :checked="true"
                  :isDisabled="
                    isCurrentDraft(d.id) ||
                    (d.sample_injection && d.number_of_terminated_injections === d.repeat)
                  "
                />
                <Checkbox
                  v-else
                  v-model="d.enabled"
                  :isDisabled="!isValidDraft(d)"
                  @change="saveDraft(d)"
                />
              </label>
              <BtnIcon
                v-if="draftDisableEdit(d)"
                class="sequence-table__btn-switcher"
                iconMaterial="visibility"
                @click="$emit('view', d)"
              />
            </div>
            <!--            <BtnIcon-->
            <!--              v-if="draftDisableEdit(d)"-->
            <!--              class="sequence-table__btn-switcher"-->
            <!--              iconMaterial="visibility"-->
            <!--              @click="$emit('view', d)"-->
            <!--            />-->
            <!--            <label v-else class="sequence-table__wrapper-enabled">-->
            <!--              <Checkbox v-model="d.enabled" :isDisabled="!isValidDraft(d)" @change="saveDraft(d)" />-->
            <!--            </label>-->
          </td>
          <!--<td class="td&#45;&#45;shrink text-gray">-->
          <!--<button class="material-icons material-icon&#45;&#45;14 transparent" @click="deleteDraft(d)">copy</button>-->
          <!--</td>-->
          <td class="td--shrink text-gray" style="padding: 0">
            <button
              v-show="!draftDisableEdit(d) && tableRows.length > 1"
              class="material-icons material-icon--14 transparent compact sequence-table__button-delete"
              @click="deleteDraft(d)"
            >
              clear
            </button>
          </td>
        </tr>
        <tr slot="footer" class="sequence-table__row-add">
          <td
            class="td--shrink content sequence-table__cell-add"
            :colspan="!isAllDraftsEnabled ? 3 : 7"
            @click="$emit('add')"
          >
            <IconMaterial title="add" size="16" class="sequence-table__icon-add" />
            Add a new line
          </td>
          <td v-if="!isAllDraftsEnabled" class="td--shrink td--separate" colspan="4">
            <Btn height="s" @click.stop="enableAllDrafts">Select all</Btn>
          </td>
        </tr>
      </Draggable>
    </table>

    <!-- Absolute positioned-->
    <MethodEditModal
      :method="methodForEdit"
      :configuration="configurationForMethod"
      :deviceId="device.id"
      @created="handleMethodCreation"
      @update:methodForm="handleMethodUpdate"
    />
  </div>
</template>

<script>
  import StatesForEntity from '@/uikitProject/states/packs/StatesForEntity.vue';
  import Draggable from 'vuedraggable';
  import IconMaterial from '@/uikitBase/icons/IconMaterial.vue';
  import BtnIcon from '@/uikitBase/btns/BtnIcon.vue';
  import InputVials from '@/uikitProject/vials/vueInputVials/InputVials.vue';
  import Checkbox from '@/uikitBase/switchers/Checkbox.vue';
  import Select from '@/uikitBase/selects/vueSelect/Select.vue';
  import PopupInfoMethodColumn from '@/uikitProject/popups/info/PopupInfoMethodColumn.vue';
  import SelectMethod from '@/components/block/vueSequenceTable/private/SelectMethod.vue';
  import { apiMethods } from '@/api/graphql/cloud/methods';
  import MethodEditModal from '@/components/block/modal/MethodEditModal.vue';
  import Btn from '@/uikitBase/btns/Btn.vue';
  import PackIconsEntity from '@/uikitProject/icons/packs/PackIconsEntity.vue';
  import { SAMPLE_INJECTIONS_LIMIT } from '@/constants/samples/limits';
  import { AuthService } from '@/services/auth.service';

  const EVENT_REMOVE_DRAFT = 'removeDraft';

  export default {
    name: 'SequenceTable',

    components: {
      PackIconsEntity,
      MethodEditModal,
      SelectMethod,
      Btn,
      PopupInfoMethodColumn,
      Checkbox,
      Select,
      BtnIcon,
      InputVials,
      IconMaterial,
      StatesForEntity,
      Draggable,
    },

    props: {
      device: Object,
      table: Object,
      current: Object,
      configurationForMethod: {
        type: Object,
        required: true,
      },
      samples: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        tableLocal: Object.values(this.table).sort((a, b) => a.order - b.order),
        isDragging: false,

        isOpenMethodSelect: false,

        methodsInUse: [],

        methodForEdit: undefined,

        draftForEditing: null,

        hasPermissionToEdit: AuthService.userData().role === 'admin',
      };
    },

    computed: {
      empty() {
        return this.tableLocal != null && this.tableLocal.length > 0;
      },
      tableRows: {
        get() {
          return this.tableLocal;
        },
        set(table) {
          this.tableLocal = table;
          this.$emit('update:table', table);
        },
      },
      disableDragDrop() {
        return this.current != null && this.current.state === 'EXE';
      },

      trayConfig() {
        const { autosampler } = this.device?.configuration ?? {};
        if (autosampler?.supported_trays && autosampler?.tray != null) {
          return Object.entries(autosampler?.supported_trays).find(
            ([, id]) => id === autosampler?.tray,
          )[0];
        }
        // const autosamplerFractionCollector = this.device?.configuration
        //   ?.autosampler_fraction_collector;
        // if (
        //   autosamplerFractionCollector?.supported_injection_trays &&
        //   autosamplerFractionCollector?.injection_tray != null
        // ) {
        //   return Object.entries(autosamplerFractionCollector?.supported_injection_trays).find(
        //     ([, id]) => id === autosamplerFractionCollector.injection_tray,
        //   )[0];
        // }
        return null;
      },

      samplesWithoutArchived() {
        return Object.values(this.samples).filter((sample) => !sample.archived);
      },

      methodsWithModifiedLabel() {
        return this.methods.map((method) => ({
          ...method,
          label: `ID: ${method.method.id}. ${method.name}`,
        }));
      },

      isAllDraftsEnabled() {
        return this.tableRows.every((draft) => draft.enabled || draft.sample_injection != null);
      },

      isCromite() {
        return this.device.model === 'cmt';
      },
    },

    watch: {
      table(table) {
        this.tableLocal = Object.values(table).sort((a, b) => a.order - b.order);
        this.initMethods();
      },

      samplesWithoutArchived() {
        // !row.sample_injection - not started
        const shouldBeUpdated = this.tableRows.some(
          (row) =>
            !row.sample_injection &&
            row.sample &&
            !this.samplesWithoutArchived.find((sample) => sample.id === row.sample),
        );

        // To avoid an unnecessary rpc request
        if (shouldBeUpdated) {
          this.tableRows = this.tableRows.map((row) => {
            // Check if a selected sample is active for unused drafts.
            // Reset a selection if a sample was archived
            if (
              !row.sample_injection &&
              row.sample &&
              !this.samplesWithoutArchived.find((sample) => sample.id === row.sample)
            ) {
              return { ...row, sample: null };
            }

            return row;
          });
        }
      },

      tableLocal: {
        handler(drafts) {
          drafts.forEach((d) => {
            if (d.enabled && !this.isValidDraft(d)) {
              d.enabled = false;
            }
          });
        },
        deep: true,
      },
    },

    created() {
      this.initMethods();
    },

    methods: {
      saveDraft(d) {
        const scheduledInjectionsNumber = this.getScheduledSampleInjectionsNumber(d.sample);
        if (SAMPLE_INJECTIONS_LIMIT < scheduledInjectionsNumber) {
          const allowedRepeats = d.repeat - (scheduledInjectionsNumber - SAMPLE_INJECTIONS_LIMIT);
          d.repeat = allowedRepeats >= 0 ? allowedRepeats : 0;
          this.notifyError(
            `The number of injections for a sample cannot exceed ${SAMPLE_INJECTIONS_LIMIT}. The number of repeats has been reduced to the maximum allowed. You can also select another sample.`,
            {
              durationSeconds: 10,
            },
          );
          return;
        }
        this.$emit('update:draft', d);
      },

      deleteDraft(d) {
        this.$emit(EVENT_REMOVE_DRAFT, d);
      },

      startDrag() {
        this.isDragging = true;
      },
      finishDrag() {
        this.isDragging = false;
      },

      changeSample(draft, sample) {
        draft.sample = sample?.id ?? null;
        if (!draft.sample) {
          draft.name = null;
        }
        this.saveDraft(draft);
      },

      changeMethod(draft, method) {
        draft.method = method.id ?? null;
        this.saveDraft(draft);
      },

      isCurrentDraft(id) {
        return !(this.current == null || id !== this.current.id);
      },

      draftDisableEdit(draft) {
        return Boolean(draft.sample_injection);
      },

      getDraftMethod(draft) {
        return this.methodsInUse.find((m) => m.id === draft.method);
      },

      onMethodSelectPopupStateChanged(isOpened) {
        this.isOpenMethodSelect = isOpened;
      },

      enableAllDrafts() {
        this.tableRows = this.tableRows.map((draft) => {
          const isValid = this.isValidDraft(draft);
          return {
            ...draft,
            enabled: isValid,
          };
        });
      },

      async initMethods() {
        const methodInUseIds = new Set(
          this.tableLocal.map((draft) => draft.method).filter(Boolean),
        );
        if (methodInUseIds.size > 0) {
          const hasNewMethod = [...methodInUseIds].some(
            (methodId) => !this.methodsInUse.find((method) => method.id === methodId),
          );
          if (hasNewMethod) {
            this.methodsInUse =
              (await apiMethods.getMethodsByIds([...methodInUseIds])).methods ?? [];
          }
        }
      },

      showCreateMethodModal(draft) {
        this.draftForEditing = draft;
        this.methodForEdit = undefined;
        this.$modal.show('method-edit');
      },
      showEditMethodModal(method, draft) {
        this.draftForEditing = draft;
        this.methodForEdit = method;
        this.$modal.show('method-edit');
      },

      handleMethodUpdate(method) {
        this.selectMethodForEditableDraft(method);
        this.notify('The updated method has been selected for the draft!', 15);
      },
      handleMethodCreation(method) {
        this.selectMethodForEditableDraft(method);
        this.notify('The created method has been selected for the draft!', 15);
      },

      selectMethodForEditableDraft(method) {
        if (this.draftForEditing) {
          this.changeMethod(this.draftForEditing, method);
        }

        this.refreshMethods();
        this.draftForEditing = null;
      },

      refreshMethods() {
        this.$store.commit('resetMethods');
        this.$refs.selectMethod.forEach((selectComponent) => {
          selectComponent.refreshMethods();
        });
      },

      isValidDraft(draft) {
        return Boolean(draft.method && draft.repeat && draft.vial);
      },

      getScheduledSampleInjectionsNumber(sampleId) {
        return this.tableRows.reduce((counter, row) => {
          return row.sample === sampleId ? counter + row.repeat : counter;
        }, 0);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sequence-table {
    overflow-x: auto;
    overflow-y: hidden;

    @screen sm {
      overflow-x: initial;
      overflow-y: initial;
    }

    &__btn-switcher {
      height: 29px;
      width: 29px;
      font-size: 15px;
    }

    &__wrapper-enabled {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 29px;
      height: 29px;
    }

    &__wrapper-checkbox {
      display: flex;
      align-items: center;
    }

    &__button-delete {
      opacity: 0;
      height: 100%;
      width: 32px;
      box-sizing: border-box;
    }

    &__row:hover &__button-delete {
      opacity: 1;
    }

    &__method-default-link {
      font-style: italic;
      opacity: 0;
    }

    &__row:hover &__method-default-link {
      opacity: 1;
    }

    &__row {
      position: relative;
    }

    &__cell-add {
      cursor: pointer;

      &:hover {
        color: $color-text-primary--hover;
        background-color: $color-bg-transparent--hover;
      }
      &:active {
        color: $color-text-primary--active;
      }
    }

    &__icon-add {
      margin-left: 7px;
      margin-right: 10px;
    }

    &:not(&--dragdrop-active):not(&--dragdrop--disabled)
      &__row:hover
      &__draggable:not(&__draggable--disabled) {
      opacity: 1;
    }

    &--dragdrop-active {
      user-select: none;

      * {
        cursor: grab;
      }
    }

    &--dragdrop-active &__row:hover {
      &.row--clickable {
        background-color: transparent;
      }

      &.row--clickable:nth-child(even) {
        background-color: $color-bg-transparent--nth;
      }
    }

    &__row:active.row--clickable:nth-child(even) {
      background-color: $color-bg-transparent--hover;
    }

    &__draggable {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      padding-left: 4px;
      height: 29px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      opacity: 0;
      cursor: grab;
      transition: opacity 0.1s;
    }

    &__dragdrop-placeholder {
      * {
        cursor: grab;
      }

      opacity: 0;
    }

    &__dragdrop-dragging {
      opacity: 1 !important;
      background-color: $color-bg-second !important;

      td:nth-last-of-type(2),
      td:nth-last-of-type(4) {
        padding-right: 20px !important;
      }
    }

    &__cell-name {
      max-width: 150px;
      width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__cell-method {
      min-width: 250px;
    }

    &__cell-repeat {
      display: flex;
      justify-content: space-between;
    }

    &__run-counter {
      margin-left: auto;
    }

    &__th-method {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }

  .table input,
  .table select {
    border: 0 solid transparent;
    border-bottom-width: 2px;
    background: transparent;
    border-radius: 3px;
    padding: 6px 0 0 0;
    text-indent: 0;
    font-size: 13px;
  }

  .table select {
    padding-top: 1px;
  }

  .table input {
    padding: 6px 0 0 0;
  }

  .table__tbody td {
    font-size: 13px;
    font-weight: normal;
    color: #000;
    white-space: nowrap;
    padding-top: 0;
    padding-bottom: 0;
  }

  .table__tbody tr {
    height: 32px;
  }

  .table__tbody input {
    padding-bottom: 4px;
    height: 24px;
  }

  .table__tbody tr input::placeholder {
    color: #00000000;
  }

  .table__tbody tr:hover input::placeholder {
    color: #00000066;
  }

  .table input[type='number']::-webkit-inner-spin-button,
  .table input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .td--separate {
    border-left: #ddd solid 2px;
    text-align: right;

    &-executing {
      border-left: $color-text-process solid 2px;
      text-align: right;
    }

    &-paused {
      border-left: $pure-color__yellow solid 2px;
      text-align: right;
    }
  }
</style>
