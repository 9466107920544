<template>
  <div class="selector-multiple-compounds">
    <div v-if="compounds.length" class="selector-multiple-compounds__list">
      <template v-for="(compound, index) of compounds">
        <CompoundWithAmount
          :key="index"
          :compound="compound"
          :hasAmount="hasAmount"
          class="selector-multiple-compounds__compound"
          @update="updateCompound($event, index)"
          @remove="removeCompound(index)"
        />

        <div :key="'divider-' + index" class="selector-multiple-compounds__divider"></div>
      </template>
    </div>

    <Btn
      v-if="compounds.length && !isShowAddForm"
      class="selector-multiple-compounds__btn-add-compound"
      @click="showAddForm"
    >
      Add compound
    </Btn>

    <CompoundWithAmount
      v-if="isShowAddForm || !compounds.length"
      :hasAmount="hasAmount"
      :isFocusOnMount="isShowAddForm"
      @create="addCompound"
    />
  </div>
</template>

<script>
  import Btn from '@/uikitBase/btns/Btn';
  import CompoundWithAmount from './private/CompoundWithAmount';

  const EVENT_MODEL = 'update:compounds';

  export default {
    name: 'SelectorMultipleCompounds',

    components: { CompoundWithAmount, Btn },

    model: {
      prop: 'compounds',
      event: EVENT_MODEL,
    },

    props: {
      // { name: '', amount?: '' }
      compounds: {
        type: Array,
        required: true,
      },
      hasAmount: {
        type: Boolean,
      },
    },

    data: () => ({
      isShowAddForm: false,
    }),

    methods: {
      showAddForm() {
        this.isShowAddForm = true;
      },
      hideAddForm() {
        this.isShowAddForm = false;
      },

      addCompound(compound) {
        this.$emit(EVENT_MODEL, [...this.compounds, compound]);

        this.hideAddForm();
      },
      updateCompound(compound, index) {
        const compounds = [...this.compounds];
        compounds[index] = compound;

        this.$emit(EVENT_MODEL, compounds);
      },
      removeCompound(index) {
        this.$emit(
          EVENT_MODEL,
          this.compounds.filter((_, _index) => _index !== index),
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .selector-multiple-compounds {
    &__list {
      padding: 10px 0;
    }

    &__compound {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &__divider {
      height: 1px;
      width: 100%;
      margin: 10px 0;
      background-color: $color-bg-third;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
