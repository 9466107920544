<template>
  <table class="recent-injections table-default">
    <thead>
      <tr>
        <th class="recent-injections--cell--name">Sample name</th>
        <th class="recent-injections--cell--number">#</th>
        <th class="recent-injections--cell--date">Date</th>
        <th class="recent-injections--cell--method">Method</th>
        <th class="recent-injections--cell--device">Device</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="injection of injections"
        :key="injection.id"
        class="row--clickable"
        @click="goToInjection(injection)"
      >
        <td class="recent-injections--cell--name" :title="injection.sample.name">
          {{ injection.sample.name }}
        </td>
        <td class="recent-injections--cell--number">{{ injection.name }}</td>
        <td class="recent-injections--cell--date">
          {{ formatInjectionDate(injection.date_started_running) }}
        </td>
        <td class="recent-injections--cell--method">
          <PopupInfoMethodColumn
            :hasMethodName="true"
            :method="injection.method"
            :isOverflowEllipsis="true"
          >
            <span class="recent-injections__label-method-name">{{ injection.method.name }}</span>
          </PopupInfoMethodColumn>
        </td>
        <td class="recent-injections--cell--device">
          {{ injection.device.name }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import DateHelper from 'utils/DateHelper';
  import { isThisYear } from 'date-fns';
  import PopupInfoMethodColumn from '@/uikitProject/popups/info/PopupInfoMethodColumn';

  export default {
    name: 'RecentInjections',

    components: { PopupInfoMethodColumn },

    props: {
      injections: {
        type: Array,
        required: true,
      },
    },

    methods: {
      formatInjectionDate(dateJSONFormat) {
        const date = new Date(dateJSONFormat);
        return DateHelper.formatDate(date, isThisYear(date) ? 'EEEE, LLL d' : 'EEEE, LLL d, yyyy');
      },
      goToInjection(injection) {
        this.$router.push({
          name: injection.calibrationId ? 'calibration injection' : 'sample injection',
          params: {
            id: injection.sample.id,
            iid: injection.id,
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .recent-injections {
    margin: 16px 0;

    td {
      color: $color-text-default;
      vertical-align: baseline;
    }

    &--cell {
      &--name {
        @include textOverflow;
        max-width: 0;
      }

      &--number {
        padding: 0 20px;
      }

      &--date,
      &--method,
      &--device {
        max-width: 120px;
        @include textOverflow;
      }

      &--method,
      &--device {
        @media (max-width: $screen-xs-max) {
          display: none;
        }
      }
    }

    &__label-method-name {
      @include textOverflow;
    }
  }
</style>
