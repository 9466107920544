<template>
  <TransitionExpand>
    <div v-if="injectionId && injection" style="border-top: 1px solid transparent">
      <div class="card-rounded card-rounded--hoverable sample-injection-mini">
        <div class="left flex mr-4">
          <div class="flex__left mt-1">
            <injection-link-component :injection="injection" />

            <div class="mt-4">
              <span class="mr-4">
                <template v-if="injection.calibration_injection_ptr">
                  Calibration injection
                </template>
                <template v-else> Sample injection </template>
              </span>

              <span class="span-date mr-4" v-html="formatTime(injection.date_started)" />

              <loading-component
                v-if="!updated"
                :label="null"
                class="loading-component--sm-inline mt-1"
              />
              <span
                v-else-if="injection.state !== 'REA' && injection.error_str != null"
                class="text-red"
              >
                <i class="material-icons material-icon--16">error</i>
                {{ injection.error_str }}
              </span>
              <StatesForEntity
                v-else
                :state="injection.state"
                data-test-id="sample-injection-state"
              />
            </div>
          </div>

          <div class="flex__right mt-2">
            <progress-component
              v-if="injection != null && injection.state === 'RUN'"
              class="progress"
              :label="injection.progress_time_str"
              :progress="injection.progress"
            />
          </div>
        </div>
        <button
          v-if="injection.state === 'PRE' || injection.state === 'RUN'"
          v-tippy
          content="Stop current run"
          class="button--square-image right"
          @click.stop="stop"
        >
          <i class="material-icons material-icon--18">stop</i>
          Stop
        </button>
      </div>
    </div>
  </TransitionExpand>
</template>

<script>
  import SampleInjectionSocket, {
    SampleInjectionSocketEvents,
  } from 'api/sockets/SampleInjectionSocket';
  import LoadingComponent from 'components/element/LoadingComponent';
  import StatesForEntity from '@/uikitProject/states/packs/StatesForEntity';
  import ProgressComponent from 'components/element/ProgressComponent';
  import InjectionAPI from 'api/injection';
  import InjectionLinkComponent from 'components/element/InjectionLinkComponent';
  import CalibrationInjectionSocket, {
    CalibrationInjectionSocketEvents,
  } from 'api/sockets/CalibrationInjectionSocket';
  import { consoleHelpers } from 'utils/logHelpers';
  import { INJECTION_ERRORS } from '@/constants/injections/errors';
  import { INJECTION_STATES } from '@/constants/injections/states';
  import faviconService, { FaviconStatuses } from '@/services/FaviconService';
  import { isInjectionFailed, isInjectionInProgress } from 'utils/injectionHelpers';
  import { isBrowserTabActive } from 'utils/browserHelpers';
  import TransitionExpand from '@/uikitProject/transitions/TransitionExpand';

  export default {
    name: 'SampleInjectionMiniComponent',

    components: {
      TransitionExpand,
      InjectionLinkComponent,
      ProgressComponent,
      StatesForEntity,
      LoadingComponent,
    },

    props: {
      injectionId: {
        type: Number,
      },
    },

    data: () => ({
      injectionSocket: null,
      injection: {},
      updated: null,
      timerConnectionLost: null,
    }),

    computed: {
      isInProgress() {
        return Boolean(this.injection) && isInjectionInProgress(this.injection);
      },
    },

    watch: {
      injectionId(valueNew, valueOld) {
        this.updated = false;

        if (valueNew) {
          this.initInjection(this.injectionId);
        } else if (valueOld) {
          this.showPreviousInjectionResult(valueOld);
        }
      },
      isInProgress: {
        handler(isInProgress) {
          if (isInProgress) {
            faviconService.setStatus(FaviconStatuses.RUNNING);
          }
        },
        immediate: true,
      },
    },

    created() {
      this.initInjection(this.injectionId);
    },

    beforeDestroy() {
      if (this.timerConnectionLost != null) clearTimeout(this.timerConnectionLost);
      this.destroyInjectionSocket();

      if (isBrowserTabActive()) {
        faviconService.setStatus(FaviconStatuses.NONE);
      }
    },

    methods: {
      initInjection(injectionId) {
        if (!injectionId) {
          return;
        }

        this.destroyInjectionSocket();
        this.injection = null;

        faviconService.setStatus(FaviconStatuses.NONE);

        InjectionAPI.getMinWithParents(injectionId, (i) => {
          this.injection = { ...this.injection, ...i };
          this.injectionSocket = this.createInjectionSocket(
            injectionId,
            this.injection.type === 'calibration',
          );
          this.updated = true;
        });
      },

      showPreviousInjectionResult(previousInjectionId) {
        /**
         * A device state can be updated a bit earlier than an injection is actually stopped.
         * So we need to make a delayed request to know the injection result.
         */
        setTimeout(() => {
          if (!this.injectionId && !isBrowserTabActive()) {
            InjectionAPI.getMinWithParents(previousInjectionId, (i) => {
              if (!this.injectionId && !isInjectionInProgress(i)) {
                faviconService.setStatus(
                  isInjectionFailed(i) ? FaviconStatuses.ERROR : FaviconStatuses.COMPLETED,
                  (this.injection = null),
                );
              }
            });
          }
        }, 1000);
      },

      createInjectionSocket(id, isCalibration) {
        this.destroyInjectionSocket();

        const socketEvents = isCalibration
          ? CalibrationInjectionSocketEvents
          : SampleInjectionSocketEvents;

        const injectionSocket = isCalibration
          ? CalibrationInjectionSocket.start(id)
          : SampleInjectionSocket.start(id);

        const listenersGroup = injectionSocket.createEventListenersGroup();
        this.listenersGroupId = listenersGroup.id;

        listenersGroup.addEventListener(socketEvents.INJECTION, (i) => this.onInjection(i));
        listenersGroup.addEventListener(socketEvents.PROGRESS, (i) => {
          this.injection = {
            ...this.injection,
            state: i.state,
            substate: i.substate,
            progress: i.progress,
            progress_time_str: i.progress_time_str,
          };

          this.onInjectionChanged(this.injection);
        });

        return injectionSocket;
      },
      destroyInjectionSocket() {
        if (this.injectionSocket != null) {
          this.injectionSocket?.close(this.listenersGroupId);
        }
      },

      onInjectionChanged(i) {
        if (this.timerConnectionLost != null) {
          clearTimeout(this.timerConnectionLost);
        }

        if (
          [
            INJECTION_STATES.PENDING,
            INJECTION_STATES.PREPARING,
            INJECTION_STATES.RUNNING,
            INJECTION_STATES.NO_INTERNET,
          ].includes(i.state)
        ) {
          this.timerConnectionLost = setTimeout(() => this.connectionLost(), 4500);
        }

        if (i.state === INJECTION_STATES.FAILED || i.state === INJECTION_STATES.READY) {
          setTimeout(() => {
            this.showNotificationIfRpcError(() => this.injectionSocket.seen());
          }, 1000);

          if (i.error && i.error !== INJECTION_ERRORS.CANCELLED) {
            this.notifyError(`Injection is failed: ${i.error_str}`, {
              durationSeconds: null,
              hasCloseButton: true,
            });
          }

          this.destroyInjectionSocket();
          this.injection = null;

          faviconService.setStatus(i.error ? FaviconStatuses.ERROR : FaviconStatuses.COMPLETED);
        }
      },

      connectionLost() {
        return this.showNotificationIfRpcError(async () => {
          consoleHelpers.warn('No internet!');
          if (this.injection) {
            this.injection.state = INJECTION_STATES.NO_INTERNET;
          }
          const injection = await this.injectionSocket.get();
          this.onInjection(injection);
        });
      },

      onInjection(i) {
        this.injection = { ...this.injection, ...i };
        this.updated = true;
        this.onInjectionChanged(i);
      },

      stop() {
        this.showNotificationIfRpcError(() => this.injectionSocket.stop());
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sample-injection-mini {
    width: 100%;
    padding: 24px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .left {
      flex-grow: 1;
      flex-shrink: 1;
    }

    .right {
      flex-shrink: 0;
    }
  }

  .flex {
    display: flex;
    flex-direction: column;

    .flex__right {
      .progress {
        margin: initial;
        width: 218px;
      }
    }
  }
  @screen sm {
    .flex {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-end;

      .flex__left {
        flex-grow: 0;
        flex-shrink: 1;
      }

      .flex__right {
        flex-grow: 1;

        .progress {
          margin: auto;
        }
      }
    }
  }
</style>
