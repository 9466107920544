<template>
  <div class="compound-with-amount">
    <GlobalEvents v-if="isEditMode" @keydown.capture.prevent.stop.esc="finishEditMode" />

    <form
      v-if="isEditMode || !compound"
      class="compound-with-amount__form"
      @submit.stop.prevent="submit"
    >
      <CompoundAutosuggest
        ref="name"
        v-model="inputs.name"
        placeholder="Compound"
        :isShowAllSuggestionsOnFocus="true"
        @mobileSave="submit"
      >
        <template
          #default="{
            value,
            setValue,
            handleInputFocus,
            handleInputBlur,
            isDisabled,
            id,
            placeholder,
          }"
        >
          <input
            :id="id"
            ref="inputDesktop"
            type="text"
            class="compound-with-amount__input-compound"
            :value="value"
            :disabled="isDisabled"
            :placeholder="placeholder"
            autocomplete="off"
            required
            @input="setValue($event.target.value)"
            @focus="handleInputFocus"
            @blur="handleInputBlur"
          />
        </template>

        <template #mobileTrigger="{ value }">
          <input
            :value="value"
            type="text"
            placeholder="Compound"
            disabled
            required
            class="compound-with-amount__input-compound"
            :class="{ 'compound-with-amount__input-compound--empty': !value }"
          />
        </template>
      </CompoundAutosuggest>

      <Input
        v-if="hasAmount"
        v-model="inputs.amount"
        class="compound-with-amount__input-amount"
        placeholder="Amount, mg/mL"
        type="number"
      />

      <Btn type="primary" :isSubmit="true" class="compound-with-amount__btn-done" padding="none">
        <IconMaterial :title="compound ? 'done' : 'add'" />
      </Btn>
      <Btn
        v-if="compound"
        class="compound-with-amount__btn-cancel"
        padding="none"
        @click="finishEditMode"
      >
        <IconMaterial title="close" />
      </Btn>
    </form>

    <div v-else class="compound-with-amount__data" @click="startEditMode">
      <div class="compound-with-amount__compound">{{ compound.name }}</div>
      <div v-if="hasAmount && compound.amount" class="compound-with-amount__amount">
        <span class="compound-with-amount__divider">—</span>
        {{ compound.amount }} mg/mL
      </div>
    </div>

    <div class="compound-with-amount__actions">
      <BtnIcon
        v-if="compound && !isEditMode"
        iconMaterial="edit"
        class="compound-with-amount__btn-edit"
        @click="startEditMode"
      />
      <BtnIcon
        v-if="compound && !isEditMode"
        iconMaterial="delete"
        class="compound-with-amount__btn-remove"
        @click="remove"
      />
    </div>
  </div>
</template>

<script>
  import BtnIcon from '@/uikitBase/btns/BtnIcon';
  import Input from '@/uikitBase/inputs/Input';
  import Btn from '@/uikitBase/btns/Btn';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import CompoundAutosuggest from '@/uikitProject/tables/shared/vueTableCell/private/CompoundAutosuggest';

  const EVENT_UPDATE = 'update';
  const EVENT_CREATE = 'create';
  const EVENT_REMOVE = 'remove';

  export default {
    name: 'CompoundWithAmount',

    components: { CompoundAutosuggest, IconMaterial, Btn, Input, BtnIcon },

    props: {
      compound: {
        type: Object,
      },
      hasAmount: {
        type: Boolean,
      },
      isFocusOnMount: {
        type: Boolean,
      },
    },

    data: () => ({
      isEditMode: false,

      inputs: {
        name: '',
        amount: '',
      },
    }),

    mounted() {
      if (this.isFocusOnMount) {
        this.$refs.inputDesktop?.focus();
      }
    },

    methods: {
      async startEditMode() {
        if (this.compound) {
          this.inputs = {
            name: this.compound.name,
            amount: this.compound.amount,
          };
        }

        this.isEditMode = true;

        await this.$nextTick();
        this.$refs.inputDesktop?.focus();
      },
      finishEditMode() {
        this.isEditMode = false;
      },

      submit() {
        if (!this.inputs.name) {
          return;
        }

        if (!this.hasAmount) {
          delete this.inputs.amount;
        }

        this.$emit(this.compound ? EVENT_UPDATE : EVENT_CREATE, { ...this.inputs });

        this.finishEditMode();
      },
      remove() {
        this.$emit(EVENT_REMOVE, this.compound);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .compound-with-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__form {
      flex: 1;
      display: flex;
      align-items: center;
    }

    &__data {
      flex: 1;
      display: flex;
      align-items: center;
      @include cursorEdit;

      @media (max-width: $screen-xs-max) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__amount {
      margin-left: 5px;

      @media (max-width: $screen-xs-max) {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    &__input-amount {
      margin-left: 5px;
    }

    &__amount {
      white-space: nowrap;
      min-width: 80px;
    }

    &__divider {
      @media (max-width: $screen-xs-max) {
        display: none;
      }
    }

    &__input-compound {
      @include placeholder {
        color: $color-text-third;
      }

      &--empty {
        -webkit-text-fill-color: unset !important;
      }
    }

    &__input-amount {
      max-width: 116px;
    }

    &__btn-done,
    &__btn-cancel {
      min-width: 32px;
      margin-left: 5px;
    }

    &__btn-cancel {
      margin-left: 5px;
    }

    &__actions {
      display: flex;
      align-items: center;
    }

    &__btn-edit {
      color: $color-text-primary;
      margin-left: 15px;
    }

    &__btn-remove {
      color: $color-text-danger;
      margin-left: 10px;
    }
  }
</style>
