var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type === 'bool')?_c('div',{staticClass:"model-field"},[(_vm.helper)?_c('PopupHelper',{attrs:{"text":_vm.helper}},[_c('label',{staticClass:"model-field__label",staticStyle:{"display":"flex"}},[_vm._v(_vm._s(_vm.label))])]):[_c('label',[_vm._v(_vm._s(_vm.label))])],_c('div',{staticClass:"model-field__container"},[_c('div',{staticClass:"model-field__input-container"},[_c('button',{class:{
          'button--underline': _vm.value,
          'button--preview': _vm.disabled,
        },domProps:{"innerHTML":_vm._s(_vm.value ? 'ON' : 'OFF')},on:{"click":function($event){return _vm.updateValue(!_vm.value)}}})]),(_vm.afterSlotVisible)?_vm._t("after"):_vm._e()],2)],2):_c('label',{staticClass:"model-field"},[(_vm.helper)?_c('PopupHelper',{attrs:{"text":_vm.helper}},[_c('div',{staticStyle:{"display":"flex"}},[_vm._v(_vm._s(_vm.label))])]):[_vm._v(" "+_vm._s(_vm.label)+" ")],_c('div',{staticClass:"model-field__container"},[_c('div',{staticClass:"model-field__input-container"},[(_vm.hints && !_vm.disabled)?_c('InputAutosuggest',{staticClass:"model-field__input-autosuggest",attrs:{"value":_vm.strValue || '',"items":_vm.hints,"labelProp":"name","placeholder":_vm.label,"titleBottomSheet":_vm.label,"isLoadingSuggestions":false,"isShowAllSuggestionsOnFocus":true,"type":_vm.inputType,"step":_vm.step,"isError":_vm.error},on:{"focus":function($event){return _vm.$emit('focus')},"model":_vm.updateValue},scopedSlots:_vm._u([{key:"input",fn:function(ref){
          var value = ref.value;
          var tempValue = ref.tempValue;
          var setValue = ref.setValue;
          var setFilterValue = ref.setFilterValue;
          var handleInputFocus = ref.handleInputFocus;
          var handleInputBlur = ref.handleInputBlur;
          var isError = ref.isError;
return [_c('input',{ref:"input",class:{ 'model-field__input--error': isError },attrs:{"type":_vm.inputType,"inputmode":_vm.inputMode,"step":_vm.step},domProps:{"value":tempValue != null ? tempValue : value},on:{"keypress":_vm.checkSymbol,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.blur($event)},"input":function($event){setValue($event.target.value) && setFilterValue($event.target.value)},"focus":handleInputFocus,"blur":handleInputBlur}})]}},{key:"mobileTrigger",fn:function(ref){
          var value = ref.value;
          var isError = ref.isError;
return [_c('input',{staticClass:"model-field__trigger-mobile",class:{ 'model-field__input--error': isError },attrs:{"type":"text","disabled":""},domProps:{"value":value}})]}}],null,false,2251716794)}):(_vm.isMultiline)?[_c('InputFormMultiline',{staticClass:"model-field__input-multiline",attrs:{"value":_vm.value,"isDisabled":_vm.disabled},on:{"model":_vm.updateValue}})]:[_c('input',{ref:"input",staticClass:"model-field__input",class:{
            'error-input': _vm.error,
            'title-input': _vm.isTitle,
            'dont-round-right-corners': _vm.afterSlotVisible,
          },attrs:{"type":_vm.inputType,"inputmode":_vm.inputMode,"disabled":_vm.disabled,"step":_vm.step},domProps:{"value":_vm.value},on:{"focus":function($event){return _vm.$emit('focus')},"keypress":_vm.checkSymbol,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.blur($event)},"input":function($event){return _vm.updateValue($event.target.value)}}})]],2),(_vm.afterSlotVisible)?_vm._t("after"):_vm._e()],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }