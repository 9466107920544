<template>
  <div class="info-method" :style="styleGrid" v-on="$listeners">
    <span v-if="hasMethodName" class="info-method__name"
      ><span class="info-method__id">#{{ method.id }}</span> {{ method.name }}</span
    >

    <div class="info-method__group">
      <Property :bgColor="bgColor" property="Run time">
        <span class="info-method__value--bold">{{ method.run_time_min }} min</span>
      </Property>
      <Property v-if="method.comment" :bgColor="bgColor" property="Comment">
        {{ method.comment }}
      </Property>
      <Property property="A phase" :hasOverflowEllipsis="true" :bgColor="bgColor" :title="phaseA">
        {{ phaseA }}
      </Property>
      <Property property="B phase" :hasOverflowEllipsis="true" :bgColor="bgColor" :title="phaseB">
        {{ phaseB }}
      </Property>
      <Property v-if="method.pressure_min" :bgColor="bgColor">
        <template #property>
          Pressure
          <IconMaterial
            title="save_alt"
            hint="Save pressure profile"
            class="info-method__icon-pressure"
          />
        </template>

        <span class="info-method__value--grey">min. </span>
        {{ method.pressure_min }} psi
        <template v-if="method.pressure_reach_sec">
          <span class="info-method__value--grey">reach in</span> {{ method.pressure_reach_sec }} sec
        </template>
      </Property>
    </div>
    <div class="info-method__group">
      <Property :bgColor="bgColor" property="Injection" class="info-method__property--injection">
        <span class="info-method__value--bold">
          {{ parseFloat(method.injection_ul).toFixed(1) }} μl
        </span>
        <template v-if="method.injection_sec">
          <span class="info-method__value--grey"> in</span>
          {{ method.injection_sec }} sec
        </template>
      </Property>
      <Property
        v-if="method.needle_depth_mm || method.syringe_refill_speed"
        :bgColor="bgColor"
        property="Sample intake"
      >
        <span v-if="method.needle_depth_mm">
          {{ parseFloat(method.needle_depth_mm).toFixed(0) }} mm
          <span class="info-method__value--grey">depth</span>
          <template v-if="method.syringe_refill_speed">
            <span class="info-method__value--grey"> at</span>
            {{ parseFloat(method.syringe_refill_speed).toFixed(0) }} μl/min
          </template>
        </span>
        <span v-else>
          <span class="info-method__value--grey">at</span>
          {{ parseFloat(method.syringe_refill_speed).toFixed(0) }} μl/min
        </span>
      </Property>
      <Property
        v-if="method.analog_signal_type"
        :bgColor="bgColor"
        :property="`${method.analog_signal_type} (Analog signal)`"
      >
        {{ method.analog_signal_description }}
      </Property>
      <Property :bgColor="bgColor" property="Flow rate">
        <span class="info-method__value--bold">
          {{ parseFloat(method.flow_rate_ml_per_min).toFixed(2) }} mL/min
        </span>
      </Property>
      <Property v-if="method.shaking_mode" :bgColor="bgColor" property="Shaking">
        mode is on for {{ method.shaking_duration_sec }} sec
      </Property>
    </div>
  </div>
</template>

<script>
  import Property from '../decorators/DecoratorProperty';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  export default {
    name: 'InfoMethod',

    components: { IconMaterial, Property },

    props: {
      method: {
        type: Object,
        required: true,
      },
      gridColumns: {
        type: Number,
        default: 2,
      },
      bgColor: {
        type: String,
        default: 'white',
      },
      hasMethodName: {
        type: Boolean,
      },
    },

    computed: {
      styleGrid() {
        return {
          gridTemplateColumns: `repeat(${this.gridColumns}, 1fr)`,
        };
      },
      phaseA() {
        return this.method.solvents?.a?.name;
      },
      phaseB() {
        return this.method.solvents?.b?.name;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .info-method {
    display: grid;
    gap: 8px;

    @media (max-width: 650px) {
      grid-template-columns: 1fr !important;
    }

    &__id {
      color: $color-text-third;
      font-weight: $weight-normal;
    }

    &__name {
      font-weight: $weight-bold;
    }

    &__value {
      &--bold {
        font-weight: $weight-bold;
      }

      &--grey {
        color: $color-text-third;
      }
    }

    &__icon-pressure {
      margin-left: 3px;
    }

    &__group {
      overflow: hidden;
    }
  }
</style>
