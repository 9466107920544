<template>
  <div ref="sample-peaks" v-resize:throttle="onResize">
    <template v-if="peaks && peaks.length > 0">
      <div v-if="editable" :class="{ 'table-peaks-scrollable-container': small }">
        <table class="table-peaks">
          <thead class="table-peaks__thead">
            <tr>
              <th style="text-align: left">#</th>
              <th style="text-align: left">Time</th>
              <th style="text-align: right; white-space: nowrap">Area</th>
              <th style="text-align: left; padding-left: 12px">Compound</th>
              <th />
              <th style="text-align: left; padding-right: 0">Amount</th>
              <th />
              <th />
              <th />
            </tr>
          </thead>
          <tbody class="table-peaks__tbody">
            <template v-for="(i, index) in peaksAndEstimations">
              <tr
                :key="`${index}-main`"
                :class="{ 'table-peaks__row--selected': i.peak.id === highlightedPeakId }"
                @mouseenter.self="onMouseEnter(i.peak.id)"
                @mouseleave="onMouseLeave(i.peak.id)"
                @focusin="focusPeak(i.peak.id)"
                @focusout="blurPeak"
              >
                <td>
                  <span class="font-monospace font-40">{{ index + 1 }}</span>
                </td>
                <td class="font-monospace">
                  {{ i.peak.apex }}
                </td>
                <td class="font-monospace area">
                  {{ i.peak.area }}
                </td>
                <td style="width: auto; padding-right: 0; padding-left: 0">
                  <a
                    v-if="i.estimation.calibration_id != null"
                    style="padding-left: 12px"
                    v-bind:href="'/app/calibrations/' + i.estimation.calibration_id.toString()"
                  >
                    {{ i.estimation.name }}
                  </a>
                  <input
                    v-else
                    :id="i.peak.id"
                    ref="compoundInput"
                    :value="i.estimation.name || ''"
                    placeholder="Compound"
                    type="text"
                    class="table-peaks__input"
                    autocomplete="off"
                    @change="updateEstimationName(i, $event.target.value)"
                    @blur="blurCompoundInput"
                  />
                </td>
                <td style="padding: 0">
                  <button
                    v-if="i.estimation.calibration_id != null"
                    title="Reset estimation"
                    @click="resetEstimation(i.estimation.id)"
                  >
                    <i class="material-icons material-icon--16">undo</i>
                  </button>
                  <button v-else title="Use calibration" @click="peakCalibration(i.peak)">
                    <i class="material-icons material-icon--16">star</i>
                  </button>
                </td>
                <td style="padding: 0">
                  <input
                    v-if="i.estimation.calibration_id == null"
                    :value="getValue(i.estimation.amount)"
                    class="font-monospace input--focus-underline"
                    placeholder="Amount"
                    style="width: 64px !important; text-align: right; display: inline-block"
                    @keypress="checkIfValueIsNumber"
                    @input="i.estimation.amount = $event.target.value"
                    @change="saveEstimations(i.peak.id)"
                    @paste.prevent
                  />
                  <a
                    v-else
                    class="font-monospace"
                    style="
                      width: 90px;
                      text-align: right;
                      display: inline-block;
                      vertical-align: bottom;
                      overflow: hidden;
                      padding-left: 8px;
                      text-overflow: ellipsis;
                    "
                    :title="i.estimation.amount"
                    v-bind:href="'/app/calibrations/' + i.estimation.calibration_id.toString()"
                  >
                    {{ i.estimation.amount.toFixed(3) }}
                  </a>
                </td>
                <td style="padding: 0">
                  <input
                    v-if="i.estimation.calibration_id == null"
                    v-model="i.estimation.unit"
                    class="input--focus-underline"
                    placeholder="Unit"
                    style="width: 64px !important; display: inline-block"
                    @change="saveEstimations(i.peak.id)"
                  />
                  <span v-else style="width: 58px; padding-left: 12px; display: inline-block">
                    {{ i.estimation.unit }}
                  </span>
                </td>
                <td style="padding-left: 0">
                  <Btn
                    v-if="peakIdToShowFullInformation === i.peak.id"
                    type="transparent"
                    padding="xs"
                    class="table-peaks__btn-details"
                    @click="hideFullPeakInformation"
                  >
                    Hide details</Btn
                  >
                  <Btn
                    v-else
                    type="transparent"
                    padding="xs"
                    class="table-peaks__btn-details"
                    @click="showShowFullPeakInformation(i.peak.id)"
                  >
                    Show more
                  </Btn>
                </td>
                <td style="padding-left: 0">
                  <RemovePeakBtn :peakId="i.peak.id" :measurementSocket="measurementSocket" />
                </td>
              </tr>
              <template v-if="peakIdToShowFullInformation === i.peak.id">
                <tr :key="`${index}-details`">
                  <td colspan="9" class="table-peaks__details">
                    <div class="table-peaks__properties">
                      <DecoratorProperty
                        bgColor="#fff"
                        property="Theoretical Plates"
                        class="table-peaks__property"
                      >
                        {{ (i.peak.plates && i.peak.plates.toFixed(3)) || 'n/a' }}
                      </DecoratorProperty>
                      <DecoratorProperty
                        bgColor="#fff"
                        property="Asymmetry"
                        class="table-peaks__property"
                      >
                        {{ getPeakAsymmetry(i.peak) }}
                      </DecoratorProperty>
                      <DecoratorProperty
                        bgColor="#fff"
                        property="Fit Quality, R²"
                        class="table-peaks__property"
                      >
                        {{ formatPeakQuality(i.peak) }}
                      </DecoratorProperty>
                      <DecoratorProperty
                        bgColor="#fff"
                        property="FWHM, min"
                        class="table-peaks__property"
                      >
                        {{ (i.peak.fwhm && i.peak.fwhm.toFixed(3)) || 'n/a' }}
                      </DecoratorProperty>
                      <DecoratorProperty
                        bgColor="#fff"
                        property="Virtual area, mAU * min"
                        class="table-peaks__property"
                      >
                        {{ (i.peak.virtual_area && i.peak.virtual_area.toFixed(3)) || 'n/a' }}
                      </DecoratorProperty>
                      <DecoratorProperty
                        bgColor="#fff"
                        property="Noise to signal ratio"
                        class="table-peaks__property"
                      >
                        {{ (i.peak.sn_level && i.peak.sn_level.toFixed(3)) || 'n/a' }}
                      </DecoratorProperty>
                    </div>
                  </td>
                </tr>
                <!-- Stub for bg styling-->
                <tr :key="`${index}-stub`"></tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
      <div v-else style="overflow-x: auto">
        <table class="table-peaks">
          <thead class="table-peaks__thead">
            <tr>
              <th class="number">#</th>
              <th style="text-align: left">Time</th>
              <th style="text-align: right; white-space: nowrap">Area</th>
              <th />
              <th style="text-align: left; padding-left: 8px">Compound</th>
              <th />
              <th style="text-align: right; padding-right: 0">Amount</th>
              <th />
            </tr>
          </thead>
          <tbody class="table-peaks__tbody">
            <template v-for="(i, index) in peaksAndEstimations">
              <tr
                :key="`${index}-main`"
                :class="{ 'table-peaks__row--selected': i.peak.id === highlightedPeakId }"
                @mouseenter.self="onMouseEnter(i.peak.id)"
                @mouseleave="onMouseLeave(i.peak.id)"
                @focusin="focusPeak(i.peak.id)"
                @focusout="blurPeak"
              >
                <td>
                  <span class="font-monospace font-40">{{ index + 1 }}</span>
                </td>
                <td class="font-monospace">
                  {{ i.peak.apex }}
                </td>
                <td class="font-monospace area">
                  {{ i.peak.area }}
                </td>
                <td class="arrow">→</td>
                <td style="width: auto; padding-right: 0; padding-left: 0">
                  <a
                    v-if="i.estimation.calibration_id != null"
                    style="padding-left: 12px"
                    v-bind:href="'/app/calibrations/' + i.estimation.calibration_id.toString()"
                  >
                    {{ i.estimation.name }}
                  </a>
                </td>
                <td style="padding: 0"></td>
                <td style="padding: 0">
                  <a
                    v-if="i.estimation && i.estimation.calibration_id"
                    class="font-monospace"
                    style="
                      width: 90px;
                      text-align: right;
                      display: inline-block;
                      vertical-align: bottom;
                      overflow: hidden;
                      padding-left: 8px;
                      text-overflow: ellipsis;
                    "
                    :title="i.estimation.amount"
                    v-bind:href="'/app/calibrations/' + i.estimation.calibration_id.toString()"
                  >
                    {{ i.estimation.amount.toFixed(3) }}
                  </a>
                </td>
                <td style="padding: 0">
                  <span style="width: 58px; padding-left: 12px; display: inline-block">
                    {{ i.estimation.unit }}
                  </span>
                </td>
                <td style="padding-left: 0">
                  <Btn
                    v-if="peakIdToShowFullInformation === i.peak.id"
                    type="transparent"
                    padding="xs"
                    class="table-peaks__btn-details"
                    @click="hideFullPeakInformation"
                  >
                    Hide details
                  </Btn>
                  <Btn
                    v-else
                    type="transparent"
                    padding="xs"
                    class="table-peaks__btn-details"
                    @click="showShowFullPeakInformation(i.peak.id)"
                  >
                    Show more
                  </Btn>
                </td>
                <td style="padding-left: 0"></td>
              </tr>
              <template v-if="peakIdToShowFullInformation === i.peak.id">
                <tr :key="`${index}-details`">
                  <td colspan="10" class="table-peaks__details">
                    <div class="table-peaks__properties">
                      <DecoratorProperty
                        bgColor="#fff"
                        property="Theoretical Plates"
                        class="table-peaks__property"
                      >
                        {{ (i.peak.plates && i.peak.plates.toFixed(3)) || 'n/a' }}
                      </DecoratorProperty>
                      <DecoratorProperty
                        bgColor="#fff"
                        property="Asymmetry"
                        class="table-peaks__property"
                      >
                        {{ getPeakAsymmetry(i.peak) }}
                      </DecoratorProperty>
                      <DecoratorProperty
                        bgColor="#fff"
                        property="Fit Quality, R²"
                        class="table-peaks__property"
                      >
                        {{ formatPeakQuality(i.peak) }}
                      </DecoratorProperty>
                      <DecoratorProperty
                        bgColor="#fff"
                        property="FWHM, min"
                        class="table-peaks__property"
                      >
                        {{ (i.peak.fwhm && i.peak.fwhm.toFixed(3)) || 'n/a' }}
                      </DecoratorProperty>
                      <DecoratorProperty
                        bgColor="#fff"
                        property="Virtual area, mAU * min"
                        class="table-peaks__property"
                      >
                        {{ (i.peak.virtual_area && i.peak.virtual_area.toFixed(3)) || 'n/a' }}
                      </DecoratorProperty>
                      <DecoratorProperty
                        bgColor="#fff"
                        property="Noise to signal ratio"
                        class="table-peaks__property"
                      >
                        {{ (i.peak.sn_level && i.peak.sn_level.toFixed(3)) || 'n/a' }}
                      </DecoratorProperty>
                    </div>
                  </td>
                </tr>
                <!-- Stub for bg styling-->
                <tr :key="`${index}-stub`"></tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </template>

    <modal v-if="editable" center :width="512" name="sample-peak">
      <div v-if="modalUseCalibration.peak" class="modal-content">
        <h4>Use calibration</h4>
        <span style="margin-top: 16px">
          Peak at {{ modalUseCalibration.peak.apex }} min<br />
          Area {{ modalUseCalibration.peak.area }} mAU×min<br />
        </span>

        <label style="padding-top: 16px; width: 100%">
          Calibration
          <select v-model="modalUseCalibration.calibrationID" style="height: 32px; width: 100%">
            <option v-for="i in modalUseCalibration.calibrations" :key="i.id" :value="i.id">
              {{ i.name }}
              <template v-if="i.sequence_id">(this sequence)</template>
            </option>
          </select>
        </label>

        <button
          class="button--accent"
          style="margin-top: 32px; padding: 8px 16px"
          @click="applyCalibration(modalUseCalibration.peak.id, modalUseCalibration.calibrationID)"
        >
          Estimate
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
  import 'assets/css/component/modal.scss';

  import resize from 'vue-resize-directive';
  import ModalComponent from 'components/element/ModalComponent.vue';
  import { apiCalibrations } from '@/api/graphql/cloud/calibrations';
  import RemovePeakBtn from '@/components/blocks/charts/chromatogramNew/private/private/RemovePeakBtn.vue';
  import Btn from '@/uikitBase/btns/Btn.vue';
  import DecoratorProperty from '@/uikitProject/decorators/DecoratorProperty.vue';

  export default {
    name: 'PrChartSamplePeaks',

    directives: { resize },

    components: {
      DecoratorProperty,
      Btn,
      RemovePeakBtn,
      modal: ModalComponent,
    },

    props: {
      editable: {
        type: Boolean,
        default: true,
      },
      pestimations: Array,
      ppeaks: Array,
      measurementSocket: Object,
      highlightedPeakId: Number,
      focusedCompoundInputId: Number,
    },

    data() {
      return {
        estimations: this.pestimations,
        peaks: this.modifyPeaks(this.ppeaks),
        estimationsMap: this.createEstimationsMap(this.ppeaks, this.pestimations),
        modalUseCalibration: {
          peak: null,
          calibrations: [],
          calibrationID: undefined,
        },
        small: false,
        focusedPeakId: null,
        peakIdToShowFullInformation: null,
      };
    },

    computed: {
      peaksAndEstimations: {
        get() {
          const { peaks } = this;

          const r = [];
          for (let i = 0; i < peaks.length; i++) {
            const peak = peaks[i];
            let est = this.estimationsMap[peak.id];
            if (est == null) {
              est = {
                name: '',
                amount: null,
                unit: '',
              };
            }
            r.push(Object.assign({}, { peak }, { estimation: est }));
          }

          return r;
        },
        set() {
          //stub
        },
      },
    },

    watch: {
      pestimations(pestimations) {
        if (pestimations) {
          this.estimations = pestimations;
          this.estimationsMap = this.createEstimationsMap(this.peaks, this.estimations);
        }
      },

      ppeaks(ppeaks) {
        if (ppeaks) this.setPeaks(ppeaks);
      },

      async focusedCompoundInputId(id) {
        if (!id) return;
        await this.$nextTick();

        if (this.$refs.compoundInput?.length) {
          const input = this.$refs.compoundInput.find((input) => Number(input.id) === id);
          input.focus();
        }
      },

      measurementSocket() {
        this.loadCalibrations();
      },
    },

    mounted() {
      this.loadCalibrations();
    },

    methods: {
      async loadCalibrations() {
        if (!this.editable) {
          return;
        }

        const measurementId = this.measurementSocket?.id;
        if (!measurementId) {
          return;
        }

        this.modalUseCalibration.calibrations = await apiCalibrations.getForEstimationList(
          measurementId,
        );
      },

      formatPeakQuality(peak) {
        if (peak.quality_estimation_rsquared && peak.quality_estimation_rsquared !== -1) {
          const value = peak.quality_estimation_rsquared.toFixed(3);
          return value;
        }
        return 'n/a';
      },

      getPeakAsymmetry(peak) {
        return ((peak.end - peak.apex) / (peak.apex - peak.start)).toFixed(3);
      },

      onResize() {
        const w = this.$refs['sample-peaks'].getBoundingClientRect().width;
        this.small = w < 600;
      },

      modifyPeaks(peaks) {
        if (peaks == null) return peaks;
        for (let i = 0; i < peaks.length; i++) {
          // eslint-disable-next-line no-param-reassign
          peaks[i].apex = Math.round(peaks[i].apex * 1e3) / 1e3;
          // eslint-disable-next-line no-param-reassign
          peaks[i].area = Math.round(peaks[i].area * 1e3) / 1e3;
        }
        return peaks;
      },

      setPeaks(peaks) {
        this.peaks = this.modifyPeaks(peaks);
        this.estimationsMap = this.createEstimationsMap(this.peaks, this.estimations);
      },

      createEstimationsMap(peaks, estimations) {
        const estimationsMap = {};

        if (estimations != null) {
          for (let i = 0; i < estimations.length; i++) {
            const e = estimations[i];
            estimationsMap[e.peak_id.toString()] = e;
          }
        }

        if (peaks != null) {
          for (let i = 0; i < peaks.length; i++) {
            const peakId = peaks[i].id;
            const est = estimationsMap[peakId];
            if (est == null) {
              const e = {
                id: null,
                peak_id: peakId,
                name: null,
                amount: null,
                unit: null,
                accuracy: null,
                calibration_id: null,
              };
              estimations.push(e);
              estimationsMap[e.peak_id.toString()] = e;
            }
          }
        }

        return estimationsMap;
      },

      peakCalibration(peak) {
        this.$modal.show('sample-peak');
        this.modalUseCalibration.peak = peak;
      },

      updateEstimationName(estimationAndPeakItem, newName) {
        // eslint-disable-next-line no-param-reassign
        estimationAndPeakItem.estimation.name = newName;
        this.saveEstimations(estimationAndPeakItem.peak.id);
      },

      saveEstimations(peakId) {
        const e = this.estimationsMap[peakId];
        const amount = parseFloat(String(e.amount).replace(',', '.'));
        if (Number.isNaN(amount) || amount <= 0) {
          e.amount = null;
        } else {
          e.amount = amount;
        }

        this.showNotificationIfRpcError(() => this.measurementSocket.saveEstimation(e));
      },

      applyCalibration(peakID, calibrationID) {
        this.showNotificationIfRpcError(() =>
          this.measurementSocket.applyCalibration(peakID, calibrationID),
        );

        this.$modal.hide('sample-peak');
      },

      resetEstimation(estimationID) {
        this.showNotificationIfRpcError(() =>
          this.measurementSocket.deleteEstimation(estimationID),
        );
      },

      emitSelectionEvent(peakId, isSelected) {
        this.$emit('selection', { peakId, isSelected });
      },

      onMouseEnter(peakId) {
        this.emitSelectionEvent(peakId, true);
      },
      onMouseLeave(peakId) {
        if (this.focusedPeakId !== peakId) {
          this.emitSelectionEvent(peakId, false);
        }
      },

      focusPeak(peakId) {
        this.focusedPeakId = peakId;
        this.emitSelectionEvent(peakId, true);
      },
      blurPeak() {
        this.emitSelectionEvent(this.focusedPeakId, false);
        this.focusedPeakId = null;
      },
      blurCompoundInput() {
        this.$emit('update:focusedCompoundInputId', null);
      },

      getValue(value) {
        if (typeof value === 'number' && Number.isInteger(value)) {
          return value.toFixed(3);
        }
        return value;
      },

      checkIfValueIsNumber(e) {
        if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','].includes(e.key)) {
          e.preventDefault();
        }
      },

      showShowFullPeakInformation(peakId) {
        this.peakIdToShowFullInformation = peakId;
      },
      hideFullPeakInformation() {
        this.peakIdToShowFullInformation = null;
      },
    },
  };
</script>

<style lang="scss">
  .table-peaks-scrollable-container {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .table-peaks {
    height: 1px;
  }

  .table-peaks button {
    display: block;
    min-width: 16px;
    padding: 4px 8px;
    margin: 0;
    background: transparent;
    color: $color-text-primary;

    @include isHoverDevice {
      visibility: hidden;
    }
  }

  .table-peaks button:hover {
    background: #00000022;
  }

  .table-peaks button:active {
    background: #00000044;
  }

  .table-peaks__thead th {
    font-size: 13px;
    font-weight: bold;
    color: #00000066;
    padding: 2px 8px 6px 8px;
  }

  .table-peaks__thead th:first-child {
    padding-left: 32px;
  }

  .table-peaks__thead th:last-child {
    padding-right: 32px;
  }

  .table-peaks__tbody td {
    font-size: 13px;
    white-space: nowrap;
    padding: 4px 8px 3px 8px;
    width: 1px;
  }

  .table-peaks__tbody td:first-child {
    padding-left: 32px;
  }

  .table-peaks__tbody td:last-child {
    padding-right: 32px;
  }

  .table-peaks__tbody tr:hover button {
    visibility: visible;
  }

  .table-peaks__tbody tr:nth-child(2n-1) {
    background-color: #00000008;
  }

  .table-peaks__tbody tr:hover,
  .table-peaks__tbody tr.table-peaks__row--selected {
    background-color: #00000011;
  }

  .input-compound--focus-underline {
    input[type='text'].autosuggest__input,
    input:not([type]).autosuggest__input {
      border: 0 solid transparent !important;
      border-bottom-width: 2px !important;
      background: transparent;
      border-radius: 3px !important;
      padding: 6px 6px 4px 0 !important;
      font-size: 13px !important;
      outline: none !important;

      &:focus {
        border-width: 0 0 2px 0 !important;
        border-color: $color-bg-primary !important;
        outline: none !important;
      }
    }
  }

  input[type='text'].input--focus-underline,
  input:not([type]).input--focus-underline,
  input[type='number'].input--focus-underline {
    border: 0 solid transparent !important;
    border-bottom-width: 2px !important;
    background: transparent;
    border-radius: 3px !important;
    padding: 6px 6px 4px 0 !important;
    font-size: 13px !important;

    &:focus {
      border-width: 0 0 2px 0 !important;
      border-color: $color-bg-primary !important;
      outline: none !important;
    }
  }

  .table-peaks__tbody {
    .number {
      text-align: left;
    }

    .area {
      font-weight: bold;
      color: $color-text-info;
      text-align: right;
      padding-left: 12px;
    }

    .compound {
      width: auto;
      padding-right: 0;
      padding-left: 8px;
    }

    .unit {
      display: inline-block;
      width: 64px;
    }

    .amount {
      display: inline-block;
      text-align: right;
      width: 64px;
    }
  }

  @media only screen and (max-width: 700px) {
    .table-peaks__tbody {
      .number {
        padding-right: 0;
      }

      .area {
        padding-left: 8px;
      }

      .amount {
        width: auto;
      }

      .arrow {
        padding: 0 0 0 2px;
      }

      .compound {
        padding-left: 8px;
      }

      .unit {
        width: auto;
      }
    }

    .table-peaks__thead {
      .number {
        padding-right: 0;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .table-peaks {
    &__input {
      flex: 1 !important;
      width: 100% !important;
      outline: none;
      color: inherit;
      padding: 0 5px !important;
      transition: border-color 67ms;
      border: none !important;
      background-color: transparent;
      text-indent: 6px !important;
      height: 34px;
      border-bottom: 2px solid transparent !important;
      -webkit-appearance: none;

      &:focus {
        border-bottom-color: $color-text-primary !important;
        border-radius: 4px !important;
      }
    }

    &__btn-details {
      width: 84px;
    }

    &__details {
      padding: 20px 0;
      background-color: white;
      box-shadow: inset 0 10px 5px -10px rgba(0, 0, 0, 0.2),
        inset 0 -10px 5px -10px rgba(0, 0, 0, 0.2);
    }

    &__properties {
      position: sticky;
      left: 32px;
      width: min(100%, 100vw - 64px);
    }

    &__property:not(:last-child) {
      margin-bottom: 5px;
    }
  }
</style>

<style scoped>
  .table-peaks__tbody tr >>> input::placeholder {
    color: #00000000;
  }

  .table-peaks__tbody tr:hover >>> input::placeholder {
    color: #00000066;
  }
</style>
