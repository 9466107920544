<template>
  <div>
    <div class="grid-header-container">
      <router-link style="display: inline-block; white-space: nowrap" to="/calibrations/">
        <i class="material-icons material-icon--16 no-text-decoration">keyboard_backspace</i>
        Calibrations
      </router-link>

      <span style="display: block; color: #00000066; text-align: center">
        <i class="material-icons material-icon--14">star</i>
        Create a New Calibration
      </span>
    </div>

    <CalibrationNewComponent style="margin-top: 16px" />
  </div>
</template>

<script>
  import 'assets/css/component/new-sam-cal-seq.scss';
  import PageTitle from '@/mixins/page-title';
  import CalibrationNewComponent from 'components/block/CalibrationNewComponent';

  export default {
    name: 'SampleNewPage',

    components: {
      CalibrationNewComponent,
    },

    mixins: [PageTitle],

    data: () => ({
      pageTitle: 'HPLC Cloud: New Calibration',
    }),
  };
</script>
