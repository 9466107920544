<template>
  <IconMaterial title="star" size="16" />
</template>

<script>
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  export default {
    name: 'IconCalibration',

    components: {
      IconMaterial,
    },
  };
</script>
