<template>
  <WithKeyboardSelection
    v-if="items.length"
    #default="{ selectedId }"
    :ids="itemIds"
    :onSelect="selectItemById"
    :isActive="isActive"
    :scrollContainer="scrollContainer"
    @highlight="previewItem"
  >
    <ul class="list">
      <li
        v-if="canCreate"
        class="list__item list__item--create"
        :class="[`list__item--padding--${padding}`]"
        @click="$emit('create')"
      >
        <IconMaterial title="add" class="list__icon-add" />
        <span class="list__label">{{ messageCreate }}</span>
      </li>
      <li
        v-for="(item, index) of items"
        :key="item.id || index"
        class="list__item"
        :class="[
          `list__item--padding--${padding}`,
          {
            'list__item--active': item.id === selectedId,
            'list__item--selected': itemSelected && item.id === itemSelected.id,
          },
        ]"
        :data-id="item.id"
        @click="selectItem(item)"
      >
        <span class="list__label" :title="item[labelProp]">{{ item[labelProp] }}</span>
        <span v-if="$scopedSlots.info" class="list__label-info">
          <slot name="info" :item="item" />
        </span>
      </li>
    </ul>
  </WithKeyboardSelection>
</template>

<script>
  import WithKeyboardSelection from '@/hoc/WithKeyboardSelection';
  import IconMaterial from '@/uikitBase/icons/IconMaterial.vue';

  const EVENT_PREVIEW = 'preview';
  const EVENT_CONFIRM = 'confirm';

  export default {
    name: 'List',

    components: {
      IconMaterial,
      WithKeyboardSelection,
    },

    props: {
      items: {
        type: Array,
        required: true,
      },
      itemSelected: {
        type: Object,
      },
      isActive: {
        type: Boolean,
        default: false,
      },
      labelProp: {
        type: String,
        required: true,
      },
      scrollContainer: {
        type: HTMLElement,
      },
      padding: {
        type: String,
        default: 'sm',
        validator(value) {
          const availableValues = ['sm', 'md'];
          return availableValues.includes(value);
        },
      },
      messageCreate: {
        type: String,
        default: 'Create',
      },
      canCreate: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      itemIds() {
        return this.items.map((item) => item.id);
      },
      focusedItem() {
        return this.items.find((item) => item.id === this.sel);
      },
    },

    methods: {
      previewItem(itemId) {
        const item = this.items.find((_item) => _item.id === Number(itemId));
        this.$emit(EVENT_PREVIEW, item);
      },
      selectItem(item) {
        this.$emit(EVENT_CONFIRM, item);
      },
      selectItemById(itemId) {
        const item = this.items.find((_item) => _item.id === Number(itemId));
        this.$emit(EVENT_CONFIRM, item);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .list {
    min-width: 100%;
    overflow: hidden;
    border-radius: $border-radius__sm;
    background-color: white;
    padding-bottom: 10px;

    &__item {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 28px;

      font-size: 13px;
      text-align: left;

      cursor: pointer;

      &:nth-child(even) {
        background-color: $color-bg-transparent--nth;
      }

      &--create {
        justify-content: flex-start;
      }

      &--selected,
      &--selected:nth-child(even) {
        color: $color-text-on-primary;
        background: #3e39bd;
      }

      &:active {
        background-color: $color-bg-transparent--active;
      }

      &--padding {
        &--sm {
          padding: 0 11px;
        }

        &--md {
          padding: 0 25px;
        }
      }

      @include isHoverDevice() {
        &:hover,
        &--active {
          background-color: $color-bg-transparent--active;
        }
      }
    }

    &__label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__label-info {
      flex: none;
      display: flex;
      align-items: center;
    }
  }
</style>
