// eslint-disable-next-line max-classes-per-file
import { REST } from 'utils/rest';

const url = '/api/calibrations/';

export class CalibrationAPI {
  static post(data, onSuccess, onError) {
    return REST.post(url, data, onSuccess, onError);
  }
}
