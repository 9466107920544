import { makeGraphqlRequestCloud } from 'api/graphql/graphQLClient';
import { SequenceReportData } from '@/graphql/cloud/sequences/queries/SequenceReportData.graphql';

export const apiSequences = {
  async getSequenceReportData(id) {
    const { sequence: sequenceReportData } = await makeGraphqlRequestCloud(SequenceReportData, {
      id,
    });

    if (!sequenceReportData) {
      throw new Error('Sequence report data was not received!');
    }

    return sequenceReportData;
  },
};
