import { INJECTION_STATES } from '@/constants/injections/states';
import { ValuesOf } from '@/types/utility';

type Injection = { state: ValuesOf<typeof INJECTION_STATES> };

export const isInjectionInProgress = (injection: Injection) =>
  ([
    INJECTION_STATES.PENDING,
    INJECTION_STATES.PREPARING,
    INJECTION_STATES.RUNNING,
    INJECTION_STATES.FINISHING,
  ] as string[]).includes(injection.state);

export const isInjectionFailed = (injection: Injection) =>
  injection.state === INJECTION_STATES.FAILED;
