<template>
  <FormCreation
    v-model="dmc"
    :isValid="isValid"
    :isRequiredDevice="true"
    :canSelectMethod="true"
    requireColumn
    style="margin-top: 16px; padding: 32px 16px 32px 16px"
    class="calibration-new"
    @submit="createCalibration"
  >
    <DecoratorInput
      iconSize="md"
      iconTitle="star"
      label="Compound name"
      style="margin-bottom: 16px"
    >
      <CompoundAutosuggest
        ref="name"
        v-model="name"
        :placeholder="defaultName"
        :isShowAllSuggestionsOnFocus="true"
        @mounted="focusInputName"
      >
        <template
          #default="{
            value,
            setValue,
            handleInputFocus,
            handleInputBlur,
            isDisabled,
            id,
            placeholder,
          }"
        >
          <input
            :id="id"
            type="text"
            class="calibration-new__input-title"
            :value="value"
            :disabled="isDisabled"
            :placeholder="placeholder"
            autocomplete="off"
            maxlength="200"
            @input="setValue($event.target.value)"
            @focus="handleInputFocus"
            @blur="handleInputBlur"
          />
        </template>

        <template #mobileTrigger="{ value }">
          <input :value="value" type="text" disabled class="calibration-new__input-title" />
        </template>
      </CompoundAutosuggest>
    </DecoratorInput>

    <DecoratorInput
      iconSize="sm"
      iconTitle="notes"
      label="Description"
      :isOptional="true"
      style="margin-bottom: 16px"
    >
      <textarea v-model="description" class="field" rows="2" />
    </DecoratorInput>
  </FormCreation>
</template>

<script>
  import 'assets/css/component/new-sam-cal-seq.scss';
  import { CalibrationAPI } from 'api/calibration';
  import FormCreation from 'components/blocks/forms/Creation';
  import DecoratorInput from '@/uikitProject/decorators/DecoratorInput';
  import CompoundAutosuggest from '@/uikitProject/tables/shared/vueTableCell/private/CompoundAutosuggest';

  const EVENT_CREATE = 'create';

  export default {
    name: 'CalibrationNewComponent',

    components: {
      CompoundAutosuggest,
      FormCreation,
      DecoratorInput,
    },

    data() {
      const defaultName = 'Compound name';

      return {
        defaultName,
        name: defaultName,
        description: '',
        compounds: [],
        dmc: {
          device: null,
          method: null,
          column: null,
        },
      };
    },

    computed: {
      isValid() {
        return (
          this.name.length > 0 &&
          this.dmc.device != null &&
          this.dmc.method != null &&
          this.dmc.column != null
        );
      },
    },

    methods: {
      async createCalibration() {
        if (!this.isValid) {
          return;
        }
        const data = {
          name: this.name !== this.defaultName ? this.name : null,
          description: this.description,
          device_id: this.dmc.device.id,
          method_id: this.dmc.method.id,
          column_id: this.dmc.column.id,
        };

        await CalibrationAPI.post(
          data,
          (r) => {
            const { id } = r;
            this.$router.push({ name: 'calibration', params: { id } });
            this.$emit(EVENT_CREATE);
          },
          (r, c) => {
            if (r) {
              this.message = r.detail;
            } else {
              this.message = `Error ${c}`;
            }
          },
        );
      },

      async focusInputName() {
        await this.$nextTick();
        this.$refs.name?.focus();
        this.$refs.name?.select();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .calibration-new {
    &__input-title {
      font-size: $size-lg;
      font-weight: $weight-bold;
    }
  }
</style>
