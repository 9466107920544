<template>
  <div class="mobile-select">
    <div class="mobile-select__wrapper-trigger" @click="showPopup">
      <slot name="mobileTrigger" :value="selectItem[this.labelProp]">
        <div v-if="title" class="mobile-select__title">{{ title }}</div>

        <SelectTrigger
          ref="inputSearch"
          :itemSelected="itemSelected"
          :labelProp="labelProp"
          :isShow="isShowPopup"
          :isTransparent="isTransparent"
          :hasSearch="false"
          :padding="padding"
          :messageNoItemSelected="messageNoItemSelected"
          :messageNoItems="messageNoItems"
          :isShowSelectedItem="isShowSelectedItem"
          :title="title"
        >
          <template #info>
            <slot
              name="rightSideInsideItem"
              :item="itemSelected"
              :isBottomSheet="false"
              :hide="hidePopup"
            />
          </template>
        </SelectTrigger>
      </slot>
    </div>

    <BottomSheet
      :isShow="isShowPopup"
      :title="titleBottomSheet"
      :hasPadding="false"
      @hide="hidePopup"
    >
      <div class="mobile-select__content">
        <div v-if="hasSearch" class="mobile-select__wrapper-input">
          <Input
            :value="searchValue"
            class="mobile-select__input"
            :placeholder="placeholderSearch"
            height="md"
            :hasClearBtn="true"
            v-bind="$attrs"
            @model="setSearchValue"
          />
        </div>

        <div class="mobile-select__list">
          <!--          <LoaderSpinner v-if="isLoadingSuggestions" class="mobile-select__loader-spinner" />-->

          <List
            :items="itemsFiltered"
            :itemSelected="itemSelected"
            :labelProp="labelProp"
            padding="md"
            :canCreate="canCreate"
            :messageCreate="messageCreate"
            @confirm="selectItem"
            @create="create"
          >
            <template #info="{ item }">
              <slot
                name="rightSideInsideItem"
                :item="item"
                :hide="hidePopup"
                :isBottomSheet="true"
              />
            </template>
          </List>
        </div>
      </div>
    </BottomSheet>
  </div>
</template>

<script>
  import {
    model,
    props,
    EVENT_MODEL,
    EVENT_SEARCH,
    EVENT_MOUNTED,
    EVENT_SHOW_POPUP,
    EVENT_HIDE_POPUP,
  } from '../shared/config';
  import BottomSheet from '@/uikitBase/popups/BottomSheet';
  import Input from '@/uikitBase/inputs/Input';
  import List from '@/uikitBase/lists/List';
  import SelectTrigger from '@/uikitBase/selects/vueSelect/shared/SelectTrigger';

  export default {
    name: 'MobileSelect',

    components: {
      SelectTrigger,
      List,
      Input,
      BottomSheet,
    },

    inheritAttrs: false,

    model,

    props: {
      ...props,

      titleBottomSheet: {
        type: String,
        required: true,
      },
    },

    data: () => ({
      isShowPopup: false,
      searchValue: '',
    }),

    computed: {
      /***
       * Convert items to Array of objects
       */
      itemsParsed() {
        if (this.items.length) {
          const [item] = this.items;

          if (typeof item !== 'object') {
            return this.items.map((item, index) => ({ name: String(item), id: index }));
          }
        }

        return this.items;
      },
      itemsFiltered() {
        return this.itemsParsed.filter(
          (item) =>
            (item[this.labelProp] !== this.searchValue &&
              item[this.labelProp].toLowerCase().includes(this.searchValue.toLowerCase())) ||
            (this.canSearchById && item.id === Number(this.searchValue)),
        );
      },
    },

    mounted() {
      this.$emit(EVENT_MOUNTED);
    },

    methods: {
      setSearchValue(value) {
        this.searchValue = value;
        this.$emit(EVENT_SEARCH, value);
      },

      showPopup() {
        if (!this.isDisabled) {
          this.isShowPopup = true;
          this.$emit(EVENT_SHOW_POPUP);
        }
      },
      hidePopup() {
        this.isShowPopup = false;
        this.setSearchValue('');
        this.$emit(EVENT_HIDE_POPUP);
      },
      hidePopupWithSave(value) {
        this.hidePopup();
        this.saveChanges(value);
      },

      saveChanges(value) {
        this.$emit(EVENT_MODEL, value);
      },

      selectItem(item) {
        this.hidePopupWithSave(item);
      },

      create() {
        this.hidePopup();
        this.$emit('create');
      },
      edit() {
        this.hidePopup();
        this.$emit('create');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .mobile-select {
    display: flex;
    flex: 1;

    &__title {
      font-size: 13px;
      color: $color-text-third;
      margin-bottom: 3px;
    }

    &__wrapper-trigger {
      width: 100%;
    }

    &__trigger {
      background-color: transparent;
      pointer-events: none;
    }

    &__content {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      max-height: calc(100% - 15px);
    }

    &__wrapper-input {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      height: 38px;
      min-height: 38px;
      padding: 0 14px;
    }

    &__input {
      font-size: $size-sm;
      height: 100%;
    }

    &__btn {
      flex: none;
      font-size: 20px;
      height: 38px;
      width: 38px;
    }

    &__list {
      flex: 1;
      position: relative;
      overflow-y: auto;
      min-height: 100px;
    }

    &__loader-spinner {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
</style>
