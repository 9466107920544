import { render, staticRenderFns } from "./RecentInjections.vue?vue&type=template&id=20ffcc6a&scoped=true&"
import script from "./RecentInjections.vue?vue&type=script&lang=js&"
export * from "./RecentInjections.vue?vue&type=script&lang=js&"
import style0 from "./RecentInjections.vue?vue&type=style&index=0&id=20ffcc6a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20ffcc6a",
  null
  
)

export default component.exports