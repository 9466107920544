<template>
  <div class="sequence-page">
    <SequenceReportModal v-if="sequence" :sequenceId="sequence.id" />

    <div class="top-line">
      <router-link
        v-if="isShowLinkToList"
        id="link-navigate-back"
        class="link-navigate-back"
        to="/sequences/"
      >
        <i class="material-icons material-icon--16 no-text-decoration">keyboard_backspace</i>
        Sequences
      </router-link>
    </div>

    <div v-if="sequence != null" class="paper-block-container paper-block-container--main mt-4">
      <div class="paper__top">
        <div class="paper__header">
          <div>
            <InputTitle v-model="sequence.name" iconName="group_work" @save="saveName" />
            <div class="pt-1">
              <span id="sequence-id" class="mr-3">Sequence</span>

              <span
                class="mr-3"
                style="color: #00000088"
                v-html="new Date(sequence.created).toLocaleDateString()"
              />

              <div v-if="device && device.id != null" style="margin-right: 12px; display: inline">
                <router-link :to="linkToDevicePage">
                  <IconMaterial title="input" :size="16" />
                  {{ device.name }}</router-link
                >
              </div>

              <StatesForEntity :state="sequence.state" class="mr-3" />

              <StateArchived v-if="sequence.archived" class="mr-3" />
            </div>
          </div>
          <div>
            <button v-if="sequence.archived" class="button--square-image" @click="restore">
              <i class="material-icons material-icon--18">restore</i>
              Restore
            </button>
            <button v-else-if="isEditMode" class="button--square-image" @click="finishEditing">
              <i class="material-icons material-icon--18">done</i>
              Finish <br />
              editing
            </button>

            <MoreMenuComponent v-show="!sequence.archived && !isEditMode">
              <button class="button--square-image" @click="startEditing">
                <i class="material-icons material-icon--18">edit</i>
                Edit
              </button>
              <button v-if="true" class="button--square-image" @click="showReportModal">
                <i class="material-icons material-icon--18">picture_as_pdf</i>
                Report
              </button>
              <button class="button--square-image" @click="clone">
                <i class="material-icons material-icon--18">content_copy</i>
                Clone
              </button>
              <button
                v-if="sequence.can_archive"
                :disabled="sequence.state === 'EXE' || sequence.state === 'PAU'"
                class="button--square-image"
                @click="archive"
              >
                <i class="material-icons material-icon--18">delete</i>
                Archive
              </button>
            </MoreMenuComponent>
          </div>
        </div>

        <div class="paper__content--margin-32">
          <ModelTextareaComponent
            ref="description"
            v-model="sequence.description"
            :canEdit="sequence.can_edit"
            :label="'Description'"
            style="margin-top: 16px"
            :isEditMode="isEditMode"
            @update:value="saveDescription"
            @click="startEditing"
          />

          <label
            v-if="sequence.compounds || isEditMode"
            class="label-block-margin"
            style="margin: 16px 0 0"
          >
            Compounds
          </label>
          <SelectorMultipleCompounds
            v-if="isEditMode"
            :compounds="compoundsParsed"
            @update:compounds="saveCompounds"
          />
          <div v-else-if="sequence.compounds" @click="startEditing">
            {{ sequence.compounds }}
          </div>
        </div>

        <div class="mt-6" style="height: 56px">
          <div class="buttons--float-right">
            <!--            <button-->
            <!--              v-if="sequence.state !== 'EXE'"-->
            <!--              :disabled="tableEmpty"-->
            <!--              class="button&#45;&#45;square-image"-->
            <!--              @click="tableClear"-->
            <!--            >-->
            <!--              <i class="material-icons material-icon&#45;&#45;18">clear</i>-->
            <!--              Clear-->
            <!--            </button>-->

            <PopupHelper
              v-if="sequence.can_start"
              text="Starts sequence from next available instruction."
              class="sequence-page__wrapper-btn"
            >
              <button
                :disabled="!canStartSequence"
                class="button--square-image-primary"
                @click="tableStart"
              >
                <i class="material-icons material-icon--18">play_arrow</i>
                Start
              </button>
            </PopupHelper>
            <PopupHelper
              v-if="sequence.can_pause"
              text="Pause the sequence in its current Instruction. The current injection will run to completion."
              class="sequence-page__wrapper-btn"
            >
              <button class="button--square-image" @click="tablePause">
                <i class="material-icons material-icon--18">pause</i>
                Pause
              </button>
            </PopupHelper>
            <button
              v-if="sequence.can_resume"
              class="button--square-image-primary"
              @click="tableResume"
            >
              <i class="material-icons material-icon--18">play_arrow</i>
              Resume
            </button>
            <PopupHelper
              v-if="sequence.can_stop"
              text="Stops the sequence. The current injection will run to completion. The current instruction cannot be resumed."
              class="sequence-page__wrapper-btn"
            >
              <button class="button--square-image-red" @click="tableStop">
                <i class="material-icons material-icon--18">stop</i>
                Stop
              </button>
            </PopupHelper>
          </div>

          <h4
            style="
              margin: 0 0 8px 32px;
              padding-top: 24px;
              white-space: nowrap;
              width: initial;
              display: inline-block;
            "
          >
            Sequence Table
          </h4>
        </div>

        <transition-expand>
          <div v-if="activeSample" style="margin-top: 16px; width: 100%; display: block">
            <div
              style="
                margin-right: 32px;
                margin-left: 32px;
                display: flex;
                justify-content: space-between;
              "
            >
              <div style="padding-top: 3px">
                <div>
                  <StateProcess title="Running" class="mr-2" />
                  <IconCalibration v-if="activeSample.type === 'C'" />
                  <IconSample v-else />
                  <span>
                    {{ activeSample.name }}
                  </span>
                </div>
                <div class="text-process mt-2">
                  stop or wait it's completion to execute sequence table
                </div>
              </div>
              <PopupHelper text="View the current injection.">
                <Btn
                  class="ml-4"
                  height="s"
                  icon-left="visibility"
                  @click="selectSample(activeSample.id, undefined, true)"
                >
                  View
                </Btn>
              </PopupHelper>
            </div>
          </div>
        </transition-expand>

        <SequenceTableComponent
          v-if="children && Object.keys(children).length > 0"
          :current="currentDraft"
          :method-default="method"
          :configurationForMethod="device.configuration_for_method"
          :samples="children"
          :table="table"
          :device="device"
          class="mt-6"
          @update:draft="updateDraft"
          @update:table="updateTable"
          @removeDraft="removeDraft"
          @view="(draft) => selectSample(draft.sample, draft.sample_injection, true)"
          @add="draftAdd"
        />
      </div>

      <!--Samples block-->

      <div ref="samples-block" class="block--gray block--shadow-in-top">
        <div v-if="sid == null">
          <h4
            style="margin: 40px 16px 24px 32px; display: inline-block"
            @click="selectSample(null)"
          >
            Samples
          </h4>
          <ShowArchivedToggle v-model="isShowArchived" :n-archived="hasArchived" />
        </div>
        <button
          v-else
          class="rect-button sequence-page__btn-back-desktop"
          style="margin: 0; padding: 32px 32px 8px 32px; height: 80px"
          @click="selectSample(null)"
        >
          <i class="material-icons material-icon--16 no-text-decoration" style="margin-bottom: 1px">
            keyboard_backspace
          </i>
          Samples
        </button>

        <div v-show="sid == null" style="padding-bottom: 32px">
          <table class="table-default table-default--offset-32">
            <thead
              v-if="childrenFiltered && childrenFiltered.length > 0"
              style="background-color: #0000000a"
            >
              <tr>
                <th class="td--shrink"></th>
                <th class="sequence-page__cell--sortable" @click="sortBy('name')">
                  Name
                  <SortDirection
                    :direction="sortedBy.direction"
                    class="sequence-page__sort-direction"
                    :class="{ 'sequence-page__sort-direction--visible': sortedBy.field === 'name' }"
                  />
                </th>
                <th v-if="hasVial">Vial</th>
                <th class="sequence-page__cell--sortable" @click="sortBy('last_modified')">
                  Date
                  <SortDirection
                    :direction="sortedBy.direction"
                    class="sequence-page__sort-direction"
                    :class="{
                      'sequence-page__sort-direction--visible': sortedBy.field === 'last_modified',
                    }"
                  />
                </th>
                <th @click="sortBy('last_modified')"></th>
              </tr>
            </thead>
            <tbody v-if="childrenFiltered && childrenFiltered.length > 0" class="tbody__td--nowrap">
              <tr
                v-for="child in childrenFiltered"
                :key="child.id"
                class="row--clickable"
                @click="selectSample(child.id)"
              >
                <td class="td--shrink">
                  <i v-if="child.archived" class="material-icons material-icon--16 text-red">
                    delete
                  </i>
                  <i v-else-if="child.type === 'S'" class="material-icons material-icon--16">
                    opacity
                  </i>
                  <i v-else-if="child.type === 'C'" class="material-icons material-icon--16">
                    start
                  </i>
                </td>
                <td class="text-bold-blue td--overflow-hidden">
                  {{ child.name }}
                </td>
                <td v-if="hasVial" class="text-gray td--shrink right">
                  {{ child.vial }}
                </td>
                <td class="td--shrink text-gray" v-html="formatDate(child.last_modified)" />
                <td
                  class="td--shrink text-gray right"
                  :class="{ 'sequence-page__cell--selected': child.id === Number(selectedItemId) }"
                >
                  {{ formatTime(child.last_modified) }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr v-if="isShowCreationForm">
                <td colspan="6" style="padding-top: 1px; padding-bottom: 1px">
                  <div class="sequence-page__form-create-sample">
                    <input
                      ref="inputNewSampleName"
                      v-model="inputs.sampleName"
                      type="text"
                      required
                      placeholder="Name"
                      class="sequence-page__input-name"
                    />
                    <Btn
                      type="primary"
                      :isSubmit="true"
                      class="sequence-page__btn-add-sample"
                      padding="xs"
                      iconLeft="add"
                      @click="addSample"
                    >
                      Sample
                    </Btn>
                    <Btn
                      type="primary"
                      :isSubmit="true"
                      class="sequence-page__btn-add-sample"
                      padding="xs"
                      iconLeft="add"
                      @click="addCalibration"
                    >
                      Calibration
                    </Btn>
                    <Btn
                      class="sequence-page__btn-hide-form"
                      padding="none"
                      @click="hideCreationForm"
                    >
                      <IconMaterial title="close" />
                    </Btn>
                  </div>
                </td>
              </tr>
              <tr v-else>
                <td colspan="6" @click="showCreationForm">
                  <IconMaterial title="add" size="16" class="sequence-page__icon-add-sample" />
                  <span class="sequence-page__label-add-sample">Add sample or calibration</span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div v-show="sid != null" class="sequence__samples-expanded">
          <div class="sequence-page__wrapper-pager">
            <button
              class="rect-button sequence-page__btn-back-adaptive"
              @click="selectSample(null)"
            >
              <i
                class="material-icons material-icon--16 no-text-decoration"
                style="margin-bottom: 1px"
              >
                keyboard_backspace
              </i>
              Samples
            </button>
            <PagerComponent
              v-if="sid != null"
              :current="selectedSampleIndex + 1"
              :next-enabled="nextID"
              :prev-enabled="prevID"
              :total="totalSamples"
              class="samples__pager"
              @next="next"
              @prev="prev"
              @prevToStart="goToFirstSample"
              @nextToEnd="goToLastSample"
            />
          </div>
          <div class="samples__sample-tabs-container">
            <div class="samples__sample-tabs-inner-container">
              <div
                v-for="child in childrenFiltered"
                :key="child.id"
                :ref="'tab' + child.id.toString()"
                :class="{
                  'samples__sample-tab-button--selected': sid === child.id,
                  'samples__sample-tab-button--gray': child.archived,
                }"
                class="samples__sample-tab-button"
                @click="selectSample(child.id)"
              >
                {{ child.name }}
              </div>
            </div>
          </div>
          <div class="samples__sample-expanded-container">
            <SampleComponent
              v-if="safeSampleID != null"
              v-show="sid != null && children[sid].type === 'S'"
              :id="safeSampleID"
              ref="selectedSample"
              :injectionID="iid"
              :sampleData="children[safeSampleID]"
              :isPaused="isSamplePaused(safeSampleID)"
              @update:injectionID="newInjectionID"
              @archive="hideCurrentChild"
            />
            <CalibrationComponent
              v-if="safeCalibrationID"
              v-show="sid != null && children[sid].type === 'C'"
              :id="safeCalibrationID"
              ref="selectedSample"
              :calibrationData="children[safeCalibrationID]"
              :isPaused="isSamplePaused(safeCalibrationID)"
              :injectionID="iid"
              @update:injectionID="newInjectionID"
              @archive="hideCurrentChild"
            />
          </div>
        </div>
      </div>
    </div>
    <error-component v-else-if="errorCode != null" :code="errorCode" style="margin-top: 64px" />
    <loading-component v-else />
  </div>
</template>

<script>
  import 'assets/css/base/table.scss';

  import _ from 'lodash';

  import StateArchived from '@/uikitProject/states/StateArchived';
  import CalibrationComponent from 'components/block/CalibrationComponent';
  import ErrorComponent from 'components/element/ErrorComponent';
  import LoadingComponent from 'components/element/LoadingComponent';
  import ModelTextareaComponent from 'components/element/ModelTextareaComponent';
  import MoreMenuComponent from 'components/element/MoreMenuComponent';
  import PageTitle from '@/mixins/page-title';
  import SampleComponent from 'components/block/SampleComponent';
  import SequenceSocket, { SequenceSocketEvents } from 'api/sockets/SequenceSocket';
  import SequenceTableComponent from '@/components/block/vueSequenceTable/SequenceTable.vue';
  import ShowArchivedToggle from 'components/element/ShowArchivedToggle';
  import StatesForEntity from '@/uikitProject/states/packs/StatesForEntity';
  import PagerComponent from 'components/block/PagerComponent';
  import TransitionExpand from '@/uikitProject/transitions/TransitionExpand';
  import Btn from '@/uikitBase/btns/Btn';
  import IconSample from '@/uikitProject/icons/IconSample';
  import IconCalibration from '@/uikitProject/icons/IconCalibration';
  import StateProcess from '@/uikitProject/states/StateProcess';
  import RouterHelper from 'utils/RouterHelper.ts';
  import { isPrimaryView, isSecondaryView } from '@/components/blocks/layouts/dual/SecondaryView';
  import InputTitle from '@/uikitProject/inputs/inputTitle/InputTitle';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import SplitScreenDisabledError from 'utils/errors/SplitScreenDisabledError.ts';
  import SortDirection from '@/uikitProject/tables/SortDirection';
  import SortHelper from 'utils/SortHelper.ts';
  import SelectorMultipleCompounds from '@/uikitProject/compounds/vueSelectorMultipleCompounds/SelectorMultipleCompounds';
  import { consoleHelpers } from 'utils/logHelpers';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper.vue';
  import SequenceReportModal from '@/components/block/modal/SequenceReportModal.vue';
  import { SAMPLE_INJECTIONS_LIMIT } from '@/constants/samples/limits';

  export default {
    name: 'SequencePage',

    components: {
      SequenceReportModal,
      PopupHelper,
      SelectorMultipleCompounds,
      SortDirection,
      IconMaterial,
      StateProcess,
      IconCalibration,
      IconSample,
      Btn,
      TransitionExpand,
      InputTitle,
      PagerComponent,
      ErrorComponent,
      ShowArchivedToggle,
      CalibrationComponent,
      LoadingComponent,
      SequenceTableComponent,
      ModelTextareaComponent,
      MoreMenuComponent,
      SampleComponent,
      StatesForEntity,
      StateArchived,
    },

    inject: {
      isPrimaryView,
      isSecondaryView,
    },

    mixins: [PageTitle],

    props: {
      id: Number,
      sid: Number, // sample ID
      iid: Number, // injection ID
    },

    data() {
      return {
        pageTitle: 'HPLC Cloud: Sequence',
        sequence: null,
        device: null,
        method: null,
        column: null,
        nameField: {
          visible: false,
          value: '',
        },
        table: null,
        children: [],
        sequenceSocket: this.createSocket(this.id),
        safeSampleID: undefined,
        safeCalibrationID: undefined,
        isShowArchived: false,
        errorCode: null,
        activeSample: null, // When sequence is not executing

        sortedBy: {
          field: null,
          direction: 'asc',
        },

        isShowCreationForm: false,
        inputs: {
          sampleName: '',
        },

        listenersGroupId: null,

        isEditMode: false,
      };
    },

    computed: {
      hasArchived() {
        if (!this.children) return false;

        return _.countBy(this.children, 'archived').true || 0;
      },
      hasVial() {
        if (!this.children) return false;
        return Object.values(this.children).some((o) => o.vial);
      },
      childrenFiltered() {
        if (this.isShowArchived) {
          return Object.values(this.children);
        }

        const childrenWithoutArchived = Object.values(_.pickBy(this.children, (o) => !o.archived));

        if (!this.sortedBy.field) {
          return childrenWithoutArchived;
        }

        const comparator = SortHelper.getBaseComparator(
          this.sortedBy.field === 'name' ? 'string' : 'datetime',
          this.sortedBy.direction,
        );

        childrenWithoutArchived.sort((a, b) => {
          return comparator(b[this.sortedBy.field], a[this.sortedBy.field]);
        });

        return childrenWithoutArchived;
      },

      prevID() {
        return this.childrenFiltered?.[this.selectedSampleIndex - 1]?.id;
      },
      nextID() {
        return this.childrenFiltered?.[this.selectedSampleIndex + 1]?.id;
      },
      selectedSampleIndex() {
        return this.childrenFiltered?.findIndex((child) => Number(child.id) === Number(this.sid));
      },
      totalSamples() {
        return this.childrenFiltered?.length;
      },

      isTableEmpty() {
        return this.table == null || Object.keys(this.table).length === 0;
      },

      linkToDevicePage() {
        try {
          const routeSecondary = RouterHelper.getRouteSecondary(this.$route);
          if (
            routeSecondary &&
            (routeSecondary.name === 'sample injection' ||
              routeSecondary.name === 'calibration injection' ||
              routeSecondary.name === 'calibration' ||
              routeSecondary.name === 'sample')
          ) {
            const { id, iid } = routeSecondary.params;

            const pathSecondaryURL = `/sequences/${this.id}/${id}` + (iid ? `/${iid}` : '');

            return {
              name: 'dual.device',
              params: {
                id: this.device?.id,
              },
              query: {
                pathSecondary: decodeURIComponent(pathSecondaryURL),
                rawLocation: true,
              },
            };
          }

          const link = {
            name: 'device',
            params: { id: this.device?.id },
            query: { setAsPrimary: true },
          };

          if (!this.isSecondaryView) {
            link.query.makePrimaryAsSecondary = true;
          }

          return link;
        } catch (e) {
          if (e instanceof SplitScreenDisabledError) {
            return {
              name: 'device',
              params: { id: this.device?.id },
            };
          }
          throw e;
        }
      },

      selectedItemId() {
        if (RouterHelper.isDualMode) {
          return RouterHelper.getRouteSecondary(this.$route)?.params?.id;
        }
        return null;
      },

      compoundsParsed() {
        return (
          this.sequence?.compounds?.split(';').map((compound) => ({ name: compound.trim() })) ?? []
        );
      },

      isShowLinkToList() {
        return !this.isSecondaryView;
      },

      enabledDrafts() {
        return Object.values(this.table).filter((draft) => draft.enabled);
      },
      areAllEnabledDraftsValid() {
        return this.enabledDrafts.every((draft) => this.isValidDraft(draft));
      },
      canStartSequence() {
        return (
          !this.isTableEmpty &&
          this.enabledDrafts.length > 0 &&
          !this.activeSample &&
          this.areAllEnabledDraftsValid
        );
      },

      currentDraft() {
        const { state } = this.sequence;
        return state === 'EXE' || state === 'PAU' || state === '-'
          ? {
              ...this.sequence.draft_info,
              sampleId: this.table[this.sequence.draft_info.id]?.sample,
              state,
            }
          : null;
      },
    },

    watch: {
      id(id) {
        if (id == null) throw Error('Sequence id is null!');
        this.sequence = null;
        this.errorCode = null;
        this.sequenceSocket = this.createSocket(id);
      },
      sid(sid) {
        if (sid != null && this.children[sid]) {
          if (this.children[sid]?.type === 'S') {
            this.safeSampleID = sid;
          } else if (this.children[sid]?.type === 'C') {
            this.safeCalibrationID = sid;
          }
        }
      },
      children() {
        if (this.sid && (!this.safeSampleID || !this.safeCalibrationID)) {
          const { sid } = this;
          if (this.children[sid]?.type === 'S') {
            this.safeSampleID = sid;

            if (!this.isShowArchived && this.children[sid].archived) {
              this.showArchived();
            }
          } else if (this.children[sid]?.type === 'C') {
            this.safeCalibrationID = sid;

            if (!this.isShowArchived && this.children[sid].archived) {
              this.showArchived();
            }
          }
          this.$nextTick(() => this.scrollToTab(this.sid));
        }

        this.updateRunningInjectionNotify();
      },
      sequence(value) {
        if (value) {
          this.updateRunningInjectionNotify();
        }
      },
      $route: {
        handler(newValue, oldValue) {
          // Close a sample if the secondary view is opened
          if (!oldValue?.query.pathSecondary && newValue.query.pathSecondary) {
            const hasSecondary = Boolean(RouterHelper.routeSecondary.name);
            const isSequenceWithDetails = Boolean(this.sid || this.rid);

            if (this.isPrimaryView && hasSecondary && isSequenceWithDetails) {
              this.$router.replace({
                name: 'sequence',
                params: {
                  id: this.id,
                },
                query: {
                  setAsPrimary: true,
                },
              });
            }
          }
        },
        immediate: true,
      },
    },

    beforeDestroy() {
      this.destroySocket();
    },

    methods: {
      sortBy(field) {
        this.sortedBy = {
          field,
          direction: this.sortedBy.direction === 'asc' ? 'desc' : 'asc',
        };
      },

      destroySocket() {
        if (this.sequenceSocket) {
          this.sequenceSocket.close(this.listenersGroupId);
        }
      },
      createSocket(id) {
        this.destroySocket();

        const onSequence = (s) => {
          this.sequence = s;
          this.pageTitle = `${s?.name}: Sequence HPLC.cloud`;
        };
        const onChildren = (c) => {
          this.children = c;
        };
        const onDevice = (d) => {
          this.device = d;
        };
        const onMethod = (m) => {
          this.method = m;
        };
        const onColumn = (c) => {
          this.column = c;
        };
        const onTable = (t) => {
          this.table = t; // Sequence table
        };

        const sequenceSocket = SequenceSocket.start(
          id,
          async (connection) => {
            const data = await connection.getFull();
            onSequence(data.sequence);

            if (_.has(data, 'device')) {
              onDevice(data.device);
            }
            if (_.has(data, 'method')) {
              onMethod(data.method);
            }
            if (_.has(data, 'column')) {
              onColumn(data.column);
            }
            if (_.has(data, 'children')) {
              onChildren(data.children);
            }
            if (_.has(data, 'table')) {
              onTable(data.table);
            }
          },
          (e) => {
            this.errorCode = e.code;
          },
        );

        const listenersGroup = sequenceSocket.createEventListenersGroup();
        this.listenersGroupId = listenersGroup.id;

        listenersGroup.addEventListener(SequenceSocketEvents.SEQUENCE, onSequence);
        listenersGroup.addEventListener(SequenceSocketEvents.CHILDREN, onChildren);
        listenersGroup.addEventListener(SequenceSocketEvents.DEVICE, onDevice);
        listenersGroup.addEventListener(SequenceSocketEvents.METHOD, onMethod);
        listenersGroup.addEventListener(SequenceSocketEvents.COLUMN, onColumn);
        listenersGroup.addEventListener(SequenceSocketEvents.SEQUENCE_RUN, (c, r) => {
          const children = _.clone(this.children);
          children[c.id] = c;
          this.children = children;
          this.selectSample(c.id, r.id, true);
        });
        listenersGroup.addEventListener(SequenceSocketEvents.SEQUENCE_CHILD, (c) =>
          this.setChild(c),
        );
        listenersGroup.addEventListener(SequenceSocketEvents.SEQUENCE_SELECT, (cID, rID) => {
          this.selectSample(cID, rID, true);
        });
        listenersGroup.addEventListener(SequenceSocketEvents.TABLE, onTable);
        listenersGroup.addEventListener(SequenceSocketEvents.SEQUENCE_DRAFT, (d) => {
          this.table = {
            ...this.table,
            [d.id]: d,
          };
        });
        listenersGroup.addEventListener(SequenceSocketEvents.SEQUENCE_PROGRESS, (p, ps, d) => {
          if (this.sequence) {
            this.sequence.progress = p;
            this.sequence.progress_str = ps;
            this.sequence.draft_info = d;
          }
        });
        listenersGroup.addEventListener(SequenceSocketEvents.SEQUENCE_ERROR, (msg) =>
          this.notifyError(msg),
        );

        return sequenceSocket;
      },

      async scrollToTab(sid) {
        if (RouterHelper.isSingleMode) {
          await this.$nextTick();

          // Scroll only inside samples container
          // TODO unstable
          const element = this.$refs[`tab${sid.toString()}`]?.[0];
          if (element?.parentNode?.parentNode) {
            element.parentNode.parentNode.scrollTop = element.offsetTop;
          }
        }
      },

      async scrollToInjection() {
        // Scroll whole page till the sample
        if (RouterHelper.isSingleMode) {
          await this.$nextTick();

          if (this.$refs['samples-block']) {
            this.$refs['samples-block'].scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'start',
            });
          }
        }
      },

      setChild(c) {
        const children = _.clone(this.children);
        children[c.id] = c;
        this.children = children;
      },

      selectSample(sid, rid, scroll = false) {
        // sid === sampleId
        // rid === injectionId ?

        let aRid = _.clone(rid);

        if (sid == null) {
          this.$router.push({
            name: 'sequence',
            params: { id: this.id },
            query: { notOpenSecondaryTab: true },
          });
        } else {
          if (!this.children[sid]) return;

          if (scroll) {
            this.scrollToTab(sid);
            this.scrollToInjection();
          }

          if (aRid == null) {
            aRid = this.children[sid].injection_last;
          }

          const { isDualMode } = RouterHelper;
          const isSample = this.children[sid].type === 'S';

          if (aRid == null) {
            const locationForSingle = {
              name: 'sequence child',
              params: {
                id: this.id,
                sid,
              },
            };
            const locationForDual = {
              name: isSample ? 'sample' : 'calibration',
              params: {
                id: sid,
              },
              query: { makeSecondaryAsPrimary: true, primaryMustBe: 'sequence' },
            };
            const locationForDualIfPrimaryIsDevice = {
              name: 'sequence child',
              params: {
                id: this.id,
                sid,
              },
            };

            consoleHelpers.warn('SHOW SEQUENCE CHILD');
            this.$router.push(
              isDualMode
                ? RouterHelper.routePrimary.name === 'dual.device'
                  ? locationForDualIfPrimaryIsDevice
                  : locationForDual
                : locationForSingle,
            );
          } else {
            const locationForSingle = {
              name: 'sequence child injection',
              params: {
                id: this.id,
                sid,
                iid: aRid,
              },
            };
            const locationForDual = {
              name: `${isSample ? 'sample' : 'calibration'} injection`,
              params: {
                id: sid,
                iid: aRid,
              },
              query: { makeSecondaryAsPrimary: true, primaryMustBe: 'sequence' },
            };
            const locationForDualIfPrimaryIsDevice = {
              name: 'sequence child injection',
              params: {
                id: this.id,
                sid,
                iid: aRid,
              },
            };

            consoleHelpers.warn('SHOW SEQUENCE CHILD');
            this.$router.push(
              isDualMode
                ? RouterHelper.routePrimary.name === 'dual.device'
                  ? locationForDualIfPrimaryIsDevice
                  : locationForDual
                : locationForSingle,
            );
          }
        }
      },

      newInjectionID(iid) {
        const { sid } = this;

        if (sid == null) {
          this.$router.push({
            name: 'sequence',
            params: { id: this.id },
          });
        } else if (iid == null) {
          this.$router.push({
            name: 'sequence child',
            params: {
              id: this.id,
              sid,
            },
          });
        } else if (iid === -1) {
          this.$router.push({
            name: 'sequence child injection new',
            params: {
              id: this.id,
              sid,
            },
          });
        } else {
          const { isDualMode } = RouterHelper;

          const locationForSingle = {
            name: 'sequence child injection',
            params: {
              id: this.id,
              sid,
              iid,
            },
          };

          const isSample = this.children[sid].type === 'S';
          const locationForDual = {
            name: `${isSample ? 'sample' : 'calibration'} injection`,
            params: {
              id: this.sid,
              iid,
            },
            query: { makeSecondaryAsPrimary: true, primaryMustBe: 'sequence' },
          };

          this.$router.push(isDualMode ? locationForDual : locationForSingle);
        }
      },

      saveName(name) {
        this.showNotificationIfRpcError(() => this.sequenceSocket.update({ name }));
      },
      saveDescription() {
        this.showNotificationIfRpcError(() =>
          this.sequenceSocket.update({ description: this.sequence.description }),
        );
      },
      saveCompounds(_compounds) {
        const compounds = _compounds.map((compound) => compound.name).join('; ');
        this.sequence.compounds = compounds;
        this.showNotificationIfRpcError(() => this.sequenceSocket.update({ compounds }));
      },

      updateDMC({ device, method, column }) {
        const isDeviceChanged = device.id !== this.device.id;
        const isMethodChanged = method.id !== this.method.id;
        const isColumnChanged = column.id !== this.column.id;

        if (isDeviceChanged || isMethodChanged || isColumnChanged) {
          this.showNotificationIfRpcError(() =>
            this.sequenceSocket.update({
              device_id: device.id,
              method_id: method.id,
              column_id: column.id,
            }),
          );
        }
      },

      finish() {
        this.showNotificationIfRpcError(() => this.sequenceSocket.finish());
      },
      reject() {
        this.showNotificationIfRpcError(() => this.sequenceSocket.reject());
      },
      reopen() {
        this.showNotificationIfRpcError(() => this.sequenceSocket.reopen());
      },

      archive() {
        this.showNotificationIfRpcError(() => this.sequenceSocket.archive());
      },
      restore() {
        this.showNotificationIfRpcError(() => this.sequenceSocket.restore());
      },

      clone() {
        return this.showNotificationIfRpcError(async () => {
          const data = await this.sequenceSocket.clone();
          this.$router.push({ name: 'sequence', params: { id: data.sequence_id } });
          this.notify('Sequence was cloned!');
        });
      },

      run() {
        this.showNotificationIfRpcError(() => this.sequenceSocket.run());
      },
      stop() {
        this.showNotificationIfRpcError(() => this.sequenceSocket.stop());
      },
      resume() {
        this.showNotificationIfRpcError(() => this.sequenceSocket.run());
      },

      load() {
        this.showNotificationIfRpcError(() => this.sequenceSocket.load());
      },

      openChild(child) {
        // TODO not used?
        this.$router.push({
          name: 'sample',
          params: { id: child.id },
        });
      },

      addSample() {
        if (this.inputs.sampleName) {
          this.showNotificationIfRpcError(() =>
            this.sequenceSocket.addSample(this.inputs.sampleName),
          );

          this.inputs.sampleName = '';
          this.hideCreationForm();
        }
      },
      addCalibration() {
        if (this.inputs.sampleName) {
          this.showNotificationIfRpcError(() =>
            this.sequenceSocket.addCalibration(this.inputs.sampleName),
          );

          this.inputs.sampleName = '';
          this.hideCreationForm();
        }
      },

      draftAdd() {
        if (this.table == null) {
          this.table = {};
        }

        if (Object.keys(this.table).length >= 1) {
          const table = Object.values(this.table).sort((a, b) => a.order - b.order);
          const lastNotArchivedDraft = _.findLast(
            table,
            (draft) => this.children[draft.sample] && !this.children[draft.sample].archived,
          );
          if (lastNotArchivedDraft) {
            const scheduledInjectionsNumber = this.getScheduledSampleInjectionsNumber(
              lastNotArchivedDraft.sample,
            );
            const allowedRepeats = SAMPLE_INJECTIONS_LIMIT - scheduledInjectionsNumber;
            const repeat =
              allowedRepeats > lastNotArchivedDraft.repeat
                ? lastNotArchivedDraft.repeat
                : allowedRepeats >= 0
                ? allowedRepeats
                : 0;

            table.push({
              name: lastNotArchivedDraft.name,
              type: lastNotArchivedDraft.type,
              sample: lastNotArchivedDraft.sample,
              vial: lastNotArchivedDraft.vial,
              amount: lastNotArchivedDraft.amount,
              unit: lastNotArchivedDraft.unit,
              method: this.device.method_id ?? null,
              repeat,
              enabled: true,
            });
            this.showNotificationIfRpcError(() => this.sequenceSocket.sequenceTableUpdate(table));

            return;
          }
        }

        const firstSampleOrCalibration = Object.values(this.children).find(
          (sample) => !sample.archived,
        );

        if (!firstSampleOrCalibration) {
          this.notifyError('You should create at least one sample or calibration');
          return;
        }

        const scheduledInjectionsNumber = firstSampleOrCalibration?.sample
          ? this.getScheduledSampleInjectionsNumber(firstSampleOrCalibration.sample)
          : 0;
        const allowedRepeats = SAMPLE_INJECTIONS_LIMIT - scheduledInjectionsNumber;
        const repeat = allowedRepeats > 0 ? 1 : 0;

        this.table.newDraft = {
          name: firstSampleOrCalibration?.name ?? null,
          type: firstSampleOrCalibration?.type ?? 'S',
          sample: firstSampleOrCalibration?.id ?? null,
          vial: this.device.configuration?.autosampler_fraction_collector ? 'W1' : 'A1',
          method: this.device.method_id ?? null,
          repeat,
          enabled: true,
        };
        const table = Object.values(this.table).sort((a, b) => a.order - b.order);
        this.showNotificationIfRpcError(() => this.sequenceSocket.sequenceTableUpdate(table));
      },

      hideCurrentChild() {
        if (!this.isShowArchived) {
          this.selectSample(null);
        }
      },

      showArchived() {
        this.isShowArchived = true;
      },

      tableClear() {
        this.table = {};
        this.showNotificationIfRpcError(() => this.sequenceSocket.sequenceTableUpdate([]));
      },
      tableStart() {
        this.showNotificationIfRpcError(() => this.sequenceSocket.sequenceTableStart());
      },
      tablePause() {
        this.showNotificationIfRpcError(() => this.sequenceSocket.sequenceTablePause());
      },
      tableResume() {
        this.showNotificationIfRpcError(() => this.sequenceSocket.sequenceTableResume());
      },
      tableStop() {
        this.showNotificationIfRpcError(() => this.sequenceSocket.sequenceTableStop());
      },

      prev() {
        this.selectSample(this.prevID);
      },
      next() {
        this.selectSample(this.nextID);
      },
      goToFirstSample() {
        this.selectSample(this.childrenFiltered[0].id);
      },
      goToLastSample() {
        this.selectSample(this.childrenFiltered[this.childrenFiltered.length - 1].id);
      },

      updateRunningInjectionNotify() {
        if (['EXE'].includes(this.sequence?.state)) {
          this.activeSample = null;
          return;
        }
        const runs = Object.values(this.children).filter((c) => ['RUN'].includes(c.state));
        if (runs.length > 0) {
          this.activeSample = runs[0];
        } else {
          this.activeSample = null;
        }
      },

      async showCreationForm() {
        this.isShowCreationForm = true;

        await this.$nextTick();
        this.$refs.inputNewSampleName?.focus();
      },
      hideCreationForm() {
        this.isShowCreationForm = false;
      },

      updateTable(drafts) {
        /**
         * We suppose that sometimes drafts from a previous run can be sent to the backend
         * (due to the vue rendering process)
         * In this case the backend deletes correct drafts. This check to avoid it
         */
        if (drafts.some((draft) => draft.sequence === this.sequence.id)) {
          this.showNotificationIfRpcError(() => this.sequenceSocket.sequenceTableUpdate(drafts));
        }
      },

      removeDraft(draft) {
        const drafts = Object.values(this.table).filter((_draft) => _draft.id !== draft.id);
        this.showNotificationIfRpcError(() => this.sequenceSocket.sequenceTableUpdate(drafts));
      },

      updateDraft(draft) {
        this.showNotificationIfRpcError(() => this.sequenceSocket.sequenceTableUpdateDraft(draft));
      },

      isSamplePaused(sampleId) {
        return this.currentDraft?.sampleId === sampleId ? this.currentDraft.state === 'PAU' : false;
      },

      startEditing() {
        if (this.sequence.can_edit) {
          this.isEditMode = true;
        }
      },
      finishEditing() {
        this.$refs.description.save();
        this.isEditMode = false;
      },

      showReportModal() {
        this.$modal.show('sequenceReport');
      },

      isValidDraft(draft) {
        return draft.method && draft.repeat && draft.vial;
      },

      getScheduledSampleInjectionsNumber(sampleId) {
        return Object.values(this.table).reduce((counter, row) => {
          return row.sample === sampleId ? counter + row.repeat : counter;
        }, 0);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sequence-page {
    &__cell {
      &--sortable {
        cursor: pointer;
        transition: color 0.3s;
        padding-right: 0;

        &:hover {
          color: $color-text-default--active;
        }
      }

      &--selected {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 2px;
          background-color: $color-bg-primary;
        }
      }
    }

    &__sort-direction {
      transform: translateY(2px);
      opacity: 0;

      &--visible {
        transition: opacity 0.3s;
        opacity: 1;
      }
    }

    &__icon-add-sample {
      margin-right: 12px;
    }

    tfoot {
      tr {
        color: $color-text-primary;
        font-weight: $weight-bold;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover,
        &:focus-within {
          background-color: $color-bg-third;

          .sequence-page__label-add-sample {
            text-decoration: underline;
          }
        }

        &:active {
          color: $color-text-primary--active;
        }
      }
    }

    &__form-create-sample {
      display: flex;
      align-items: center;
    }

    &__input-name {
      background-color: transparent;
      text-indent: 0;
    }

    &__btn-add-sample,
    &__btn-hide-form {
      flex: none;
      min-width: 32px;
      margin-left: 5px;
    }

    &__wrapper-btn {
      display: inline-block;
    }

    &__btn-new-method {
      margin-left: 10px;
    }

    &__wrapper-pager {
      position: relative;
      display: flex;

      @screen sm {
        display: none;
      }
    }

    &__btn-back-desktop {
      display: none;

      @screen sm {
        display: block;
      }
    }

    &__btn-back-adaptive {
      height: 80px;

      @screen sm {
        display: none;
      }
    }

    &__id {
      color: $color-text-third;
    }

    &__favorite {
      margin-left: 10px;
    }
  }
</style>

<style lang="scss">
  .sequence__samples-expanded {
    display: flex;
    min-height: 1024px;
    flex-direction: column;
    @screen sm {
      flex-direction: row;
    }
    > div > .samples__pager {
      height: 80px;

      @screen sm {
        display: none;
      }
    }
  }

  .samples__sample-tabs-container {
    display: none;
    @screen sm {
      display: block;
    }
    position: relative;
    width: 64px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .samples__sample-expanded-container {
    width: 100%;
    @screen sm {
      width: calc(100% - 64px);
    }
    z-index: 1;
  }

  .samples__sample-tabs-inner-container {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    padding-bottom: 512px;
    width: 100%;
  }

  .samples__sample-tab-button {
    padding-left: 16px;
    font-size: 13px;
    font-weight: bold;
    height: 48px;
    text-align: left;
    line-height: 48px;
    color: #00000066;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      color: #000000;
      background-color: #00000011;
    }

    &--gray {
      color: #989898;
      text-decoration: line-through;
      background-color: #00000011;
    }

    &--selected {
      color: $color-text-primary--hover;
      background-color: #ffffff;
    }

    &--selected:hover {
      color: $color-text-primary--hover;
      background-color: #ffffff;
    }
  }
</style>
