<template>
  <div
    v-test="{ id: 'trayVials' }"
    class="tray-vials"
    :style="styleGrid"
    :class="{ 'tray-vials--in-popup': this.isInPopup }"
  >
    <div v-if="!isSingleRowTray" class="tray-vials__stub" />
    <div
      v-for="(_, indexColumn) of columns"
      :key="`label-x-${indexColumn}`"
      class="tray-vials__label"
    >
      {{ LABELS_X[indexColumn] }}
    </div>
    <template v-for="(_, indexRow) of rows">
      <div
        v-if="!isSingleRowTray"
        :key="`label-y-${indexRow}`"
        class="tray-vials__label tray-vials__label--letter"
      >
        {{ isSingleRowTray ? '' : LABELS_Y[indexRow] }}
      </div>
      <Vial
        v-for="column of columns"
        :key="`${LABELS_Y[indexRow]}${column}`"
        v-model="_checkedVial"
        v-test="{ id: 'vial' }"
        :isDisabled="isDisabled"
        :isProcessing="isProcessing"
        :value="isSingleRowTray ? 'W' + column : `${LABELS_Y[indexRow]}${column}`"
        class="tray-vials__vial"
        data-test-id="vial"
      />
    </template>
  </div>
</template>

<script>
  import Vial from './private/Vial';

  import testDirective from '@/directives/test';

  const EVENT_MODEL = 'model';

  const DEFAULT_CONFIG = {
    rows: 6,
    columns: 8,
  };

  const LABELS_X = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const LABELS_Y = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

  export default {
    name: 'TrayVials',

    directives: { test: testDirective },

    components: {
      Vial,
    },

    model: {
      prop: 'checkedVial',
      event: EVENT_MODEL,
    },

    props: {
      checkedVial: {
        type: [String, Number],
      },
      // Config with number of rows and columns.
      // Format: 96_8x12
      config: {
        required: true,
        validator: (value) => typeof value === 'string' || value === null,
      },
      isDisabled: {
        type: Boolean,
      },
      isProcessing: {
        type: Boolean,
      },
      isInPopup: {
        type: Boolean,
      },
    },

    data: () => ({
      LABELS_X,
      LABELS_Y,
    }),

    computed: {
      _checkedVial: {
        get() {
          return this.checkedVial;
        },
        set(value) {
          this.$emit(EVENT_MODEL, value);
        },
      },

      styleGrid() {
        return {
          gridTemplateColumns: `repeat(${this.columns + (this.isSingleRowTray ? 0 : 1)}, 1fr)`,
          gridTemplateRows: `repeat(${this.rows + (this.isSingleRowTray ? 0 : 1)}, 1fr)`,
        };
      },

      configParsed() {
        return this.config?.match(/\d+_(?<rows>\d+)x(?<columns>\d+)/)?.groups ?? DEFAULT_CONFIG;
      },

      columns() {
        return Number(this.configParsed.columns);
      },
      rows() {
        return Number(this.configParsed.rows);
      },
      isSingleRowTray() {
        return this.configParsed.rows < 2;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .tray-vials {
    display: grid;

    &--in-popup {
      @media (max-width: $screen-sm-max) {
        font-size: $size-sm;
      }
    }

    &__label {
      align-self: center;
      justify-self: center;

      &--letter {
        @media (max-width: $screen-xxs-max) {
          margin-right: 3px;
        }
      }
    }

    &__row {
      display: flex;
      flex: 1;
    }

    &__vial {
      flex: 1;
      margin: 0;

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }
    }
  }
</style>
