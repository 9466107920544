<template>
  <modal
    :name="name"
    height="auto"
    doSubmitOnEnter
    class="modal-postprocessing-template-edit"
    :hasCloseConfirmationPopup="dataChanged"
    closeConfirmationPopupMessage="Postprocessing Template wasn't saved! Do you want to close the window?"
    @submit="isApplyMode ? applyTemplate : isNewPostprocessingTemplate ? saveAsNew() : save()"
    @opened="opened"
    @before-close="deactivatePostprocessingTemplatePresetSearch"
    @closed="closed"
  >
    <div class="modal-content modal-content--no-padding">
      <div class="modal-header">
        <div class="header-container">
          <h4 class="postprocessing-template-title">
            {{
              isApplyMode
                ? 'Apply postprocessing template'
                : postprocessingTemplate.id
                ? 'Postprocessing template'
                : 'Create postprocessing template'
            }}
            <span
              v-if="postprocessingTemplate && postprocessingTemplate.id"
              class="modal-postprocessing-template-edit__id"
              >#{{ postprocessingTemplate.id }}</span
            >
          </h4>
          <SearchFakedInput
            v-show="!postprocessingTemplatePresetSearchVisible"
            style="max-width: 280px"
            class="postprocessing-template-search"
            text="Select postprocessing template as preset"
            data-test-id="btn-search-preset"
            @click="openPostprocessingTemplatePresetSearch"
          />
          <div
            v-show="postprocessingTemplatePresetSearchVisible"
            class="postprocessing-template-search"
          >
            <SelectPostprocessingTemplateAsPreset
              ref="postprocessingTemplatePresetSearch"
              @change="updatePostprocessingTemplateDataFromPreset"
              @hidePopup="closePostprocessingTemplatePresetSearch"
            />
          </div>
        </div>
      </div>

      <div class="modal-body">
        <div
          v-if="isApplyMode && selectedMeasurement"
          class="modal-postprocessing-template-edit__warning"
        >
          Postprocessing template will be applied to the selected channel:
          <span class="font-bold px-1">{{ selectedMeasurement.name }}</span>
        </div>

        <div ref="name" class="block-name">
          <StandardModelFieldComponent
            v-model="postprocessingTemplateData.name"
            style="flex: 1 1"
            :type="'str'"
            :is-title="true"
            :label="'Postprocessing template name'"
            :error="errors.name"
            :isMultiline="true"
            @update:value="clearError('name')"
          />

          <div class="modal-postprocessing-template-edit__actions">
            <template v-if="!isNewPostprocessingTemplate">
              <button
                v-if="isArchived"
                class="button--square-image archive-button"
                @click="restore"
              >
                <i class="material-icons material-icon--18">restore</i>Restore
              </button>
              <button v-else class="button--square-image archive-button" @click="archive">
                <i class="material-icons material-icon--18">delete</i>Archive
              </button>

              <button class="button--square-image favorites-button" @click="toggleFavorite">
                <i class="material-icons material-icon--18">{{
                  postprocessingTemplateData.is_favorite ? 'star' : 'star_border'
                }}</i>
              </button>
            </template>
          </div>
        </div>

        <StateArchived v-if="isArchived" />

        <PostprocessingTemplateEditComponent
          ref="editForm"
          v-model="postprocessingTemplateData"
          :errors="errors"
          :measurement="selectedMeasurement"
          :method="method"
          class="mt-3 modal-postprocessing-template-edit__form"
          @validation="setValidation"
          @updateField="clearError"
        />
      </div>

      <div v-if="!isArchived" class="modal-footer">
        <div class="modal-content__actions-panel">
          <div class="actions">
            <div class="modal-content__actions">
              <Btn :disabled="!dataChanged" @click="reset">Revert</Btn>

              <Btn :disabled="!isValid" type="accent" @click="saveAsNew">
                {{
                  isNewPostprocessingTemplate && !isApplyMode
                    ? 'Create'
                    : isApplyMode
                    ? 'Save as new template'
                    : 'Save as new'
                }}
              </Btn>
              <Btn
                v-if="isApplyMode"
                :isLoading="isApplying"
                :disabled="!isValid"
                type="primary"
                @click="applyTemplate"
              >
                Apply
              </Btn>
              <Btn
                v-else-if="!isNewPostprocessingTemplate"
                :disabled="!canSave || !isValid"
                type="primary"
                @click="save"
              >
                Save
              </Btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import _ from 'lodash';
  import ModalComponent from 'components/element/ModalComponent.vue';
  import PostprocessingTemplateEditComponent from 'components/block/PostprocessingTemplateEditComponent.vue';
  import SelectPostprocessingTemplateAsPreset from 'components/element/SelectPostprocessingTemplateAsPreset.vue';
  import SearchFakedInput from 'components/element/SearchFakedInput.vue';

  import {
    POSTPROCESSING_TEMPLATE_EMPTY,
    POSTPROCESSING_TEMPLATE_ERRORS_DEFAULT,
  } from '@/constants/postprocessingTemplates/presets';
  import Btn from '@/uikitBase/btns/Btn';
  import StandardModelFieldComponent from 'components/element/StandardModelFieldComponent';
  import {
    getPostprocessingTemplateWithDefaultValues,
    POSTPROCESSING_TEMPLATE_MAP,
    preparePostprocessingTemplateForRequest,
  } from 'utils/postprocessingTemplateHelpers.ts';
  import { apiPostprocessingTemplates } from '@/api/graphql/cloud/postprocessingTemplates';
  import StateArchived from '@/uikitProject/states/StateArchived.vue';
  import { generateUuidv4 } from '@/utils/uuidHelpers';
  import GraphqlResponseError from '@/errors/GraphqlResponseError';
  import { apiMeasurements } from '@/api/graphql/cloud/measurements';

  const EVENT_UPDATE_POSTPROCESSING_TEMPLATE = 'update:postprocessingTemplate';
  const EVENT_UPDATE_POSTPROCESSING_TEMPLATE_FORM = 'update:postprocessingTemplateForm';
  const EVENT_UPDATE_ARCHIVED = 'update:archived';
  const EVENT_CREATED = 'created';
  const EVENT_APPLY = 'apply';

  export default {
    name: 'PostprocessingTemplateEditModal',

    components: {
      StateArchived,
      StandardModelFieldComponent,
      Btn,
      SearchFakedInput,
      PostprocessingTemplateEditComponent,
      SelectPostprocessingTemplateAsPreset,
      modal: ModalComponent,
    },

    props: {
      postprocessingTemplate: {
        type: Object,
        default: () => ({}),
      },
      name: {
        type: String,
        default: 'postprocessing-template-edit',
      },
      isCloseOnArchivingStatus: {
        type: Boolean,
      },
      deviceId: {
        type: String,
      },
      isApplyMode: {
        type: Boolean,
      },
      selectedMeasurement: {
        type: Object,
      },
      method: {
        type: Object,
      },
    },

    data: () => ({
      postprocessingTemplateData: POSTPROCESSING_TEMPLATE_EMPTY,
      errors: { ...POSTPROCESSING_TEMPLATE_ERRORS_DEFAULT },
      modalVisible: false,
      postprocessingTemplatePresetSearchVisible: false,
      isValid: true,

      isApplying: false,

      defaultPostprocessingTemplateData: {},
    }),

    computed: {
      dataChanged() {
        const initialData =
          !this.postprocessingTemplate ||
          typeof this.postprocessingTemplate !== 'object' ||
          Object.keys(this.postprocessingTemplate).length === 0
            ? this.defaultPostprocessingTemplateData
            : this.postprocessingTemplate;

        return Object.keys(this.postprocessingTemplateData).some((key) => {
          if (['defaultValues', 'id', 'uuid', 'is_favorite', 'is_archived'].includes(key)) {
            return false;
          }

          if (initialData[key] && typeof initialData[key] === 'object') {
            return !_.isEqual(initialData[key], this.postprocessingTemplateData[key]);
          }

          return (
            // eslint-disable-next-line eqeqeq
            initialData[key] != this.postprocessingTemplateData[key]
          );
        });
      },

      canSave() {
        return this.dataChanged;
      },

      postprocessingTemplateForRequest() {
        return preparePostprocessingTemplateForRequest(this.postprocessingTemplateData);
      },
      isNewPostprocessingTemplate() {
        return this.postprocessingTemplate?.uuid == null;
      },
      isArchived() {
        return this.postprocessingTemplateData?.is_archived;
      },
      isEditable() {
        return this.postprocessingTemplate?.editable;
      },
    },

    watch: {
      postprocessingTemplate(newPostprocessingTemplate) {
        if (!this.modalVisible) {
          this.updatePostprocessingTemplateDataFromObject(newPostprocessingTemplate);
        }
      },
    },

    created() {
      this.initDefaultValues();
    },

    mounted() {
      this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
      this.onSubmitError = this.onSubmitError.bind(this);
    },

    methods: {
      opened() {
        this.reset();

        this.$emit('opened');
        this.$nextTick(() => {
          this.modalVisible = true;
        });
      },

      closed() {
        this.modalVisible = false;
        this.$emit('closed');
      },

      scrollToField(fieldName) {
        if (fieldName === 'name') {
          this.$refs.name.scrollIntoView({
            behavior: 'smooth',
          });
        } else if (fieldName) {
          this.$refs.editForm.scrollToField(fieldName);
        }
      },

      async openPostprocessingTemplatePresetSearch() {
        this.postprocessingTemplatePresetSearchVisible = true;
        await this.$nextTick();
        this.$refs.postprocessingTemplatePresetSearch.activate();
      },

      closePostprocessingTemplatePresetSearch() {
        this.postprocessingTemplatePresetSearchVisible = false;
      },

      deactivatePostprocessingTemplatePresetSearch() {
        if (this.postprocessingTemplatePresetSearchVisible) {
          this.postprocessingTemplatePresetSearchVisible = false;

          // NOTE: manual deactivation. Automatic deactivation is called after component is destroyed => errors in console
          this.$refs?.postprocessingTemplatePresetSearch?.deactivate();
        }
      },

      updatePostprocessingTemplateDataFromObject(newPostprocessingTemplate) {
        this.initDefaultValues(newPostprocessingTemplate);
      },

      updatePostprocessingTemplateDataFromPreset(postprocessingTemplate) {
        if (postprocessingTemplate) {
          this.clearErrors();
          // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
          const { id, is_favorite, ..._postprocessingTemplate } = postprocessingTemplate;
          this.postprocessingTemplateData = {
            ...this.postprocessingTemplateData,
            ..._.pick(_postprocessingTemplate, _.keys(this.postprocessingTemplateData)),
          };
        }
      },

      onSubmitSuccess(postprocessingTemplate) {
        this.updatePostprocessingTemplateDataFromObject(postprocessingTemplate);
        this.close();
      },

      onSubmitError(error) {
        if (error instanceof GraphqlResponseError) {
          const { message, fields } = error.params;

          if (fields != null) {
            const fieldsWithError = Object.entries(fields);
            fieldsWithError.forEach(([fieldName, error]) => {
              this.errors[fieldName] = error;
            });

            if (fieldsWithError.length) {
              this.scrollToField(fieldsWithError[0][0]);
            }
          }

          if (message) {
            this.notifyResponseError({ message });
          }
        } else {
          this.notifyResponseError({ message: 'Unknown error' });

          throw error;
        }
      },

      async save() {
        if (!this.isValid) {
          return;
        }

        this.clearErrors();

        try {
          const postprocessingTemplate = await apiPostprocessingTemplates.createOrUpdatePostprocessingTemplate(
            this.postprocessingTemplateForRequest,
          );

          this.onSubmitSuccess(postprocessingTemplate);
          const _postprocessingTemplate = {
            ...postprocessingTemplate,
            is_favorite: this.postprocessingTemplateData.is_favorite,
          };

          // We need the event to distinguish between updating a form and editing favorite or archive statuses
          this.$emit(EVENT_UPDATE_POSTPROCESSING_TEMPLATE_FORM, _postprocessingTemplate);

          this.$emit(EVENT_UPDATE_POSTPROCESSING_TEMPLATE, _postprocessingTemplate);

          this.$store.commit('resetPostprocessingTemplates');
        } catch (e) {
          this.onSubmitError(e);
        }
      },
      saveAsNew() {
        if (!this.isValid) {
          return;
        }

        const sendRequest = async () => {
          this.clearErrors();

          try {
            const postprocessingTemplate = await apiPostprocessingTemplates.createOrUpdatePostprocessingTemplate(
              {
                ...this.postprocessingTemplateForRequest,
                uuid: generateUuidv4(),
              },
            );

            this.onSubmitSuccess(postprocessingTemplate);
            this.notify(
              `New postprocessing template was created: #${postprocessingTemplate.id} ${postprocessingTemplate.name}`,
              15,
            );
            this.$emit(EVENT_CREATED, postprocessingTemplate);
            this.$store.commit('resetPostprocessingTemplates');
          } catch (e) {
            this.onSubmitError(e);
          }
        };

        if (this.postprocessingTemplateForRequest.algorithm_base === 'manual') {
          this.$modal.show('dialog', {
            title: 'Save anyway?',
            text: `You cannot save the postprocessing template as a new one if you have selected the manual baseline correction algorithm. It will be saved with the default algorithm "Horizontal Correction".`,
            buttons: [
              {
                title: 'Confirm',
                default: true,
                handler: () => {
                  sendRequest();
                  this.$modal.hide('dialog');
                },
                class: 'vue-dialog-button blue-text',
              },
              {
                title: 'Cancel',
                handler: () => {
                  this.$modal.hide('dialog');
                },
                class: 'vue-dialog-button red-text',
              },
            ],
          });
        } else {
          sendRequest();
        }
      },
      async applyTemplate() {
        if (!this.isValid) {
          return;
        }

        this.clearErrors();

        try {
          this.isApplying = true;

          const {
            baseline_correction_anchor_point,
            algorithm_binj,
            blank_injection_measurement_ulid,
            detection_time_end_sec,
            detection_time_start_sec,
            algorithm_pdet,
            peak_detection_min_distance,
            peak_detection_min_height,
            peak_detection_min_prominence,
            peak_detection_min_width,
            algorithm_peak,
            peak_integration_quality_threshold,
            algorithm_nois,
          } = this.postprocessingTemplateForRequest;

          const algorithm_base =
            POSTPROCESSING_TEMPLATE_MAP[this.postprocessingTemplateData.algorithm_base];

          const postprocessings = {
            measurementId: this.selectedMeasurement.id,
            measurementUlid: this.selectedMeasurement.ulid,
            algorithm_pdet,
            algorithm_peak,
            algorithm_base,
            algorithm_binj,
            algorithm_nois,
            params_pdet: {
              peak_detection_min_distance,
              peak_detection_min_height,
              peak_detection_min_prominence,
              peak_detection_min_width,
            },
            params_peak: {
              peak_integration_quality_threshold,
            },
            params_base:
              algorithm_base === 'manual'
                ? {
                    baseline_correction_points: this.postprocessingTemplateData
                      .baseline_correction_points,
                  }
                : algorithm_base === 'horizontal_correction'
                ? {
                    baseline_correction_anchor_point,
                  }
                : {},
            params_binj: {
              blank_injection_measurement_ulid,
            },
            params_nois: {},
            params_dtim: {
              detection_time_start_sec,
              detection_time_end_sec,
            },
          };

          await apiMeasurements.applyPostprocessing(postprocessings);

          this.$emit(EVENT_APPLY);
          this.notify('Postprocessing template was applied!', 15);
          this.close();
        } catch (e) {
          this.onSubmitError(e);
        } finally {
          this.isApplying = false;
        }
      },

      reset() {
        this.postprocessingTemplatePresetSearchVisible = false;
        this.clearErrors();
        this.updatePostprocessingTemplateDataFromObject(this.postprocessingTemplate);
      },

      clearErrors() {
        this.errors = { ...POSTPROCESSING_TEMPLATE_ERRORS_DEFAULT };
      },
      clearError(fieldName) {
        this.errors = { ...this.errors, [fieldName]: null };
      },

      close() {
        console.warn('this.name', this.name);
        this.$modal.hide(this.name);
      },

      setValidation(value) {
        this.isValid = value;
      },

      async toggleFavorite() {
        this.postprocessingTemplateData.is_favorite
          ? await apiPostprocessingTemplates.removeFromFavorites(
              this.postprocessingTemplateData.uuid,
            )
          : await apiPostprocessingTemplates.addToFavorites(this.postprocessingTemplateData.uuid);
        this.postprocessingTemplateData.is_favorite = !this.postprocessingTemplateData.is_favorite;
        this.$emit(EVENT_UPDATE_POSTPROCESSING_TEMPLATE, {
          ...this.postprocessingTemplate,
          is_favorite: this.postprocessingTemplateData.is_favorite,
        });
        this.$store.commit('resetPostprocessingTemplates');
      },

      async archive() {
        await apiPostprocessingTemplates.archive(this.postprocessingTemplateData.uuid);
        this.postprocessingTemplateData.is_archived = true;

        this.$emit(EVENT_UPDATE_ARCHIVED, true);
        this.$emit(EVENT_UPDATE_POSTPROCESSING_TEMPLATE, {
          ...this.postprocessingTemplate,
          archived: this.postprocessingTemplateData.is_archived,
        });
        this.$store.commit('resetPostprocessingTemplates');

        if (this.isCloseOnArchivingStatus) {
          this.close();
        }
      },
      async restore() {
        await apiPostprocessingTemplates.restore(this.postprocessingTemplateData.uuid);
        this.postprocessingTemplateData.is_archived = false;

        this.$emit(EVENT_UPDATE_ARCHIVED, false);
        this.$emit(EVENT_UPDATE_POSTPROCESSING_TEMPLATE, {
          ...this.postprocessingTemplate,
          archived: this.postprocessingTemplateData.is_archived,
        });
        this.$store.commit('resetPostprocessingTemplates');

        if (this.isCloseOnArchivingStatus) {
          this.close();
        }
      },

      async initDefaultValues(postprocessingTemplate) {
        this.postprocessingTemplateData = await getPostprocessingTemplateWithDefaultValues(
          postprocessingTemplate ?? this.postprocessingTemplate,
        );
        this.defaultPostprocessingTemplateData = { ...this.postprocessingTemplateData };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-postprocessing-template-edit {
    &__id {
      color: $color-text-third;
      font-weight: $weight-normal;
      font-size: $size-xs;
      margin-left: 5px;
    }

    &__actions {
      display: flex;
    }

    &__form {
      margin-top: 20px;
    }

    &__warning {
      margin-bottom: 20px;
      padding: 10px;
      border-radius: $border-radius__md;
      background-color: $pure-color__lightgray-09;
      border: 1px solid $pure-color__lightgray-12;
      font-size: $size-sm;
    }
  }

  .header-container {
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    & .postprocessing-template-title {
      align-self: self-start;
      flex: 0 0;
      white-space: nowrap;
    }

    & .postprocessing-template-search {
      flex: 1 1;
      display: inline-block;
      margin-left: 16px;
      align-items: flex-end;
    }
  }

  .favorites-button,
  .archive-button {
    margin-top: 10px;
  }

  .block-name {
    display: flex;
    flex-direction: row;
  }
</style>
