<template>
  <Tippy
    ref="tippy"
    :isDisabled="isDisabled"
    trigger="click"
    :isInteractive="true"
    placement="bottom-start"
    :duration="[67, 0]"
    distance="0"
    :arrow="false"
    :popperOptions="{
      modifiers: { preventOverflow: { boundariesElement: 'window' } },
    }"
    @show="handleShowPopup"
    @hide="handleHidePopup"
  >
    <template #trigger="{ isShow }">
      <slot name="select" :itemSelected="itemSelected" :isShow="isShow">
        <div v-if="title" class="select-desktop__title">{{ title }}</div>

        <SelectTrigger
          ref="selectTrigger"
          v-model="searchValue"
          :itemSelected="itemSelected"
          :labelProp="labelProp"
          :stylesInputSearch="stylesInputSearch"
          :isShow="isShow"
          :isSearchActive="isSearchActive"
          :isTransparent="isTransparent"
          :isDisabled="isDisabled"
          :hasSearch="hasSearch"
          :padding="padding"
          :messageNoItemSelected="messageNoItemSelected"
          :messageNoItems="messageNoItems"
          :placeholderSearch="placeholderSearch"
          :isShowSelectedItem="isShowSelectedItem"
          :title="title"
          @click="activate"
        >
          <template #info>
            <slot name="rightSideInsideItem" :item="itemSelected" :hide="hidePopup" />
          </template>
        </SelectTrigger>
      </slot>
    </template>

    <!--For custom dropdowns-->
    <template #default="{ isShow, triggerWidth, triggerOffsetLeft, hide }">
      <GlobalEvents v-if="isShow" @keydown.esc="hide" />

      <div
        ref="popup"
        :style="getPopupStyles(triggerWidth, triggerOffsetLeft)"
        class="select__dropdown"
      >
        <slot
          :selectItem="selectItem"
          :itemsFiltered="itemsFiltered"
          :messageNoItems="messageNoItems"
          :labelProp="labelProp"
          :isActive="isShow"
        >
          <component
            :is="dropdownComponent"
            :items="itemsFiltered"
            :itemSelected="itemSelected"
            :labelProp="labelProp"
            :messageNoItems="messageNoItems"
            :isActive="isShow"
            :canCreate="canCreate"
            :messageCreate="messageCreate"
            @select="selectItem"
            @create="$emit('create')"
            @hide="hidePopup"
          >
            <template #leftSideInsideItem="{ item }">
              <slot name="leftSideInsideItem" :item="item" :isInDropdown="true" />
            </template>
            <template #rightSideInsideItem="{ item }">
              <slot
                name="rightSideInsideItem"
                :item="item"
                :hide="hidePopup"
                :isInDropdown="true"
              />
            </template>
          </component>
        </slot>
      </div>
    </template>
  </Tippy>
</template>

<script>
  import Tippy from '@/uikitBase/popups/vueTippy/Tippy';
  import PopupBase from '@/uikitBase/selects/vueSelect/desktop/private/PopupBase.vue';
  import {
    model,
    props,
    EVENT_MODEL,
    EVENT_SEARCH,
    EVENT_SHOW_POPUP,
    EVENT_HIDE_POPUP,
  } from '../shared/config';
  import SelectTrigger from '@/uikitBase/selects/vueSelect/shared/SelectTrigger';

  const EVENT_INIT = 'init';

  const INPUT_SEARCH_MIN_WIDTH = 100;

  export default {
    name: 'DesktopSelect',

    components: {
      SelectTrigger,
      Tippy,
      PopupBase,
    },

    model,

    props,

    data: () => ({
      isActivated: false,

      isSearchActive: false,

      searchValue: '',
      searchWidth: null,
    }),

    computed: {
      stylesInputSearch() {
        return {
          width: `${this.searchWidth}px`,
        };
      },

      dropdownComponent() {
        switch (this.type) {
          default:
            return PopupBase;
        }
      },

      itemsFiltered() {
        if (this.isExcludeSelectedItem) {
          return this.items.filter((item) => {
            return (
              item !== this.itemSelected ||
              (item[this.labelProp] ?? '').toLowerCase().includes(this.searchValue.toLowerCase()) ||
              (this.canSearchById && item.id === Number(this.searchValue))
            );
          });
        }
        return this.items.filter(
          (item) =>
            (item[this.labelProp] ?? '').toLowerCase().includes(this.searchValue.toLowerCase()) ||
            (this.canSearchById && item.id === Number(this.searchValue)),
        );
      },
    },

    watch: {
      searchValue(value) {
        this.$emit(EVENT_SEARCH, value);
      },
    },

    methods: {
      activate() {
        if (!this.isActivated) {
          this.isActivated = true;

          this.$emit(EVENT_INIT);
        }
      },

      async focus() {
        await this.$nextTick();

        this.$refs.selectTrigger?.click();
      },

      getPopupStyles(triggerWidth) {
        return {
          minWidth: `${window.innerWidth < triggerWidth ? window.outerWidth - 30 : triggerWidth}px`,
          ...this.dropdownStyles,
        };
      },
      selectItem(item) {
        this.hidePopup();
        this.$emit(EVENT_MODEL, item);
      },

      async showSearch() {
        // Old version. Maybe a label check is important
        // if (this.hasSearch && this.$refs.selectTrigger.labelWidth) {
        if (this.hasSearch) {
          const width = this.$refs.selectTrigger.labelWidth;
          this.searchWidth = width > INPUT_SEARCH_MIN_WIDTH ? width : null;
          this.isSearchActive = true;
          await this.$nextTick();
          this.$refs.selectTrigger.focus();
        }
      },
      hideSearch() {
        if (this.hasSearch) {
          this.isSearchActive = false;
          this.searchValue = '';
        }
      },

      handleShowPopup() {
        this.$emit(EVENT_SHOW_POPUP);
        this.showSearch();
      },
      handleHidePopup() {
        this.$emit(EVENT_HIDE_POPUP);
        this.hideSearch();
      },

      hidePopup() {
        this.$refs.tippy.hide();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .select-desktop {
    &__title {
      font-size: 13px;
      color: $color-text-third;
      margin-bottom: 3px;
    }

    &__dropdown {
      max-height: 180px;
      overflow-y: auto;
    }
  }
</style>
