<template>
  <div>
    <table v-if="!empty" class="table-default">
      <thead style="background-color: #0000000a">
        <tr>
          <th />
          <th>Name</th>
          <th />
          <th>Date</th>
          <th />
        </tr>
      </thead>
      <tbody class="tbody__td--nowrap">
        <tr v-for="i in runs" :key="i.id" class="row--clickable" @click="open(i)">
          <td class="td--shrink pr-4">
            <IconSequence v-if="i.sample.sequence" style="margin-left: 3px" />
            <IconCalibration v-else-if="i.type === 'calibration'" style="margin-left: 3px" />
            <IconSample v-else style="margin-left: 3px" />
          </td>
          <td class="td--overflow-hidden">
            <injection-link-component :injection="i" :first-icon="false" class="text-bold-blue" />
          </td>
          <td>
            <StatesForEntity
              :hasTitleOnAdaptive="false"
              :state="i.state"
              data-test-id="state-run"
            />
          </td>
          <td class="td--shrink text-gray" v-html="formatDate(new Date(i.created))" />
          <td class="td--shrink text-gray" v-html="formatTime(i.created)" />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import _ from 'lodash';

  import StatesForEntity from '@/uikitProject/states/packs/StatesForEntity.vue';
  import InjectionLinkComponent from 'components/element/InjectionLinkComponent.vue';
  import IconSequence from '@/uikitProject/icons/IconSequence.vue';
  import IconSample from '@/uikitProject/icons/IconSample.vue';
  import IconCalibration from '@/uikitProject/icons/IconCalibration';

  export default {
    name: 'RunsListComponent',
    components: {
      IconSample,
      IconSequence,
      IconCalibration,
      InjectionLinkComponent,
      StatesForEntity,
    },
    props: {
      runs: Array,
    },
    computed: {
      empty() {
        return _.isEmpty(this.runs);
      },
    },
    methods: {
      open(r) {
        if (r.sample.sequence) {
          this.$router.push({
            name: 'sequence child injection',
            params: { id: r.sample.sequence.id, sid: r.sample.id, iid: r.id },
            query: { makeSecondaryAsPrimary: true, primaryMustBe: 'device' },
          });
        } else if (r.type === 'sample') {
          this.$router.push({
            name: 'sample injection',
            params: { id: r.sample.id, iid: r.id },
            query: { makeSecondaryAsPrimary: true, primaryMustBe: 'device' },
          });
        } else if (r.type === 'calibration') {
          this.$router.push({
            name: 'calibration injection',
            params: { id: r.sample.id, iid: r.id },
            query: { makeSecondaryAsPrimary: true, primaryMustBe: 'device' },
          });
        }
      },
    },
  };
</script>
