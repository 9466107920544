import { isThisYear } from 'date-fns';
import DateHelper from '@/utils/DateHelper';

const locale = window.navigator.language;

const OPTIONS_TIME = { hour: 'numeric', minute: 'numeric' } as const;

type Device = {
  name: string;
  id: string;
};

type RouterLink = {
  name: string;
  params: {
    id: string;
    sid?: string;
  };
};

export enum Entities {
  SAMPLE = 'sample',
  CALIBRATION = 'calibration',
  SEQUENCE = 'sequence',
}

type TableFormat = {
  id: number;
  entity: Entities;
  title: string;
  state: string;
  device: Device;
  date: string;
  time: string;
  link: RouterLink;
  sequenceId?: string;
  sequenceName?: string;
};

type GroupByDate = {
  date: string;
  entities: TableFormat[];
};

type GroupRanked = {
  entities: TableFormat[];
};

export const formatItem = (item: any, _entity?: Entities): TableFormat => {
  const entity: Entities = _entity ?? item.type;
  const date = new Date(item.created);
  return {
    entity,
    id: item.id,
    title: item.name,
    state: item.state,
    device: {
      id: item.device_id,
      name: item.device_name,
    },
    time: date.toLocaleTimeString(locale, OPTIONS_TIME),
    date: date.toLocaleDateString(locale),
    link: {
      // Now it's correct, but depends on route name.
      // TODO Way to fix it is to use constants for router names and use another helper function for matching
      name: item.sequence_id ? 'sequence child' : entity,
      params: item.sequence_id
        ? {
            id: item.sequence_id,
            sid: item.id,
          }
        : {
            id: item.id,
          },
    },
    sequenceId: item.sequence_id,
    sequenceName: item.sequence_name,
  };
};

export default class TableEntityHelper {
  public static convertToTableFormat(data: any[], _entity?: Entities): GroupByDate[] {
    if (!data) return [];

    const dataFormatted: { [key: string]: TableFormat[] } = data.reduce((acc, _item) => {
      const date = new Date(_item.created);
      const localeDate = DateHelper.formatDate(
        date,
        isThisYear(date) ? 'EEEE, LLL d' : 'EEEE, LLL d, yyyy',
      );

      const item = formatItem(_item, _entity);

      if (!acc[localeDate]) {
        acc[localeDate] = [];
      }
      acc[localeDate].push(item);

      return acc;
    }, {});

    return Object.entries(dataFormatted).map(([date, entities]) => ({ date, entities }));
  }

  public static convertToTableFormatRanked(data: any[], _entity?: Entities): GroupRanked[] {
    if (!data) return [];

    const entities: TableFormat[] = data.map((item) => formatItem(item, _entity));

    return [{ entities }];
  }
}
