<template>
  <modal
    :name="name"
    height="auto"
    doSubmitOnEnter
    class="modal-method-edit"
    :hasCloseConfirmationPopup="dataChanged"
    closeConfirmationPopupMessage="Method wasn't saved! Do you want to close the window?"
    @submit="isNewMethod ? saveAsNew() : save()"
    @opened="opened"
    @before-close="deactivateMethodPresetSearch"
    @closed="closed"
  >
    <div class="modal-content modal-content--no-padding">
      <div class="modal-header">
        <div class="header-container">
          <h4 class="method-title">
            {{ method.id ? 'Method' : 'Create method' }}
            <span v-if="method && method.id" class="modal-method-edit__id">#{{ method.id }}</span>
          </h4>
          <template v-if="hasPermissionToEdit">
            <SearchFakedInput
              v-show="!methodPresetSearchVisible"
              style="max-width: 200px"
              class="method-search"
              text="Select method as preset"
              data-test-id="btn-search-preset"
              @click="openMethodPresetSearch"
            />
            <div v-show="methodPresetSearchVisible" class="method-search">
              <SelectMethodAsPreset
                ref="methodPresetSearch"
                @change="updateMethodDataFromPreset"
                @hidePopup="closeMethodPresetSearch"
              />
            </div>
          </template>
        </div>
      </div>

      <div class="modal-body">
        <div v-if="isDeprecated" class="modal-method-edit__outdated">
          This method is deprecated. It does not match the current device configuration and may not
          work correctly. We recommend to create a new one. You can try using the
          <span class="font-bold">"Save as new"</span> button below, in case of error the form will
          be updated
        </div>

        <div ref="name" class="block-name">
          <StandardModelFieldComponent
            v-model="methodData.name"
            style="flex: 1 1"
            :type="'str'"
            :is-title="true"
            :label="'Method name'"
            :error="errors.name"
            :isMultiline="true"
            :disabled="!hasPermissionToEdit"
            @update:value="clearError('name')"
          />

          <div v-if="hasPermissionToEdit" class="modal-method-edit__actions">
            <template v-if="!isNewMethod">
              <button
                v-if="isArchived"
                class="button--square-image archive-button"
                @click="restore"
              >
                <i class="material-icons material-icon--18">restore</i>Restore
              </button>
              <button v-else class="button--square-image archive-button" @click="archive">
                <i class="material-icons material-icon--18">delete</i>Archive
              </button>

              <button class="button--square-image favorites-button" @click="toggleFavorite">
                <i class="material-icons material-icon--18">{{
                  methodData.favorite ? 'star' : 'star_border'
                }}</i>
              </button>
            </template>
          </div>
        </div>

        <StateArchived v-if="isArchived" />

        <MethodEditComponent
          ref="editForm"
          v-model="methodData"
          :errors="errors"
          :configuration="preferredConfiguration"
          :disabled="!hasPermissionToEdit"
          class="mt-3 modal-method-edit__form"
          @validation="setValidation"
          @updateField="clearError"
        />
      </div>

      <div v-if="!isArchived" class="modal-footer">
        <div class="modal-content__actions-panel">
          <div class="actions">
            <div v-if="hasPermissionToEdit" class="modal-content__actions">
              <Btn :disabled="!dataChanged" @click="reset">Revert</Btn>
              <Btn v-if="canSaveAsNew" :disabled="!isValid" type="accent" @click="saveAsNew">
                {{ isNewMethod ? 'Create' : 'Save as new' }}
              </Btn>
              <template v-if="!isNewMethod">
                <PopupHelper
                  v-if="!canSave && !isEditable"
                  text="You can't update the method because it's already used. It's allowed to update only the name."
                >
                  <Btn :disabled="!canSave || !isValid" type="primary" @click="save"> Save</Btn>
                </PopupHelper>
                <Btn v-else :disabled="!canSave || !isValid" type="primary" @click="save">
                  Save
                </Btn>
              </template>
            </div>
            <div v-else class="modal-method-edit__no-permissions">
              You can't edit and create methods. Ask the organization owner to give you more
              permissions
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import _ from 'lodash';
  import MethodAPI from 'api/method';
  import ModalComponent from 'components/element/ModalComponent.vue';
  import MethodEditComponent from 'components/block/MethodEditComponent.vue';
  import SelectMethodAsPreset from 'components/element/SelectMethodAsPreset.vue';
  import SearchFakedInput from 'components/element/SearchFakedInput.vue';

  import { METHOD_EMPTY, METHOD_ERRORS_DEFAULT } from '@/constants/methods/presets';
  import Btn from '@/uikitBase/btns/Btn';
  import StandardModelFieldComponent from 'components/element/StandardModelFieldComponent';
  import {
    getMethodWithDefaultValuesByConfiguration,
    isMethodDefaultConfiguration,
    prepareMethodForRequest,
  } from 'utils/methodHelpers.ts';
  import { apiMethods } from '@/api/graphql/cloud/methods';
  import StateArchived from '@/uikitProject/states/StateArchived.vue';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper.vue';
  import { DEFAULT_DEVICE_CONFIGURATION } from '@/constants/methods/configuration';
  import { AuthService } from '@/services/auth.service';

  const EVENT_UPDATE_METHOD = 'update:method';
  const EVENT_UPDATE_METHOD_FORM = 'update:methodForm';
  const EVENT_UPDATE_ARCHIVED = 'update:archived';
  const EVENT_CREATED = 'created';

  export default {
    name: 'MethodEditModal',

    components: {
      PopupHelper,
      StateArchived,
      StandardModelFieldComponent,
      Btn,
      SearchFakedInput,
      MethodEditComponent,
      SelectMethodAsPreset,
      modal: ModalComponent,
    },

    props: {
      method: {
        type: Object,
        default: () => ({}),
      },
      name: {
        type: String,
        default: 'method-edit',
      },
      configuration: {
        type: Object,
      },
      isCloseOnArchivingStatus: {
        type: Boolean,
      },
      deviceId: {
        type: String,
      },
    },

    data: () => ({
      methodData: METHOD_EMPTY,
      errors: { ...METHOD_ERRORS_DEFAULT },
      modalVisible: false,
      methodPresetSearchVisible: false,
      isValid: true,
      preferredConfiguration: DEFAULT_DEVICE_CONFIGURATION,
      defaultMethod: {},
      hasPermissionToEdit: AuthService.userData().role === 'admin',
    }),

    computed: {
      dataChanged() {
        const initialData =
          !this.method || typeof this.method !== 'object' || Object.keys(this.method).length === 0
            ? this.defaultMethod
            : this.method;

        return Object.keys(this.methodData).some((key) => {
          if (key === 'defaultValues') {
            return false;
          }

          if (initialData[key] && typeof initialData[key] === 'object') {
            return !_.isEqual(initialData[key], this.methodData[key]);
          }

          // eslint-disable-next-line eqeqeq
          return initialData[key] != this.methodData[key];
        });
      },
      isOnlyNameChanged() {
        if (this.dataChanged) {
          return Object.keys(this.methodData).every((key) => {
            if (key === 'defaultValues') {
              return true;
            }

            if (key === 'name') {
              // eslint-disable-next-line eqeqeq
              return this.method[key] != this.methodData[key];
            }

            if (this.method[key] && typeof this.method[key] === 'object') {
              return _.isEqual(this.method[key], this.methodData[key]);
            }

            // eslint-disable-next-line eqeqeq
            return this.method[key] == this.methodData[key];
          });
        }
        return false;
      },
      canSave() {
        return this.isEditable ? this.dataChanged : this.dataChanged && this.isOnlyNameChanged;
      },

      methodForRequest() {
        return prepareMethodForRequest(this.methodData);
      },
      isNewMethod() {
        return this.method?.id == null;
      },
      isArchived() {
        return this.methodData?.archived;
      },
      isDeprecated() {
        return this.isDefaultConfiguration && !this.methodData?.is_example;
      },
      isEditable() {
        return this.method?.editable;
      },
      isCromite() {
        return this.preferredConfiguration?.device_type === 'CROMITE';
      },

      canSaveAsNew() {
        return this.deviceId != null || this.preferredConfiguration?.isForImport;
      },

      isDefaultConfiguration() {
        return isMethodDefaultConfiguration(this.method?.hardware_configuration);
      },
    },

    watch: {
      method(newMethod) {
        if (!this.modalVisible) {
          this.updateMethodDataFromObject(newMethod);
        }
      },
    },

    created() {
      this.initDefaultValues();
    },

    mounted() {
      this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
      this.onSubmitError = this.onSubmitError.bind(this);
    },

    methods: {
      opened() {
        this.reset();

        this.initConfiguration();

        this.$emit('opened');
        this.$nextTick(() => {
          this.modalVisible = true;
        });
      },

      closed() {
        this.modalVisible = false;
        this.$emit('closed');
      },

      scrollToField(fieldName) {
        if (fieldName === 'name') {
          this.$refs.name.scrollIntoView({
            behavior: 'smooth',
          });
        } else if (fieldName) {
          this.$refs.editForm.scrollToField(fieldName);
        }
      },

      async openMethodPresetSearch() {
        this.methodPresetSearchVisible = true;
        await this.$nextTick();
        this.$refs.methodPresetSearch.activate();
      },

      closeMethodPresetSearch() {
        this.methodPresetSearchVisible = false;
      },

      deactivateMethodPresetSearch() {
        if (this.methodPresetSearchVisible) {
          this.methodPresetSearchVisible = false;

          // NOTE: manual deactivation. Automatic deactivation is called after component is destroyed => errors in console
          this.$refs?.methodPresetSearch?.deactivate();
        }
      },

      updateMethodDataFromObject(newMethod) {
        this.initDefaultValues(newMethod);
      },

      updateMethodDataFromPreset(method) {
        if (method) {
          this.clearErrors();
          // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
          const { id, favorite, ..._method } = method;
          this.methodData = {
            ...this.methodData,
            ..._.pick(_method, _.keys(this.methodData)),
          };
        }
      },

      onSubmitSuccess(method) {
        this.updateMethodDataFromObject(method);
        this.close();
      },

      onSubmitError(data, status) {
        if (status === 400) {
          const fieldNames = Object.keys(data);
          fieldNames.forEach((field) => {
            this.errors[field] = data[field][0];
          });
          if (fieldNames.length) {
            this.scrollToField(fieldNames[0]);
          }

          if (data.error === 'method.hardware_configuration_mismatch') {
            this.notifyResponseError(data, status);

            this.notify(
              'The device configuration was changed! Your current method form has just been reloaded! Some fields can be added or deleted. Please check all fields and save again',
              15,
            );
            this.initConfiguration(true);
            this.initDefaultValues(this.methodData);
            this.scrollToField('name');
          }
        } else this.notifyResponseError(data, status);
      },

      save() {
        if (!this.isValid) {
          return;
        }

        this.clearErrors();

        MethodAPI.put(
          this.methodForRequest,
          (method) => {
            this.onSubmitSuccess(method);
            const _method = {
              ...method,
              favorite: this.methodData.favorite,
            };

            // We need the event to distinguish between updating a form and editing favorite or archive statuses
            this.$emit(EVENT_UPDATE_METHOD_FORM, _method);

            this.$emit(EVENT_UPDATE_METHOD, _method);

            this.$store.commit('resetMethods');
          },
          this.onSubmitError,
        );
      },
      saveAsNew() {
        if (!this.isValid) {
          return;
        }

        if (!this.configuration) {
          this.notifyError(`Unknown device configuration!`, 10);

          return;
        }

        this.clearErrors();

        MethodAPI.post(
          {
            ...this.methodForRequest,
            favorite: false,
            hardware_configuration: this.preferredConfiguration,
            device_id: this.deviceId,
          },
          (method) => {
            this.onSubmitSuccess(method);
            this.notify(`New method was created: #${method.id} ${method.name}`, 15);
            this.$emit(EVENT_CREATED, method);
            this.$store.commit('resetMethods');
          },
          this.onSubmitError,
        );
      },

      reset() {
        this.methodPresetSearchVisible = false;
        this.clearErrors();
        this.updateMethodDataFromObject(this.method);
      },

      clearErrors() {
        this.errors = { ...METHOD_ERRORS_DEFAULT };
      },
      clearError(fieldName) {
        this.errors = { ...this.errors, [fieldName]: null };
      },

      close() {
        this.$modal.hide(this.name);
      },

      setValidation(value) {
        this.isValid = value;
      },

      async toggleFavorite() {
        this.methodData.favorite
          ? await apiMethods.removeFromFavorites(this.methodData.id)
          : await apiMethods.addToFavorites(this.methodData.id);
        this.methodData.favorite = !this.methodData.favorite;
        this.$emit(EVENT_UPDATE_METHOD, {
          ...this.method,
          favorite: this.methodData.favorite,
        });
        this.$store.commit('resetMethods');
      },

      async archive() {
        await apiMethods.archive(this.methodData.id);
        this.methodData.archived = true;

        this.$emit(EVENT_UPDATE_ARCHIVED, true);
        this.$emit(EVENT_UPDATE_METHOD, {
          ...this.method,
          archived: this.methodData.archived,
        });
        this.$store.commit('resetMethods');

        if (this.isCloseOnArchivingStatus) {
          this.close();
        }
      },
      async restore() {
        await apiMethods.restore(this.methodData.id);
        this.methodData.archived = false;

        this.$emit(EVENT_UPDATE_ARCHIVED, false);
        this.$emit(EVENT_UPDATE_METHOD, {
          ...this.method,
          archived: this.methodData.archived,
        });
        this.$store.commit('resetMethods');

        if (this.isCloseOnArchivingStatus) {
          this.close();
        }
      },

      async initDefaultValues(method) {
        this.methodData = await getMethodWithDefaultValuesByConfiguration(
          method ?? this.method,
          this.preferredConfiguration,
        );
        this.defaultMethod = { ...this.methodData };
      },

      initConfiguration(doUseDeviceCurrentConfiguration = false) {
        this.preferredConfiguration = doUseDeviceCurrentConfiguration
          ? this.configuration
          : this.isDefaultConfiguration
          ? DEFAULT_DEVICE_CONFIGURATION
          : this.method?.hardware_configuration ??
            this.configuration ??
            DEFAULT_DEVICE_CONFIGURATION;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-method-edit {
    &__id {
      color: $color-text-third;
      font-weight: $weight-normal;
      font-size: $size-xs;
      margin-left: 5px;
    }

    &__actions {
      display: flex;
    }

    &__form {
      margin-left: -16px;
    }

    &__outdated {
      padding: 10px;
      margin-bottom: 10px;
      background-color: $color-bg-warning;
      border-radius: $border-radius__md;
      font-size: $size-sm;
      line-height: 1.5;
    }

    &__no-permissions {
      color: $color-text-danger;
    }
  }

  .header-container {
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    & .method-title {
      align-self: self-start;
      flex: 0 0;
      white-space: nowrap;
    }

    & .method-search {
      flex: 1 1;
      display: inline-block;
      margin-left: 16px;
      align-items: flex-end;
    }
  }

  .favorites-button,
  .archive-button {
    margin-top: 10px;
  }

  .block-name {
    display: flex;
    flex-direction: row;
  }
</style>
