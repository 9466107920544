<template>
  <div>
    <slot
      :data="measurementResults"
      :isFetchingData="isFetchingData"
      :isApplyPostprocessingSignal="isApplyPostprocessingSignal"
    />
  </div>
</template>

<script>
  import MeasurementSocket, { MeasurementSocketEvents } from 'api/sockets/MeasurementSocket';
  import SharedMeasurementSocket, {
    SharedMeasurementSocketEvents,
  } from 'api/sockets/SharedMeasurementSocket';
  import { consoleHelpers } from 'utils/logHelpers';
  import { UNKNOWN_VALUE } from '@/utils/chart/chart-constants';
  import { apiMeasurements } from '@/api/graphql/cloud/measurements';

  const EVENT_CREATE = 'create';
  const EVENT_DESTROY = 'destroy';

  export default {
    name: 'PrDataAdapter',

    props: {
      measurements: {
        type: Array,
        required: true,
      },
      sampleToken: {
        type: String,
        default: null,
      },
      hasLiveUpdate: {
        type: Boolean,
        default: true,
      },
      doSkipDataFetching: {
        type: Boolean,
      },
      isApplyPostprocessing: {
        type: Boolean,
      },
      sampleId: {
        type: Number,
        required: true,
      },
    },

    data() {
      return {
        activeSockets: [],
        measurementResults: [],
        isFetchingData: true,
        listenersGroupsId: {},
        isApplyPostprocessingSignal: {
          value: this.isApplyPostprocessing,
        },
      };
    },

    watch: {
      measurements: {
        async handler(measurements) {
          this.isFetchingData = true;

          if (this.doSkipDataFetching) {
            this.measurementResults = this.measurements;
            this.isFetchingData = false;
            return;
          }

          const initMeasurements = () => {
            return Promise.all(
              measurements.map(async (measurementShort) => {
                const measurement = await apiMeasurements.getMeasurement(
                  measurementShort.id,
                  measurementShort.ulid,
                  this.sampleId,
                  this.sampleToken,
                );

                return {
                  ...measurement,
                  data: await measurement.getData(
                    this.isApplyPostprocessing && measurement.postprocessings.length > 0,
                  ),
                  baseline: [],
                  isLoading: false,
                };
              }),
            );
          };

          this.measurementResults = await initMeasurements();
          this.isFetchingData = false;

          this.initSockets();
        },
        immediate: true,
      },
      async isApplyPostprocessing() {
        this.isFetchingData = true;

        this.measurementResults = await Promise.all(
          this.measurementResults.map(async (measurement) => ({
            ...measurement,
            data: await measurement.getData(this.isApplyPostprocessing),
            baseline: [],
          })),
        );

        this.isFetchingData = false;

        this.refreshIsApplyPostprocessingSignal();
      },
    },

    created() {
      this.$emit(EVENT_CREATE);
    },

    beforeDestroy() {
      this.destroySockets();
      this.$emit(EVENT_DESTROY);
    },

    methods: {
      initSockets() {
        const socketIdsShouldBeInit = this.measurements.map(({ id }) => id);
        consoleHelpers.warn('Socket ids', socketIdsShouldBeInit);
        const socketsWithoutClosed = this.activeSockets.filter((socket) => {
          if (socketIdsShouldBeInit.includes(socket.id)) return true;
          socket.close();
          return false;
        });
        consoleHelpers.warn('Sockets without closed', socketsWithoutClosed);

        this.activeSockets = !this.hasLiveUpdate
          ? []
          : this.measurements.map((measurement) => {
              const alreadyConnectedSocket = socketsWithoutClosed.find(
                (socket) => socket.id === measurement.id,
              );
              return alreadyConnectedSocket ?? this.createSocket(measurement.id);
            });
      },

      createSocket(measurementId) {
        const onNewData = (from, values) => {
          this.measurementResults = this.measurementResults.map((_measurement) => {
            if (_measurement?.id === measurementId) {
              const currentData = _measurement?.data;

              if (currentData?.length === from) {
                const data = [...currentData, ...values];
                return {
                  ..._measurement,
                  data,
                  data_length: data.length,
                };
              } else if (currentData?.length < from) {
                const difference = from - currentData.length;
                const unknownValues = Array(difference).fill(UNKNOWN_VALUE);
                const data = [...currentData, ...unknownValues, ...values];

                return {
                  ..._measurement,
                  data,
                  data_length: data.length,
                };
              }
              const data = [...(currentData ?? [])];
              data.splice(from, values.length, ...values);

              return {
                ..._measurement,
                data,
                data_length: data.length,
              };
            }
            return _measurement;
          });
        };

        const socket = this.sampleToken
          ? SharedMeasurementSocket.start(measurementId, null, {
              shareToken: this.sampleToken,
            })
          : MeasurementSocket.start(measurementId);

        const socketEvents = this.sampleToken
          ? SharedMeasurementSocketEvents
          : MeasurementSocketEvents;

        const listenersGroup = socket.createEventListenersGroup();
        this.listenersGroupsId[measurementId] = listenersGroup.id;

        listenersGroup.addEventListener(socketEvents.NEW_VALUES, onNewData);

        return socket;
      },
      destroySockets() {
        if (!this.hasLiveUpdate) {
          return;
        }

        this.activeSockets.forEach((socket) => socket.close(this.listenersGroupsId[socket.id]));
      },

      refreshIsApplyPostprocessingSignal() {
        this.isApplyPostprocessingSignal = {
          value: this.isApplyPostprocessing,
        };
      },
    },
  };
</script>
