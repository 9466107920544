<template>
  <div class="postprocessing-template-edit">
    <div class="postprocessing-template-edit__content">
      <PortalTarget name="selectCalibrationModal" />

      <div class="postprocessing-template-edit__section">
        <h4 class="section first">Detection time</h4>

        <field
          ref="detection_time_start_sec"
          :disabled="disabled"
          :error="errors && errors.detection_time_start_sec"
          :hints="[1, 5, 10, 30, 60, 300, 600]"
          :label="'Detection time start, s'"
          :type="'int'"
          :value="pPostprocessingTemplate.detection_time_start_sec"
          :defaultValue="defaultValues.detection_time_start_sec"
          @update:value="update('detection_time_start_sec', $event)"
        />
        <field
          :disabled="disabled"
          :error="errors && errors.detection_time_end_sec"
          :hints="[1, 5, 10, 30, 60, 300, 600]"
          :label="'Detection time end, s'"
          :type="'int'"
          :value="pPostprocessingTemplate.detection_time_end_sec"
          :defaultValue="defaultValues.detection_time_end_sec"
          @update:value="update('detection_time_end_sec', $event)"
        />
      </div>
      <div class="postprocessing-template-edit__section">
        <h4 class="section first">Peak detection</h4>

        <field
          ref="algorithm_pdet"
          :disabled="true"
          :error="errors && errors.algorithm_pdet"
          :hints="['Default']"
          :label="'Peak detection algorithm'"
          :type="'str'"
          :value="pPostprocessingTemplate.algorithm_pdet"
          :defaultValue="defaultValues.algorithm_pdet"
          helper="todo"
          @update:value="update('algorithm_pdet', $event)"
        />

        <field
          :disabled="disabled"
          :hints="['10 * Noise level', 'Custom mAU']"
          :label="'Min Prominence (relative height), mAU'"
          :type="'str'"
          :value="minProminence"
          helper="todo"
          :canSelectOnlyFromHints="true"
          @update:value="updateMinProminence"
        />

        <field
          v-if="pPostprocessingTemplate.peak_detection_min_prominence !== -1"
          ref="peak_detection_min_prominence"
          :disabled="disabled || pPostprocessingTemplate.peak_detection_min_prominence === -1"
          :error="errors && errors.peak_detection_min_prominence"
          :hints="[10, 100, 500, 1000]"
          :label="'Custom min prominence, mAU'"
          :type="'float'"
          :value="
            pPostprocessingTemplate.peak_detection_min_prominence === -1
              ? 'Allow custom value in the select above'
              : pPostprocessingTemplate.peak_detection_min_prominence
          "
          :defaultValue="defaultValues.peak_detection_min_prominence"
          helper="todo"
          @update:value="update('peak_detection_min_prominence', $event)"
        />

        <field
          ref="peak_detection_min_height"
          :disabled="disabled"
          :error="errors && errors.peak_detection_min_height"
          :hints="[10, 100, 500, 1000]"
          :label="'Min height, mAU'"
          :type="'int'"
          :value="pPostprocessingTemplate.peak_detection_min_height"
          :defaultValue="defaultValues.peak_detection_min_height"
          helper="todo"
          @update:value="update('peak_detection_min_height', $event)"
        />

        <field
          ref="peak_detection_min_width"
          :disabled="disabled"
          :error="errors && errors.peak_detection_min_width"
          :label="'Min Width, sec'"
          :type="'int'"
          :value="pPostprocessingTemplate.peak_detection_min_width"
          :defaultValue="defaultValues.peak_detection_min_width"
          helper="todo"
          @update:value="update('peak_detection_min_width', $event)"
        />

        <field
          ref="peak_detection_min_distance"
          :disabled="disabled"
          :error="errors && errors.peak_detection_min_distance"
          :label="'Min Distance between peaks, min'"
          :type="'int'"
          :value="pPostprocessingTemplate.peak_detection_min_distance"
          :defaultValue="defaultValues.peak_detection_min_distance"
          helper="todo"
          @update:value="update('peak_detection_min_distance', $event)"
        />
      </div>

      <div class="postprocessing-template-edit__section">
        <h4 class="section first">Peak integration</h4>

        <field
          ref="algorithm_peak"
          :error="errors && errors.algorithm_peak"
          :hints="['None', 'Peak Fitting v1']"
          :label="'Peak integration algorithm'"
          :type="'str'"
          :canSelectOnlyFromHints="true"
          :value="pPostprocessingTemplate.algorithm_peak"
          :defaultValue="defaultValues.algorithm_peak"
          helper="todo"
          @update:value="update('algorithm_peak', $event)"
        />

        <template v-if="pPostprocessingTemplate.algorithm_peak === 'Peak Fitting v1'">
          <field
            ref="peak_integration_quality_threshold"
            :disabled="disabled"
            :error="errors && errors.peak_integration_quality_threshold"
            :label="'Quality threshold'"
            :hints="[0.9, 0.95]"
            :type="'float'"
            :value="pPostprocessingTemplate.peak_integration_quality_threshold"
            :defaultValue="defaultValues.peak_integration_quality_threshold"
            helper="todo"
            @update:value="update('peak_integration_quality_threshold', $event)"
          />
        </template>
      </div>

      <div class="postprocessing-template-edit__section">
        <h4 class="section first">Baseline correction</h4>

        <field
          ref="algorithm_base"
          :error="errors && errors.algorithm_base"
          :hints="
            measurement
              ? ['None', 'Manual', 'Horizontal correction', 'Diagonal correction']
              : ['None', 'Horizontal correction', 'Diagonal correction']
          "
          :label="'Baseline correction algorithm'"
          :type="'str'"
          :canSelectOnlyFromHints="true"
          :value="pPostprocessingTemplate.algorithm_base"
          :defaultValue="defaultValues.algorithm_base"
          helper="todo"
          @update:value="update('algorithm_base', $event)"
        />

        <template v-if="pPostprocessingTemplate.algorithm_base === 'Horizontal correction'">
          <field
            ref="baseline_correction_anchor_point"
            :disabled="disabled"
            :error="errors && errors.baseline_correction_anchor_point"
            :label="'Anchor point'"
            :hints="['Start', 'Finish', 'Median']"
            :type="'str'"
            :canSelectOnlyFromHints="true"
            :value="pPostprocessingTemplate.baseline_correction_anchor_point"
            :defaultValue="defaultValues.baseline_correction_anchor_point"
            helper="todo"
            @update:value="update('baseline_correction_anchor_point', $event)"
          />
        </template>

        <template v-if="pPostprocessingTemplate.algorithm_base === 'Manual'">
          <ManualBaselineDrawer
            ref="baseline_correction_points"
            :error="errors && errors.baseline_correction_points"
            :measurementId="measurement.id"
            :measurementUlid="measurement.ulid"
            :method="method"
            class="postprocessing-template-edit__drawer"
            @save="update('baseline_correction_points', $event)"
          />
        </template>
      </div>

      <div class="postprocessing-template-edit__section">
        <h4 class="section first">Blank injection</h4>

        <field
          ref="algorithm_binj"
          :error="errors && errors.algorithm_binj"
          :hints="['None', 'Simple subtraction']"
          :label="'Blank injection algorithm'"
          :type="'str'"
          :canSelectOnlyFromHints="true"
          :value="pPostprocessingTemplate.algorithm_binj"
          :defaultValue="defaultValues.algorithm_binj"
          helper="todo"
          @update:value="update('algorithm_binj', $event)"
        />

        <SelectMeasurement
          v-if="pPostprocessingTemplate.algorithm_binj === 'Simple subtraction'"
          ref="blank_injection_measurement_ulid"
          :measurementUlid="pPostprocessingTemplate.blank_injection_measurement_ulid"
          :measurementId="pPostprocessingTemplate.blank_injection_measurement_id"
          :error="errors && errors.blank_injection_measurement_ulid"
          class="mt-2"
          @model="updateMeasurement"
        />
      </div>

      <div class="postprocessing-template-edit__section">
        <h4 class="section first">Noise reduction</h4>

        <field
          ref="algorithm_nois"
          :error="errors && errors.algorithm_nois"
          :hints="['None', 'Wavelet v1']"
          :label="'Noise reduction algorithm'"
          :type="'str'"
          :canSelectOnlyFromHints="true"
          :value="pPostprocessingTemplate.algorithm_nois"
          :defaultValue="defaultValues.algorithm_nois"
          helper="todo"
          @update:value="update('algorithm_nois', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { POSTPROCESSING_TEMPLATE_DEFAULT } from '@/constants/postprocessingTemplates/presets';
  import StandardModelFieldComponent from 'components/element/StandardModelFieldComponent.vue';
  import SelectMeasurement from '@/uikitProject/injections/measurements/vueSelectMeasurementForm/SelectMeasurementForm.vue';
  import ManualBaselineDrawer from '@/uikitProject/chromatogram/ManualBaselineDrawer.vue';

  const EVENT_UPDATE_POSTPROCESSING_TEMPLATE = 'update:postprocessingTemplate';
  const EVENT_UPDATE_FIELD = 'updateField';

  export default {
    name: 'PostprocessingTemplateEditComponent',

    components: {
      ManualBaselineDrawer,
      SelectMeasurement,
      field: StandardModelFieldComponent,
    },

    model: {
      prop: 'postprocessingTemplate',
      event: EVENT_UPDATE_POSTPROCESSING_TEMPLATE,
    },

    props: {
      postprocessingTemplate: {
        type: Object,
        default: () => ({ ...POSTPROCESSING_TEMPLATE_DEFAULT }),
      },
      errors: {
        type: Object,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      isReadonly: Boolean,
      padding: {
        type: String,
        default: 'md',
        validator(value) {
          const availableValues = ['sm', 'md'];
          return availableValues.includes(value);
        },
      },
      measurement: {
        type: Object,
      },
      method: {
        type: Object,
      },
    },

    data() {
      return {};
    },

    computed: {
      pPostprocessingTemplate() {
        return this.postprocessingTemplate ? this.postprocessingTemplate : {};
      },
      defaultValues() {
        return this.pPostprocessingTemplate.defaultValues;
      },

      minProminence() {
        return this.pPostprocessingTemplate.peak_detection_min_prominence === -1
          ? '10 * Noise level'
          : 'Custom mAU';
      },
    },

    methods: {
      update(key, value) {
        this.$emit(EVENT_UPDATE_POSTPROCESSING_TEMPLATE, {
          ...this.pPostprocessingTemplate,
          [key]: value,
        });
        this.$emit(EVENT_UPDATE_FIELD, key);
      },

      scrollToField(fieldName) {
        this.$refs[fieldName]?.$el.scrollIntoView({
          behavior: 'smooth',
        });
      },

      updateMinProminence(value) {
        this.update('peak_detection_min_prominence', value === '10 * Noise level' ? -1 : 0);
      },

      updateMeasurement(measurement) {
        this.$emit(EVENT_UPDATE_POSTPROCESSING_TEMPLATE, {
          ...this.pPostprocessingTemplate,
          blank_injection_measurement_id: measurement.id,
          blank_injection_measurement_ulid: measurement.ulid,
        });
        this.$emit(EVENT_UPDATE_FIELD, 'blank_injection_measurement_ulid');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .postprocessing-template-edit {
    &__section {
      margin-bottom: 20px;
    }

    &__processing {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
    }

    &__checkbox-valve-change-position {
      margin-top: 10px;
    }

    &__checkbox-auto-peaks {
      &:not(:last-child) {
        margin-top: 10px;
      }
    }

    &__label-input {
      color: $color-text-third;
    }

    &__drawer {
      margin-top: 8px;
    }
  }
</style>

<style lang="scss">
  @import '~assets/css/base/icons';

  .postprocessing-template-edit {
    &__icon-section {
      margin-right: 3px !important;
    }

    i {
      @extend .material-icons;
      @extend .material-icon--18;
    }

    .standard-model-field {
      margin-top: 8px;
      display: block;
    }
  }
</style>
