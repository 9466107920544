import { makeGraphqlRequestCloud } from 'api/graphql/graphQLClient';
import { CalibrationFull } from '@/graphql/cloud/calibrations/queries/CalibrationFull.graphql';
import { CalibrationsForMeasurement } from '@/graphql/cloud/calibrations/queries/CalibrationsForMeasurement.graphql';
import { Calibrations } from '@/graphql/cloud/calibrations/queries/Calibrations.graphql';
import { transformArrayToObject } from '@/utils/objectHelpers';
import DataNotFoundError from '@/errors/DataNotFoundError';

export const apiCalibrations = {
  async getCalibrationFull(id) {
    const { calibration: calibrationFull } = await makeGraphqlRequestCloud(CalibrationFull, { id });

    if (!calibrationFull) {
      throw new DataNotFoundError();
    }

    const {
      sample: { device, injections, column, method, ...sample },
      ...calibration
    } = calibrationFull;

    return {
      device,
      injections: transformArrayToObject(
        injections.map(({ device, column, method, ...injection }) => ({
          id: injection.id,
          device,
          column,
          method,
          injection: {
            ...injection,
            ...injection.calibrationInjection,
            measurements: transformArrayToObject(injection.measurements, 'id'),
          },
        })),
        'id',
      ),
      column,
      method,
      calibration: {
        ...calibration,
        ...sample,
        device_name: device?.name,
      },
    };
  },
  async getCalibrations() {
    const { calibrations } = await makeGraphqlRequestCloud(Calibrations);

    return calibrations.map(({ id, sample }) => ({
      id,
      ...sample,
      device_name: sample?.device?.name,
    }));
  },
  async getForEstimationList(measurementId) {
    const { calibrations } = await makeGraphqlRequestCloud(CalibrationsForMeasurement, {
      measurementId,
    });

    return calibrations.map(({ id, sample }) => ({
      id,
      ...sample,
      device_name: sample?.device?.name,
    }));
  },
};
