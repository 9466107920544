import { REST } from 'utils/rest';

const url = '/api/knowledge/compounds/';

export default class CompoundAPI {
  static getList(onSuccess, onError, offset, limit) {
    const params = [];

    if (limit) {
      params.push(`limit=${limit}`);
    }

    if (offset) {
      params.push(`offset=${offset}`);
    }

    const resultUrl = params.length === 0 ? url : `${url}?${params.join('&')}`;

    return REST.get(resultUrl, onSuccess, onError);
  }

  static search(query, onSuccess, onError, offset, limit) {
    if (query == null || query === '') {
      return CompoundAPI.getList(onSuccess, onError, offset, limit);
    }

    const params = [`query=${query}`];

    if (limit) {
      params.push(`limit=${limit}`);
    }

    if (offset) {
      params.push(`offset=${offset}`);
    }

    const resultUrl = `${url}?${params.join('&')}`;
    return REST.get(resultUrl, onSuccess, onError);
  }
}
