<template>
  <div class="device-page">
    <AlltestaTowerSettingsModal
      v-if="device != null"
      ref="settingsModal"
      :device-id="id"
      :isCromite="isCromite"
    />

    <template v-if="device != null">
      <div class="top-line--flex device-page__wrapper-title">
        <div>
          <InputTitle
            v-if="device"
            v-model="device.name"
            iconName="input"
            data-test-id="page-title"
            @save="saveName"
          />

          <div class="pt-0 pb-2 mt-0">
            <template v-if="device.name !== ' '">
              <DeviceType :model="device.model" class="mr-2" />
              <DeviceConnection
                :hasDetails="true"
                :device="device"
                :deviceSocket="socket"
                class="mr-2"
              />
              <template v-if="device.versioning && device.versioning.current_version">
                <FirmwareStatusMini
                  :data="device.versioning"
                  :deviceId="device.id"
                  class="mr-2"
                  @update="showSuggestionToUpdate"
                />
              </template>
              <StatusOnline
                :isOnline="device.is_online"
                :wasOnline="device.date_last_online"
                :isWorking="isInjectionInProgress"
                class="mr-2"
                data-test-id="device-status-online"
              />
            </template>
            <data-state-component v-else :updating="true" class="m-0 text-left" />
          </div>
        </div>
        <div class="top-line__actions" data-test-id="btn-show-settings">
          <button
            class="button--square-image"
            :disabled="state.injection_id !== null"
            @click="settings"
          >
            <i class="material-icons material-icon--18">settings</i>
            Settings
          </button>
        </div>
      </div>

      <TransitionExpand>
        <div v-if="state.injection_id == null && isShowSuggestionToUpdate" class="top-line">
          <FirmwareStatus
            class="mt-2"
            :device="device"
            @update="updateVersion"
            @later="updateLater"
            @forceUpdate="forceUpdateVersion"
            @confirm="submitUpdateResult"
          />
        </div>
      </TransitionExpand>

      <SampleInjectionMiniComponent :injectionId="state.injection_id" />

      <DeviceHardwareComponent
        :device="device"
        :method="method"
        :socket="socket"
        class="device-page__hardware"
        @start="start"
      />

      <MethodCard
        :method="method"
        :configuration="configurationForMethod"
        :deviceId="device.id"
        class="mt-12 top-line"
        @update:method="updateDeviceMethod"
      />
      <ColumnCard :column="column" class="mt-12 top-line" @update:column="updateDeviceColumn" />

      <div style="background-color: #00000044; height: 1px; margin: 64px 0 56px" />

      <!--<button-->
      <!--  class="button&#45;&#45;square-image"-->
      <!--  style="width: 64px; float: right; margin-top: 28px; margin-right: 16px;"-->
      <!--  @click="newSequence"-->
      <!--&gt;-->
      <!--  <i class="material-icons material-icon&#45;&#45;18" style="width: 26px;">+group_work</i>-->
      <!--  Sequence-->
      <!--</button>-->

      <!--<button-->
      <!--  class="button&#45;&#45;square-image hidden sm:inline"-->
      <!--  style="width: 64px; float: right; margin-top: 28px;"-->
      <!--  @click="newCalibration"-->
      <!--&gt;-->
      <!--  <i class="material-icons material-icon&#45;&#45;18" style="width: 26px;">+star</i>-->
      <!--  Calibration-->
      <!--</button>-->

      <!--<button-->
      <!--  class="button&#45;&#45;square-image"-->
      <!--  style="width: 64px; float: right; margin-top: 28px;"-->
      <!--  @click="newSample"-->
      <!--&gt;-->
      <!--  <i class="material-icons material-icon&#45;&#45;18" style="width: 26px;">+opacity</i>-->
      <!--  Sample-->
      <!--</button>-->

      <div class="top-line--flex">
        <h4 style="margin: 0 0 4px 0">Recent Runs</h4>
        <search-faked-input
          style="float: right; margin-right: 0; vertical-align: middle"
          @mousedown="$router.push({ name: 'search', query: { device_id: id } })"
        />
      </div>

      <runs-list-component style="margin-top: 24px" v-bind:runs="runs" />
    </template>
    <error-component v-else-if="errorCode != null" :code="errorCode" style="margin-top: 64px" />
    <loading-component v-else />
  </div>
</template>

<script>
  import 'assets/css/base/icons.scss';

  import DeviceAPI from 'api/device';
  import DeviceSocket, { DeviceSocketEvents } from 'api/sockets/DeviceSocket';
  import StatusOnline from '@/uikitProject/statuses/StatusOnline.vue';
  import FirmwareStatus from '@/uikitProject/devices/FirmwareStatus';
  import DataStateComponent from 'components/element/DataStateComponent.vue';
  import SearchFakedInput from 'components/element/SearchFakedInput.vue';
  import DeviceType from '@/uikitProject/devices/DeviceType.vue';
  import DeviceConnection from '@/uikitProject/devices/vueDeviceConnection/DeviceConnection.vue';
  import RunsListComponent from '@/components/block/RunsListComponent.vue';
  import PageTitle from '@/mixins/page-title';
  import ErrorComponent from 'components/element/ErrorComponent';
  import LoadingComponent from 'components/element/LoadingComponent';
  import DeviceHardwareComponent from 'components/block/DeviceHardwareComponent';
  import SampleInjectionMiniComponent from 'components/block/SampleInjectionMiniComponent';
  import TransitionExpand from '@/uikitProject/transitions/TransitionExpand';
  import NavigatorHelper from 'utils/NavigatorHelper.ts';
  import ColumnCard from 'components/blocks/cards/ColumnCard';
  import MethodCard from 'components/blocks/cards/MethodCard';
  import FirmwareStatusMini from '@/uikitProject/devices/FirmwareStatusMini';
  import AlltestaTowerSettingsModal from 'components/block/modal/AlltestaTowerSettingsModal';
  import InputTitle from '@/uikitProject/inputs/inputTitle/InputTitle';
  import { persistentStorage, PersistentStorageKeys } from 'utils/persistentStorage';

  const preventWhiteRectForMobileSafari = () => {
    if (NavigatorHelper.isMobile && NavigatorHelper.browserName === 'safari') {
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
      }, 400);
    }
  };

  export default {
    name: 'DevicePage',

    components: {
      InputTitle,
      AlltestaTowerSettingsModal,
      FirmwareStatusMini,
      MethodCard,
      ColumnCard,
      TransitionExpand,
      SampleInjectionMiniComponent,
      DeviceHardwareComponent,
      LoadingComponent,
      ErrorComponent,
      SearchFakedInput,
      DataStateComponent,
      RunsListComponent,
      DeviceConnection,
      DeviceType,
      StatusOnline,
      FirmwareStatus,
    },

    mixins: [PageTitle],

    props: {
      id: String,
    },

    data() {
      return {
        pageTitle: 'HPLC Cloud: Device',

        device: null,

        method: null,
        column: null,

        runs: null,

        socket: this.createDeviceSocket(this.id),

        connectionLost: false,
        timerConnectionLost: null,

        errorCode: null,

        isShowSuggestionToUpdate: false,

        listenersGroupId: null,
      };
    },

    computed: {
      state() {
        return this.device?.state;
      },
      configurationForMethod() {
        return this.device.configuration_for_method;
      },

      isCromite() {
        return this.device?.model === 'cmt';
      },

      isInjectionInProgress() {
        return this.device.state.injection_id != null;
      },
    },

    watch: {
      id(id) {
        this.socket = this.createDeviceSocket(id);
      },
      device(d) {
        if (d?.is_online !== false) {
          this.onConnectionConfirmed();
        }
        this.pageTitle = d?.name || 'HPLC Cloud: Device';
      },
      connectionLost(connectionLost) {
        if (!connectionLost) {
          this.socket.dontsleep();
        }
      },
      state(s) {
        if (s.ready == null) return;
        if (s.sleep_countdown && s.sleep_countdown <= 1) {
          this.socket.dontsleep();
        }

        this.onConnectionConfirmed();
      },
    },

    beforeDestroy() {
      if (this.timerConnectionLost != null) clearTimeout(this.timerConnectionLost);
      this.destroyDeviceSocket();
    },

    mounted() {
      preventWhiteRectForMobileSafari();
    },

    methods: {
      updateDeviceMethod(method) {
        this.method = method;

        let methodID;
        if (method) {
          methodID = method.id;
        } else {
          methodID = null;
        }

        if (this.device.method_id !== methodID) {
          const onSuccess = (device) => {
            this.device.method_id = device.method_id;
          };
          const onError = () => {
            this.notifyError(`Can't select this method!`);
          };

          // / NOTE: PATCH is more correct http method
          DeviceAPI.put(this.id, { method_id: methodID }, onSuccess, onError);
        }
      },
      updateDeviceColumn(column) {
        let columnID;
        if (column) {
          columnID = column.id;
        } else {
          columnID = null;
        }

        if (this.device.column_id !== columnID) {
          const onSuccess = (device) => {
            this.device.column_id = device.column_id;
          };
          const onError = () => {
            this.notifyError(`Can't select this column!`);
          };

          // NOTE: PATCH is more correct http method
          DeviceAPI.put(this.id, { column_id: columnID }, onSuccess, onError);
        }
      },

      start(vial) {
        const onSuccess = () => this.notify('Starting');
        DeviceAPI.run(this.device.id, { vial }, onSuccess, this.notifyResponseError);
      },

      onConnectionConfirmed() {
        this.connectionLost = false;
        this.updateConnectionLostTimeout();
      },
      updateConnectionLostTimeout() {
        if (this.timerConnectionLost != null) clearTimeout(this.timerConnectionLost);
        this.timerConnectionLost = setTimeout(this.onConnectionLost, 4500);
      },
      onConnectionLost() {
        this.connectionLost = true;
        this.socket.dontsleep();
        this.updateConnectionLostTimeout();
      },

      createDeviceSocket(id) {
        this.destroyDeviceSocket();

        const onDevice = (deviceData) => {
          if (this.device?.state) deviceData.state = { ...this.device?.state, ...deviceData.state };
          // Preprocess received data
          const { column, method, ...device } = deviceData;
          device.column_id = column?.id;
          device.method_id = method?.id;
          device.method = method;
          //
          this.device = device;
          this.method = method || undefined;
          this.column = column;
        };
        const onDeviceRecentRuns = (runs) => {
          this.runs = runs;
        };
        const onMethodReset = () => {
          this.notify(
            'The device configuration was changed! Your method was reset. Select or create a new one.',
            20,
          );
        };

        const socket = DeviceSocket.start(
          id,
          async (connection) => {
            connection.dontsleep();
            const { device } = await connection.get();
            onDevice(device);
            const { runs } = await connection.getRecentRuns();
            onDeviceRecentRuns(runs);
          },
          (e) => {
            this.errorCode = e.code;
          },
        );

        const listenersGroup = socket.createEventListenersGroup();
        this.listenersGroupId = listenersGroup.id;

        listenersGroup.addEventListener(DeviceSocketEvents.DEVICE, onDevice);
        listenersGroup.addEventListener(DeviceSocketEvents.STATE, (s) => {
          if (this.device?.state?.injection_id !== s.injection_id) {
            this.showNotificationIfRpcError(async () => {
              const { device } = await socket.get();
              onDevice(device);
            });
          }
          this.device.state = s;
        });
        listenersGroup.addEventListener(DeviceSocketEvents.RECENT_RUNS, onDeviceRecentRuns);
        listenersGroup.addEventListener(DeviceSocketEvents.METHOD_RESET, onMethodReset);
        listenersGroup.addEventListener(DeviceSocketEvents.ERROR, (error) => {
          this.notifyError(error);
        });

        return socket;
      },
      destroyDeviceSocket() {
        if (this.socket != null) this.socket.close(this.listenersGroupId);
        this.socket = null;
      },

      saveName(name) {
        DeviceAPI.put(this.id, { name });
      },

      updateVersion() {
        const onSuccess = () => {
          // no stub
        };
        DeviceAPI.update(this.id, onSuccess, this.notifyResponseError);
        persistentStorage.remove(PersistentStorageKeys.DEVICE_VERSION_UPDATE_LATER, {
          params: [this.device.id],
        });
      },
      forceUpdateVersion(targetVersion) {
        const onSuccess = () => {
          // no stub
        };
        DeviceAPI.forceUpdate(this.id, targetVersion, onSuccess, this.notifyResponseError);
      },
      submitUpdateResult() {
        const onSuccess = () => {
          // stub
        };
        DeviceAPI.submitUpdateResult(this.id, onSuccess, this.notifyResponseError);
      },
      updateLater(versionToUpdate) {
        persistentStorage.set(
          PersistentStorageKeys.DEVICE_VERSION_UPDATE_LATER,
          {
            version: versionToUpdate,
            timestamp: new Date().getTime(),
          },
          { params: [this.device.id] },
        );
        this.hideSuggestionToUpdate();
      },

      settings() {
        this.$refs.settingsModal.show();
      },

      newSample() {
        this.$router.push({
          name: 'sample new',
          query: { device: this.id },
        });
      },
      newCalibration() {
        this.$router.push({
          name: 'calibration new',
          query: { device: this.id },
        });
      },
      newSequence() {
        this.$router.push({
          name: 'sequence new',
          query: { device: this.id },
        });
      },

      showSuggestionToUpdate() {
        this.isShowSuggestionToUpdate = true;
      },
      hideSuggestionToUpdate() {
        this.isShowSuggestionToUpdate = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  .device-page {
    &__wrapper-title {
      align-items: baseline;
    }

    &__hardware {
      margin-top: 32px;

      @media (max-width: 932px) {
        margin-top: 16px;
        padding: 0 16px;
      }

      @media (max-width: $screen-xs-max) {
        margin-top: 8px;
        padding: 0 4px;
      }
    }
  }
</style>
