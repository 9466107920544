<template>
  <div class="p-0 m-0">
    <div
      ref="divCalibrationsChart"
      v-resize:throttle="onResize"
      class="disable-selection"
      style="height: 300px"
      @mousemove="chartMouseMove"
      @mouseleave="chartMouseLeave"
    />
  </div>
</template>

<script>
  import resize from 'vue-resize-directive';
  import { SVG } from '@svgdotjs/svg.js';
  import { ALT_CHART_COLORS, DELTAS_MAU, DELTAS_TIME } from 'utils/chart/chart-constants.ts';

  export default {
    name: 'CalibrationsChartComponent',

    directives: { resize },

    props: ['calibration', 'injections', 'injectionHighlightedId'],

    data() {
      return {
        highlightID: 0,
        draw: null,
        chart: {
          xMax: 0,
          yMax: 0,
        },
        chartSvg: {
          points: {},
          line: null,
          rect: null,
        },
        dots: [],
      };
    },

    watch: {
      injections() {
        this.updateMinMax();
        this.redraw();
      },
      injectionHighlightedId(highlightedId) {
        this.dots.forEach(({ id, svg }) => {
          svg.fill(highlightedId === id ? ALT_CHART_COLORS.dotActive : ALT_CHART_COLORS.dot);
        });
      },
    },

    mounted() {
      const { divCalibrationsChart } = this.$refs;
      this.draw = SVG().addTo(divCalibrationsChart).size('100%', '100%');
      this.updateRect();
      this.refresh(true);
    },

    methods: {
      onResize() {
        this.updateRect();
        this.refresh(false);
      },
      refresh() {
        this.updateMinMax();
        this.redraw();
      },

      updateRect() {
        const { divCalibrationsChart } = this.$refs;
        if (divCalibrationsChart == null) {
          this.chartSvg.rect = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
          };
          return;
        }
        this.chartSvg.rect = divCalibrationsChart.getBoundingClientRect();
      },

      updateMinMax() {
        let xMax = 0.001;
        let yMax = 0.001;
        Object.values(this.injections).forEach((i) => {
          if (!i.injection.peak_id || i.injection.archived) {
            return;
          }

          const { amount } = i.injection;
          const area = i.injection.peak_area;
          if (amount > xMax) xMax = amount;
          if (area > yMax) yMax = area;
        });

        const { k } = this.calibration;
        xMax = Math.max(xMax, yMax * k);
        yMax = Math.max(yMax, xMax / k) * 1.25;

        this.chart.xMax = yMax * k;
        this.chart.yMax = yMax;
      },

      chartMouseMove() {
        // const p = this.getXY(e);
      },

      chartMouseLeave() {
        this.highlightID = null;
      },

      getXY(e) {
        const x = e.clientX - this.chartSvg.rect.x;
        const y = e.clientY - this.chartSvg.rect.y;
        return { x, y };
      },

      redraw() {
        const colorScale = ALT_CHART_COLORS.scale;
        const colorGrid = ALT_CHART_COLORS.grid;
        const colorText = ALT_CHART_COLORS.text;
        const colorDot = ALT_CHART_COLORS.dot;

        if (this.draw == null) return;

        const { draw } = this;

        this.updateRect();
        const w = this.chartSvg.rect.width;
        const h = this.chartSvg.rect.height;

        const xs = 0; // x start
        const xe = this.chart.xMax; // x end
        const ys = 0; // y start
        const ye = this.chart.yMax; // y end

        if (xe - xs <= 0) return;
        if (ye - ys <= 0) return;

        draw.clear();

        draw.rect(w, h).fill(ALT_CHART_COLORS.background);

        // MAu Scale

        let d = (ye - ys) / 10.0;
        for (let i = 0; i < DELTAS_MAU.length; i++) {
          if (d <= DELTAS_MAU[i]) {
            d = DELTAS_MAU[i];
            break;
          }
        }

        draw.line(0, 0, 0, h).stroke({ width: 1, color: colorScale });

        const min2 = Math.round(ys / d) * d;
        for (let mau = min2; mau <= ye; mau += d) {
          const y = Math.round(h - ((mau - ys) * h) / (ye - ys));
          draw
            .text((Math.round(mau * 100) / 100).toString())
            .move(3, y - 14)
            .font({
              family: 'PT Sans, Helvetica',
              size: 13,
              anchor: 'left',
              leading: '1.5em',
              fill: colorText,
            });
          draw.line(0, y, w, y).stroke({ width: 0.5, color: colorGrid });
        }

        draw.text('Peak area, mAU×min').move(40, 2).font({
          family: 'PT Sans, Helvetica',
          size: 13,
          anchor: 'left',
          leading: '1.5em',
          fill: colorText,
        });

        // Time Scale

        const duration = xe - xs;
        let dW = 1;
        if (duration / 15 > 1 || duration / 8 < 1) {
          dW = duration / 12;
          for (let i = 0; i < DELTAS_TIME.length; i++) {
            if (dW < DELTAS_TIME[i]) {
              dW = DELTAS_TIME[i];
              break;
            }
          }
        }

        draw.line(0, h, w, h).stroke({ width: 1, color: colorScale });

        const x2 = Math.round(xs / dW) * dW;
        for (let i = x2; i < xe; i += dW) {
          const x = Math.round(((i - xs) * w) / (xe - xs));
          draw
            .text((Math.round(i * 100) / 100).toString())
            .move(x + 2, h - 15)
            .font({
              family: 'PT Sans, Helvetica',
              size: 13,
              anchor: 'left',
              leading: '1.5em',
              fill: colorText,
            });
          draw.line(x, 0, x, h).stroke({ width: 0.5, color: colorGrid });
        }

        draw
          .text(`Amount, ${this.calibration.unit}`)
          .move(w - 120, h - 30)
          .font({
            family: 'PT Sans, Helvetica',
            size: 13,
            anchor: 'left',
            leading: '1.5em',
            fill: colorText,
          });

        // Dots

        draw.line(0, h, w, 0).fill(colorDot).stroke({
          color: colorDot,
          width: 2,
        });

        this.dots = [];
        Object.values(this.injections).forEach((i) => {
          if (i.injection.archived) {
            return;
          }

          const { amount } = i.injection;
          const area = i.injection.peak_area;
          if (area !== null && amount !== null) {
            const x = ((amount - xs) * w) / (xe - xs);
            const y = h - ((area - ys) * h) / (ye - ys);

            const dot = draw.group().fill(colorDot);
            const circle = draw
              .circle(17, 17)
              .move(x - 8, y - 8)
              .stroke({
                color: '#ffffff',
                width: 1.75,
              });
            const text = draw
              .text(i.injection.name)
              .move(x - 3, y - 9)
              .font({
                family: 'PT Sans, Helvetica',
                size: 13,
                anchor: 'left',
                leading: '1.5em',
                fill: '#ffffff',
              });

            dot.data('id', i.injection.id).add(circle).add(text);

            this.createDotEventListeners(dot);

            this.dots.push({ id: i.injection.id, svg: dot });
          }
        });
      },

      peakRemove(id) {
        this.showNotificationIfRpcError(() => this.injectionSocket.peakRemove(id));
      },
      baselineReset() {
        this.showNotificationIfRpcError(() => this.injectionSocket.baselineReset());
      },
      baselineSet(timeStart, timeEnd) {
        this.showNotificationIfRpcError(() => this.injectionSocket.baselineSet(timeStart, timeEnd));
      },
      baselineAuto() {
        this.showNotificationIfRpcError(() => this.injectionSocket.baselineAuto());
      },

      createDotEventListeners(dot) {
        const that = this;
        dot.on('mouseenter', function () {
          that.$emit('update:injectionHighlightedId', this.data('id'));
        });
        dot.on('mouseleave', function () {
          that.$emit('update:injectionHighlightedId', null);
        });
      },
    },
  };
</script>
