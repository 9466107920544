<template>
  <ModalComponent :width="750 - 128" name="modalCalibrationNew" #default="{ close }">
    <CalibrationNewComponent @create="handleCreate(close)" />
  </ModalComponent>
</template>

<script>
  import ModalComponent from 'components/element/ModalComponent.vue';
  import CalibrationNewComponent from 'components/block/CalibrationNewComponent';

  const EVENT_CREATE = 'create';

  export default {
    name: 'CalibrationNewModal',

    components: {
      CalibrationNewComponent,
      ModalComponent,
    },

    methods: {
      handleCreate(closeModal) {
        closeModal();
        this.$emit(EVENT_CREATE);
      },
    },
  };
</script>
