import AccountPage from 'components/page/AccountPage.vue';
import ColumnsPage from 'components/page/ColumnsPage.vue';
import ColumnDetails from 'components/page/ColumnDetails.vue';
import CalibrationNewPage from 'components/page/CalibrationNewPage.vue';
import CalibrationPage from 'components/page/CalibrationPage.vue';
import DevicesPage from 'components/page/DevicesPage.vue';
import DevicePage from 'components/page/DevicePage.vue';
import MethodsPage from 'components/page/MethodsPage.vue';
import MethodDetails from '@/components/page/MethodDetails.vue';
import SamplePage from 'components/page/SamplePage.vue';
import SampleNewPage from 'components/page/SampleNewPage.vue';
import SequencePage from 'components/page/SequencePage.vue';
import SequenceNewPage from 'components/page/SequenceNewPage.vue';
import SearchPage from 'components/page/SearchPage.vue';
import ChromatogramsComparePage from 'components/page/ChromatogramsComparePage.vue';
import DeviceTerminalPage from 'components/page/DeviceTerminalPage.vue';

import { RouteConfig } from '../types';
import { Nullable } from '@/types/utility';
import MethodPreview from '@/components/page/MethodPreview.vue';
import OrganizationPage from '@/components/page/OrganizationPage.vue';
import PostprocessingTemplatesPage from '@/components/page/PostprocessingTemplatesPage.vue';

export const dualModeRoutesPrimary: RouteConfig[] = [
  {
    path: 'devices/',
    name: 'dual.devices',
    component: DevicesPage,
  },
  {
    path: 'devices/:id([a-zA-Z0-9]+)/terminal/',
    name: 'dual.deviceTerminal',
    component: DeviceTerminalPage,
    props: (route) => ({ id: route.params.id }),
  },
  {
    path: 'samples/',
    name: 'dual.samples',
    component: SearchPage,
  },
  {
    path: 'calibrations/',
    name: 'dual.calibrations',
    component: SearchPage,
  },
  {
    path: 'sequences/',
    name: 'dual.sequences',
    component: SearchPage,
  },
  {
    path: 'search/',
    name: 'dual.search',
    component: SearchPage,
  },
  {
    path: 'columns/',
    name: 'dual.columns',
    component: ColumnsPage,
  },
  {
    path: 'methods/',
    name: 'dual.methods',
    component: MethodsPage,
  },
  {
    path: 'postprocessing-templates/',
    name: 'dual.postprocessingTemplates',
    component: PostprocessingTemplatesPage,
  },
  {
    path: 'account/',
    name: 'dual.account',
    component: AccountPage,
  },
  {
    path: 'organization/',
    name: 'dual.organization',
    component: OrganizationPage,
  },
  {
    path: 'chromatograms-compare/',
    name: 'dual.chromatograms compare',
    component: ChromatogramsComparePage,
    props: (route) => {
      let ids: Nullable<number[]> = null;
      try {
        ids = (route.query.ids as string)?.split('.')?.map((i) => parseInt(i, 10));
      } catch (e) {
        //stub
      }

      return {
        ids,
      };
    },
  },
  {
    path: 'methods/preview/:methodId(\\d+)/',
    name: 'dual.methodPreview',
    component: MethodPreview,
    props: (route) => ({
      methodId: Number(route.params.methodId),
    }),
  },
];

const dualModeRoutesMaybePrimary: RouteConfig[] = [
  {
    path: 'devices/:id(.+)/',
    name: 'dual.device',
    component: DevicePage,
    props: (route) => ({ id: route.params.id }),
  },
  {
    path: 'samples/new/',
    name: 'dual.sample new',
    meta: {
      colorBg: 'gray',
    },
    component: SampleNewPage,
  },
  {
    path: 'samples/:id(\\d+)/',
    name: 'dual.sample',
    component: SamplePage,
    meta: {
      colorBg: 'dark-gray',
    },
    props: (route) => ({ id: parseInt(route.params.id, 10), iid: null }),
  },
  {
    path: 'samples/:id(\\d+)/injection/:iid(\\d+)/',
    name: 'dual.sample injection',
    component: SamplePage,
    meta: {
      colorBg: 'dark-gray',
    },
    props: (route) => ({
      id: parseInt(route.params.id, 10),
      iid: parseInt(route.params.iid, 10),
    }),
  },
  {
    path: 'samples/:id(\\d+)/injection/new/',
    name: 'dual.sample injection new',
    component: SamplePage,
    meta: {
      colorBg: 'dark-gray',
    },
    props: (route) => ({
      id: parseInt(route.params.id, 10),
      iid: -1,
    }),
  },
  {
    path: 'calibrations/new/',
    name: 'dual.calibration new',
    component: CalibrationNewPage,
    meta: {
      colorBg: 'gray',
    },
  },
  {
    path: 'calibrations/:id(\\d+)/',
    name: 'dual.calibration',
    component: CalibrationPage,
    meta: {
      colorBg: 'dark-gray',
    },
    props: (route) => ({ id: parseInt(route.params.id, 10), iid: null }),
  },
  {
    path: 'calibrations/:id(\\d+)/injection/:iid(\\d+)/',
    name: 'dual.calibration injection',
    component: CalibrationPage,
    meta: {
      colorBg: 'dark-gray',
    },
    props: (route) => ({
      id: parseInt(route.params.id, 10),
      iid: parseInt(route.params.iid, 10),
    }),
  },
  {
    path: 'calibrations/:id(\\d+)/injection/new/',
    name: 'dual.calibration injection new',
    component: CalibrationPage,
    meta: {
      colorBg: 'dark-gray',
    },
    props: (route) => ({
      id: parseInt(route.params.id, 10),
      iid: -1,
      defaultAmount: route.query.amount,
    }),
  },
  {
    path: 'sequences/new/',
    name: 'dual.sequence new',
    component: SequenceNewPage,
    meta: {
      colorBg: 'gray',
    },
  },
  {
    path: 'sequences/:id(\\d+)/',
    name: 'dual.sequence',
    component: SequencePage,
    meta: {
      colorBg: 'dark-gray',
    },
    props: (route) => ({ id: parseInt(route.params.id, 10), sid: null, iid: null }),
  },
  {
    path: 'sequences/:id(\\d+)/:sid(\\d+)',
    name: 'dual.sequence child',
    component: SequencePage,
    meta: {
      colorBg: 'dark-gray',
    },
    props: (route) => ({
      id: parseInt(route.params.id, 10),
      sid: parseInt(route.params.sid, 10),
      iid: null,
    }),
  },
  {
    path: 'sequences/:id(\\d+)/:sid(\\d+)/:iid(\\d+)',
    name: 'dual.sequence child injection',
    component: SequencePage,
    meta: {
      colorBg: 'dark-gray',
    },
    props: (route) => ({
      id: parseInt(route.params.id, 10),
      sid: parseInt(route.params.sid, 10),
      iid: parseInt(route.params.iid, 10),
    }),
  },
  {
    path: 'sequences/:id(\\d+)/:sid(\\d+)/new',
    name: 'dual.sequence child injection new',
    component: SequencePage,
    meta: {
      colorBg: 'dark-gray',
    },
    props: (route) => ({
      id: parseInt(route.params.id, 10),
      sid: parseInt(route.params.sid, 10),
      iid: -1,
    }),
  },
  {
    path: 'columns/:columnId(\\d+)/',
    name: 'dual.columnDetails',
    component: ColumnDetails,
    props: (route) => ({
      columnId: Number(route.params.columnId),
    }),
  },
  {
    path: 'methods/:methodId(\\d+)/',
    name: 'dual.methodDetails',
    component: MethodDetails,
    props: (route) => ({
      methodId: Number(route.params.methodId),
    }),
  },
];

const dualMode = {
  path: '/dual/',

  component: () => import('@/layouts/Dual.vue'),
  children: [...dualModeRoutesPrimary, ...dualModeRoutesMaybePrimary],
};

export default dualMode;
