import { Nullable } from '@/types/utility';
import { SECONDS_IN_MINUTE } from '@/uikitProject/charts/new/private/constants';
import { DataExtended, Measurement } from '@/uikitProject/charts/new/private/types';
import { sendDebugEvent } from '@/utils/sentryHelpers';
import { memoize } from '@/utils/performanceHelpers';
import _ from 'lodash';
export { createZoomActivationOverlay } from './zoomActivationOverlay';
export { createMessageOverlay } from './messageOverlay';

export const trimDataIfOutOfDetection = memoize(
  (data: number[], startIndex: number, endIndex: number): Array<Nullable<number>> =>
    data.slice(startIndex, endIndex + 1),
  { max: 5 },
);

export const getXAxisValues = (data: DataExtended) => {
  return data.y.map((_, index) => index * data.dx + data.x0);
};

export const getStartAndEndXValues = (data: DataExtended): [start: number, end: number] => {
  if (data.y.length === 0) {
    throw new Error('There are no Y values');
  }

  const yLastIndex = data.y.length - 1;
  return [data.x0, yLastIndex * data.dx + data.x0];
};

export const hasSamePeaks = (measurementA: Measurement, measurementB: Measurement) => {
  // TODO Check error and remove
  if (measurementA.peaks == null || measurementB.peaks == null) {
    sendDebugEvent('#2269: Front: Fix sentry issues', [
      ['Measurements', [measurementA, measurementB]],
      ['Task', 'https://gitlab.com/hplc-cloud-group/hplc-cloud-server/-/issues/2269'],
      [
        'Sentry issue',
        'https://newcrom.sentry.io/issues/4703701845/?project=6192768&statsPeriod=90d&stream_index=0',
      ],
    ]);

    return false;
  }

  if (measurementA.peaks.length !== measurementB.peaks.length) {
    return false;
  }

  const aPeakIds = measurementA.peaks
    .map((peak) => peak.id)
    .sort((idA, idB) => idA - idB)
    .toString();
  const bPeakIds = measurementB.peaks
    .map((peak) => peak.id)
    .sort((idA, idB) => idA - idB)
    .toString();

  return aPeakIds === bPeakIds;
};

export const getBaselineValueByTime = (data: {
  timeInMinutes: number;
  baseline: number[];
  mps: number;
}): number => {
  const { baseline, mps, timeInMinutes } = data;
  const index = Math.trunc(timeInMinutes * SECONDS_IN_MINUTE * mps);
  return baseline[index] ?? 0;
};

export const numberFormatterWith2FractionDigits = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  useGrouping: false,
});

const numberFormatterWith3FractionDigits = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 3,
  useGrouping: false,
});

export const getHoverMessages = memoize(
  (yValues: Array<Nullable<number>>, dx: number, x0: number, yLabel: string): string[] =>
    yValues.map(
      (mauValue, index) =>
        `${numberFormatterWith3FractionDigits.format(x0 + index * dx)} min, ${
          mauValue != null ? numberFormatterWith2FractionDigits.format(mauValue) : 'unknown'
        } ${yLabel}`,
    ),
  { max: 6 },
);

/**
 * Formula: https://calcstroy.ru/geometriya/kalkulyator-linejnoj-interpolyatsii
 */
export const findYBetweenTwoPoints = (data: {
  x0: number;
  y0: number;
  x1: number;
  y1: number;
  xBetween: number;
}): number => {
  const { x0, x1, xBetween, y0, y1 } = data;
  return y0 + ((xBetween - x0) / (x1 - x0)) * (y1 - y0);
};

export const getMeasurementPart = memoize(
  (
    measurementData: Nullable<number>[],
    dx: number,
    mps: number,
    x0: number,
    xStart: number,
    xEnd: number,
  ) => {
    const measurementPart: Array<[x: number, y: number]> = [];

    for (let i = 0; i < measurementData.length; i++) {
      const x = dx * (i + Math.round(x0 * SECONDS_IN_MINUTE * mps));

      if (x > xEnd) {
        break;
      }

      if (x >= xStart) {
        const y = measurementData[i];
        if (y == null) {
          throw new Error('Y can not be null');
        }
        measurementPart.push([x, y]);
      }
    }

    return measurementPart;
  },
  { max: 30 },
);

export const addStyleSheetForPeakHighlighting = _.once(() => {
  const css = window.document.styleSheets[0];
  css.insertRule(
    `
    .chromatogram-chart .shape-group [id$="-background"]:hover {
      opacity: 1 !important;
    }
    `,
    css.cssRules.length,
  );
});
