<template>
  <div ref="inputVials" class="input-vials">
    <Tippy
      ref="tippy"
      :isInteractive="true"
      trigger="focus"
      :isHideOnClickInside="false"
      :isHideOnClickOutside="false"
      :placement="placement"
      :duration="[200, 67]"
      class="input-vials__tippy"
      :triggerTarget="triggerTarget"
      :flip="false"
      :boundary="triggerTarget"
      :popperOptions="popperOptions"
      @show="handleShowPopup"
      @hide="handleHidePopup"
    >
      <template #trigger>
        <slot :vial="_privateVial" :setVial="setVial" :handleBlur="handleBlur">
          <div v-if="title" class="input-vials__title">{{ title }}</div>
          <input
            v-model="_privateVial"
            v-test="{ id: 'input' }"
            placeholder="Vial"
            @blur="handleBlur"
          />
        </slot>
      </template>
      <template #default="{ hide: hideCallback, isShow }">
        <WithDelay v-if="isShow" delay="500">
          <GlobalEvents @mouseup="hide(hideCallback)" @keypress.enter="blur" @keydown.esc="blur" />
        </WithDelay>
        <TrayVials
          :checkedVial="_privateVial"
          :config="trayConfig"
          class="input-vials__tray"
          @model="handleTrayModel"
        />
      </template>
    </Tippy>
  </div>
</template>

<script>
  import Tippy from '../../../../uikitBase/popups/vueTippy/Tippy';
  import TrayVials from '../../vueTrayVials/TrayVials';
  import testDirective from '@/directives/test';
  import WithDelay from '@/hoc/WithDelay';
  import DomHelper from 'utils/DomHelper.ts';
  import NavigatorHelper from 'utils/NavigatorHelper.ts';
  import { model, props } from '@/uikitProject/vials/vueInputVials/shared/config.ts';

  const EVENT_MODEL = 'model';

  export default {
    name: 'DesktopInputVials',

    directives: { test: testDirective },

    components: {
      WithDelay,
      TrayVials,
      Tippy,
    },

    model,

    props,

    data: () => ({
      triggerTarget: null,
      isPopupOpened: false,

      privateVial: '',
    }),

    computed: {
      _privateVial: {
        get() {
          return this.privateVial;
        },
        set(value) {
          this.privateVial = value;

          if (!this.isLazy) {
            this.updateModel(value);
          }
        },
      },

      refInput() {
        const wrapper = this.$refs.inputVials;
        const input = wrapper.querySelector('input');
        if (input) {
          return input;
        }
        throw new Error('The default slot must contain an input element');
      },

      popperOptions() {
        return {
          modifiers: {
            preventOverflow: {
              boundariesElement: 'window',
              enabled: true,
            },
            hide: {
              enabled: true,
              /**
               * Fires when the reference element is out of boundaries, useful to know when to hide the popper
               */
              fn: (e) => {
                if (
                  // isPopupOpened becomes true with little delay for opening a keyboard on touch devices
                  this.isPopupOpened &&
                  !DomHelper.isElementInsideViewport(e.instance.reference)
                ) {
                  this.refInput.blur();
                }
                return e;
              },
            },
          },
        };
      },
    },

    watch: {
      vial: {
        handler(value) {
          if (this.privateVial !== value) {
            this.privateVial = value;
          }
        },
        immediate: true,
      },
    },

    mounted() {
      this.initTriggerTarget();
    },

    methods: {
      setVial(value) {
        this._privateVial = value;
      },

      blur() {
        this.refInput.blur();
      },

      initTriggerTarget() {
        this.triggerTarget = this.refInput;
      },

      async hide(hide) {
        await this.$nextTick();

        const isInputFocused = document.activeElement === this.triggerTarget;
        if (!isInputFocused) {
          hide();
        }
      },

      handleShowPopup() {
        // little delay for opening a keyboard on touch devices
        if (NavigatorHelper.isTouchDevice) {
          setTimeout(() => {
            this.isPopupOpened = true;
          }, 500);
        } else {
          this.isPopupOpened = true;
        }
      },
      handleHidePopup() {
        this.isPopupOpened = false;
      },

      validate() {
        if (!/^[a-z]\d\d?$/i.test(this._privateVial)) {
          this._privateVial = '';
        }
      },

      handleBlur() {
        this.validate();

        if (this.isLazy) {
          this.updateModel();
        }
      },

      updateModel(value = this._privateVial) {
        this.$emit(EVENT_MODEL, value && value.toUpperCase());
      },

      handleTrayModel(vial) {
        this._privateVial = vial;

        this.handleBlur();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .input-vials {
    display: flex;

    &__tippy {
      width: 100%;
    }

    &__title {
      color: $color-text-third;
    }

    &__tray {
      padding: 4px 7px 7px 5px;
      border-radius: $border-radius__md;
    }
  }
</style>
