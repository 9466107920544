<template>
  <!--eslint-disable vue/no-mutating-props-->
  <div style="padding: 32px 0 0 32px">
    <ModalComponent :scrollable="true" width="500" height="auto" name="preparation-log">
      <div class="modal-content">
        <i v-for="(item, index) in preparationLog" :key="index">
          {{ index }}. {{ new Date(item.time).toLocaleTimeString() }} - {{ item.message }}
        </i>
      </div>
    </ModalComponent>

    <SendReportModal
      v-if="canCreateReport"
      :sample="sample"
      :injection="injection"
      :method="method"
      :column="column"
      :owner="owner"
      :isShared="isShared"
      :sampleToken="sampleToken"
    />

    <CreateApplicationModal
      v-if="isAdmin && sampleSocket"
      :injection="injection"
      :method="method"
      :column="column"
      :token="sample ? sample.token_hex : null"
      :sampleSocket="sampleSocket"
    />

    <div class="buttons--float-right">
      <template v-if="!isShared">
        <PopupHelper
          v-if="injection.state === 'PRE' || injection.state === 'RUN'"
          text="Stops current Preparation or Injection step."
        >
          <button class="button--square-image" @click="stop">
            <i class="material-icons material-icon--18">stop</i>
            Stop
          </button>
        </PopupHelper>
        <PopupHelper
          v-else-if="injection.state === 'FIN'"
          text="Aborts any Post-Injection processes."
        >
          <button class="button--square-image" @click="stop">
            <i class="material-icons material-icon--18">stop</i>
            Abort
          </button>
        </PopupHelper>

        <button v-if="injection.archived" class="button--square-image" @click="restore">
          <i class="material-icons material-icon--18">restore</i>
          Restore
        </button>
      </template>

      <button v-if="isEditMode" class="button--square-image" @click="finishEditing">
        <i class="material-icons material-icon--18">done</i>
        Finish <br />
        editing
      </button>

      <MoreMenuComponent
        v-show="
          !injection.archived && !['PRE', 'RUN', 'FIN'].includes(injection.state) && !isEditMode
        "
      >
        <button v-if="injection.can_edit" class="button--square-image" @click="startEditing">
          <i class="material-icons material-icon--18">edit</i>
          Edit
        </button>
        <button
          v-if="
            canCreateReport &&
            (injection.state === 'WOR' || injection.state === 'REA' || injection.state === 'FAI')
          "
          class="button--square-image"
          @click="sendPdf"
        >
          <i class="material-icons material-icon--18">picture_as_pdf</i>
          Report
        </button>
        <button
          v-if="injection.can_archive && injection.state !== 'R'"
          class="button--square-image"
          @click="archive"
        >
          <i class="material-icons material-icon--18">delete</i>
          Archive
        </button>
        <button
          v-if="preparationLog != null"
          class="button--square-image"
          @click="viewPreparationLog"
        >
          <i class="material-icons material-icon--18">assignment</i>
          Log
        </button>

        <button
          v-if="isAdmin"
          class="button--square-image injection-header__btn-create-application"
          @click="createApplication"
        >
          <i class="material-icons material-icon--18">computer</i>
          Create <br />
          application
        </button>
      </MoreMenuComponent>
    </div>

    <h2 style="display: block; padding: 0; margin: 0 0 8px 0; color: black; white-space: nowrap">
      <i class="submain">show_chart</i>
      {{ injection.name }}
      <loading-component
        v-if="loading"
        :label="null"
        style="display: inline-block; width: 16px; margin: 0"
      />
      <progress-component
        v-if="injection != null && injection.state === 'RUN'"
        :label="injection.progress_time_str"
        :progress="injection.progress"
        style="display: inline-block; vertical-align: bottom; margin-left: 32px; margin-bottom: 4px"
      />
      <!--<transition name="fade">-->
      <!--Unused-->
      <!--<span v-if="updatedLabel" style="font-size: 13px; color: #12985f; padding-left: 16px;">-->
      <!--Updated-->
      <!--</span>-->
      <!--</transition>-->
    </h2>

    <div style="min-height: 24px; vertical-align: bottom; display: block">
      <span v-if="label" style="display: inline-block; color: black" class="mr-3">
        {{ label }}
      </span>
      <span v-else style="display: inline-block; color: black" class="mr-3">
        Sample injection
      </span>

      <span class="span-date mr-3" v-html="new Date(injection.date_started).toLocaleDateString()" />

      <span class="span-date mr-3" v-html="formatTime(injection.date_started)" />

      <span
        v-if="
          (injection.state !== 'REA' && injection.error_str != null) ||
          injection.error_str === 'Stopped by user' ||
          injection.error_str === 'Cancelled by server'
        "
        class="mr-3 text-red"
        style="white-space: nowrap"
      >
        <i class="material-icons material-icon--16">error</i>
        {{ injection.error_str }}
      </span>
      <StatesForEntity
        v-else
        class="mr-3"
        :state="injection.state"
        data-test-id="injection-status"
      />

      <StateArchived v-if="injection.archived" />
    </div>

    <model-textarea-component
      ref="description"
      v-model="injection.description"
      :isEditMode="isEditMode"
      :canEdit="!isShared && injection.can_edit && editable"
      :label="'Description'"
      style="margin: 16px 32px 0 0"
      @click="startEditing"
    />
  </div>
</template>

<script>
  import StatesForEntity from '@/uikitProject/states/packs/StatesForEntity.vue';
  import StateArchived from '@/uikitProject/states/StateArchived.vue';
  import MoreMenuComponent from 'components/element/MoreMenuComponent.vue';
  import ProgressComponent from 'components/element/ProgressComponent.vue';
  import ModelTextareaComponent from '@/components/element/ModelTextareaComponent.vue';
  import LoadingComponent from '@/components/element/LoadingComponent';
  import 'assets/css/component/modal.scss';
  import ModalComponent from '@/components/element/ModalComponent.vue';
  import SendReportModal from 'components/block/modal/SendReportModal';
  import { AuthService } from '@/services/auth.service';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper.vue';
  import { isInjectionInProgress } from '@/utils/injectionHelpers';

  export default {
    name: 'InjectionHeaderComponent',

    components: {
      PopupHelper,
      CreateApplicationModal: () => import('components/block/modal/CreateApplicationModal'),
      SendReportModal,
      ModalComponent,
      ModelTextareaComponent,
      ProgressComponent,
      MoreMenuComponent,
      StatesForEntity,
      StateArchived,
      LoadingComponent,
    },

    props: {
      isShared: {
        type: Boolean,
      },
      editable: {
        type: Boolean,
        default: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      sample: Object,
      injection: Object,
      method: Object,
      column: Object,
      measurementsSelectedIds: Array,
      injectionSocket: Object,
      sampleSocket: {
        type: Object,
      },
      label: String,
      owner: {
        type: Object,
      },
      sampleToken: {
        type: String,
      },
    },

    data() {
      return {
        updatedLabel: false,

        isAdmin: AuthService.userData()?.is_superuser,

        isEditMode: false,
      };
    },

    computed: {
      canCreateReport() {
        return Boolean(this.sample && this.injection && this.measurementsSelectedIds);
      },
      preparationLog() {
        try {
          return this.injection.preparation_log && JSON.parse(this.injection.preparation_log);
        } catch (e) {
          console.error(`Can't parse preparation log!`);
          return null;
        }
      },
    },

    watch: {
      injection() {
        this.finishEditing();
      },
    },

    methods: {
      startEditing() {
        if (!this.isShared && this.injection != null && !isInjectionInProgress(this.injection)) {
          this.isEditMode = true;
        }
      },
      finishEditing() {
        if (this.isEditMode) {
          this.$refs.description?.save();
          this.showNotificationIfRpcError(() =>
            this.injectionSocket.update({ description: this.injection.description }),
          );
          this.isEditMode = false;
        }
      },
      viewPreparationLog() {
        this.$modal.show('preparation-log');
      },
      sendPdf() {
        this.$modal.show('sendReport');
      },
      createApplication() {
        this.$modal.show('createApplication');
      },

      stop() {
        return this.showNotificationIfRpcError(() => this.injectionSocket.stop());
      },

      restore() {
        this.showNotificationIfRpcError(() => this.injectionSocket.restore());
      },
      archive() {
        this.showNotificationIfRpcError(() => this.injectionSocket.archive());
      },
    },
  };
</script>

<style lang="scss" scoped>
  .injection-header {
    &__btn-create-application {
      font-size: $size-xxxs;
    }
  }

  .button--square-image {
    @media (max-width: $screen-xxs-max) {
      margin: 0;
    }
  }
</style>
