import _ from 'lodash';
import { Callback, Dict } from '@/types/utility';
import SocketCentrifugeBase, { SubscriptionError } from '@/api/sockets/SocketCentrifugeBase';

export enum CalibrationSocketEvents {
  CALIBRATION = 'calibration',
  CALIBRATION_REFRESH = 'calibration.refresh',
  // Internal app events. Not emitted by socket
  DEVICE = 'calibration.device',
  METHOD = 'calibration.method',
  COLUMN = 'calibration.column',
  INJECTIONS = 'calibration.injections',
}

enum CalibrationSocketRPC {
  GET = 'calibration.get',
  ARCHIVE = 'calibration.archive',
  RESTORE = 'calibration.restore',
  UPDATE = 'calibration.update',
  GET_INJECTIONS_WITH_DATA = 'calibration.getInjectionsWithData',
  START_NEW_INJECTION = 'calibration.startNewInjection',
}

export default class CalibrationSocket extends SocketCentrifugeBase<
  CalibrationSocketEvents,
  CalibrationSocketRPC
> {
  private static connections: Dict<CalibrationSocket> = {};

  protected listeners: Record<CalibrationSocketEvents, Callback[]> = {
    [CalibrationSocketEvents.CALIBRATION]: [],
    [CalibrationSocketEvents.CALIBRATION_REFRESH]: [],
    [CalibrationSocketEvents.DEVICE]: [],
    [CalibrationSocketEvents.METHOD]: [],
    [CalibrationSocketEvents.COLUMN]: [],
    [CalibrationSocketEvents.INJECTIONS]: [],
  };

  private constructor(
    public id: number,
    onConnect?: Callback<[SocketCentrifugeBase<CalibrationSocketEvents, CalibrationSocketRPC>]>,
    onError?: Callback<[SubscriptionError]>,
  ) {
    super(`personal:calibration-${id}`, {
      handleEvents: ({ data }) => {
        switch (data.event) {
          case CalibrationSocketEvents.CALIBRATION: {
            if (_.has(data, 'calibration')) {
              this.callListeners(CalibrationSocketEvents.CALIBRATION, data.calibration);
            }
            if (_.has(data, 'device')) {
              this.callListeners(CalibrationSocketEvents.DEVICE, data.device);
            }
            if (_.has(data, 'method')) {
              this.callListeners(CalibrationSocketEvents.METHOD, data.method);
            }
            if (_.has(data, 'column')) {
              this.callListeners(CalibrationSocketEvents.COLUMN, data.column);
            }
            if (_.has(data, 'injections')) {
              this.callListeners(CalibrationSocketEvents.INJECTIONS, data.injections);
            }
            break;
          }
          case CalibrationSocketEvents.CALIBRATION_REFRESH: {
            this.callListeners(CalibrationSocketEvents.CALIBRATION_REFRESH, data.calibration);
            break;
          }
        }
      },
      onConnect,
      onError,
    });

    this.dataAddedToEveryRequest = { calibration_id: this.id };
  }

  public static start(
    id: number,
    onConnect?: Callback<[SocketCentrifugeBase<CalibrationSocketEvents, CalibrationSocketRPC>]>,
    onError?: Callback<[SubscriptionError]>,
  ) {
    const connection = CalibrationSocket.connections[id];
    if (connection) {
      connection.addSession();
      onConnect?.(connection);
      return connection;
    }

    CalibrationSocket.connections[id] = new CalibrationSocket(id, onConnect, onError);
    return CalibrationSocket.connections[id];
  }

  close(listenersGroupId: string): boolean {
    const isClosed = super.close(listenersGroupId);

    if (isClosed) {
      delete CalibrationSocket.connections[this.id];
    }

    return isClosed;
  }

  get() {
    return this.sendNamedRPC(CalibrationSocketRPC.GET);
  }

  getInjectionsWithData() {
    return this.sendNamedRPC(CalibrationSocketRPC.GET_INJECTIONS_WITH_DATA);
  }

  archive() {
    return this.sendNamedRPC(CalibrationSocketRPC.ARCHIVE);
  }

  restore() {
    return this.sendNamedRPC(CalibrationSocketRPC.RESTORE);
  }

  update(calibration) {
    return this.sendNamedRPC(CalibrationSocketRPC.UPDATE, { calibration });
  }

  injectionNew(description, deviceId, vial, amount) {
    return this.sendNamedRPC(CalibrationSocketRPC.START_NEW_INJECTION, {
      description,
      device_id: deviceId,
      vial,
      amount,
    });
  }
}
