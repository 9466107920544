<template>
  <FormCreation v-model="dmc" :isRequiredDevice="false" :isValid="isValid" @submit="createSample">
    <DecoratorInput iconSize="md" iconTitle="opacity" label="Name" style="margin-bottom: 16px">
      <input
        ref="name"
        v-model="name"
        autofocus="autofocus"
        class="header"
        type="text"
        data-test-id="input-name"
        @focus="$event.target.select()"
      />
    </DecoratorInput>

    <DecoratorInput
      iconSize="sm"
      iconTitle="notes"
      label="Description"
      :isOptional="true"
      style="margin-bottom: 16px"
    >
      <textarea
        v-model="description"
        class="field"
        rows="2"
        data-test-id="input-description"
      ></textarea>
    </DecoratorInput>

    <DecoratorInput
      iconSize="sm"
      iconShiftTopPx="23"
      iconTitle="star"
      label="Expected compounds"
      :isOptional="true"
    >
      <SelectorMultipleCompounds v-model="compounds" />
    </DecoratorInput>
  </FormCreation>
</template>

<script>
  import 'assets/css/component/new-sam-cal-seq.scss';
  import { SampleAPI } from 'api/sample';
  import DecoratorInput from '@/uikitProject/decorators/DecoratorInput';
  import FormCreation from 'components/blocks/forms/Creation';
  import SelectorMultipleCompounds from '@/uikitProject/compounds/vueSelectorMultipleCompounds/SelectorMultipleCompounds';

  const EVENT_CREATE = 'create';

  export default {
    name: 'SampleNewComponent',

    components: {
      SelectorMultipleCompounds,
      FormCreation,
      DecoratorInput,
    },

    data: () => {
      const defaultName = 'Sample name';

      return {
        defaultName,
        name: defaultName,
        description: '',
        compounds: [],
        compoundsTest: [],

        dmc: {
          device: null,
          method: null,
          column: null,
        },
      };
    },

    computed: {
      isValid() {
        return this.name.length > 0;
      },
    },

    mounted() {
      this.$refs.name.focus();
    },

    methods: {
      async createSample() {
        if (!this.isValid) {
          return;
        }

        const data = {
          name: this.name !== this.defaultName ? this.name : null,
          description: this.description,
          compounds: this.compounds.map((compound) => compound.name).join('; '),
        };

        if (this.dmc.device != null) {
          data.device_id = this.dmc.device.id;
          if (this.dmc.method) data.method_id = this.dmc.method.id;
          if (this.dmc.column) data.column_id = this.dmc.column.id;
        }

        await SampleAPI.post(
          data,
          (r) => {
            const { id } = r;
            this.$router.push({ name: 'sample', params: { id } });
            this.$emit(EVENT_CREATE);
          },
          (r, c) => {
            if (r) {
              this.message = r.detail;
            } else {
              this.message = `Error ${c}`;
            }
          },
        );
      },
    },
  };
</script>
