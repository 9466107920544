<template>
  <div class="select-measurement">
    <div class="select-measurement__label">Select measurement</div>

    <LoadingComponent v-if="isLoading" />
    <div v-else-if="isShowForm" class="select-measurement__form">
      <div class="select-measurement__label">Select sample</div>
      <SelectSample v-model="form.sample" class="select-measurement__select" />

      <div class="select-measurement__label">Select injection</div>
      <SelectInjection
        v-model="form.injection"
        :sampleId="form.sample && form.sample.id"
        class="select-measurement__select"
      />

      <div class="select-measurement__label">Select measurement</div>
      <SelectMeasurement
        v-model="form.measurement"
        :injectionId="form.injection && form.injection.id"
        class="select-measurement__select"
      />

      <div class="select-measurement__actions">
        <Btn class="select-measurement__btn-cancel" @click="hideSelectForm">Cancel</Btn>
        <Btn :disabled="!canSave" type="primary" @click="save">Save</Btn>
      </div>
    </div>
    <div v-else>
      <template v-if="selectedMeasurement">
        <RouterLink :to="linkToInjection" class="select-measurement__link-to-measurement">
          <IconCalibration v-if="selectedMeasurement.injection.sample.isCalibration" />
          <IconSample v-else />
          {{ selectedMeasurement.injection.sample.name }} #{{
            selectedMeasurement.injection.name
          }}
          : {{ selectedMeasurement.name }}
        </RouterLink>
        <Btn @click="showSelectForm">Select another channel</Btn>
      </template>
      <Btn v-else @click="showSelectForm">Select channel</Btn>
    </div>

    <div v-if="error" class="select-measurement__error">
      {{ error }}
    </div>
  </div>
</template>

<script>
  import { apiMeasurements } from '@/api/graphql/cloud/measurements';
  import IconSample from '@/uikitProject/icons/IconSample.vue';
  import IconCalibration from '@/uikitProject/icons/IconCalibration.vue';
  import LoadingComponent from '@/components/element/LoadingComponent.vue';
  import Btn from '@/uikitBase/btns/Btn.vue';
  import SelectSample from '@/uikitProject/samples/SelectSample.vue';
  import SelectInjection from '@/uikitProject/injections/SelectInjection.vue';
  import SelectMeasurement from '@/uikitProject/injections/measurements/vueSelectMeasurementForm/private/SelectMeasurement.vue';

  const EVENT_MODEL = 'model';

  export default {
    name: 'SelectMeasurementForm',

    components: {
      SelectMeasurement,
      SelectInjection,
      SelectSample,
      Btn,
      LoadingComponent,
      IconCalibration,
      IconSample,
    },

    model: {
      prop: 'measurementUlid',
      event: EVENT_MODEL,
    },

    props: {
      measurementUlid: {
        type: String,
      },
      measurementId: {
        type: Number,
      },
      error: {
        type: String,
      },
    },

    data: () => ({
      selectedMeasurement: null,
      isLoading: true,
      isShowForm: false,

      form: {
        sample: null,
        injection: null,
        measurement: null,
      },
    }),

    computed: {
      canSave() {
        return this.form.sample && this.form.injection && this.form.measurement;
      },
      linkToInjection() {
        return `/${
          this.selectedMeasurement.injection.sample.isCalibration ? 'calibrations' : 'samples'
        }/${this.selectedMeasurement.injection.sample.id}/injection/${
          this.selectedMeasurement.injection.id
        }`;
      },
    },

    watch: {
      measurementUlid: {
        handler() {
          this.initMeasurement();
        },
        immediate: true,
      },
      'form.sample'() {
        this.form.injection = null;
        this.form.measurement = null;
      },
      'form.injection'() {
        this.form.measurement = null;
      },
    },

    methods: {
      async initMeasurement() {
        if (this.measurementUlid == null) {
          this.isLoading = false;
          return;
        }

        try {
          this.isLoading = true;
          this.selectedMeasurement = await apiMeasurements.getMeasurementShort(this.measurementId);
        } finally {
          this.isLoading = false;
        }
      },

      showSelectForm() {
        this.isShowForm = true;
      },
      hideSelectForm() {
        this.isShowForm = false;
        this.resetForm();
      },

      resetForm() {
        this.form = {
          sample: null,
          injection: null,
          measurement: null,
        };
      },

      save() {
        this.$emit(EVENT_MODEL, {
          ulid: this.form.measurement.ulid,
          id: this.form.measurement.id,
        });
        this.hideSelectForm();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .select-measurement {
    &__form {
      padding-left: 20px;
    }

    &__select {
      margin-bottom: 10px;
    }

    &__actions {
      margin-top: 10px;
    }

    &__btn-cancel {
      margin-right: 10px;
    }

    &__label {
      font-size: 13px;
      color: $color-text-third;
      margin-bottom: 3px;
    }

    &__link-to-measurement {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &:hover {
        text-decoration: none;
      }
    }

    &__link-icon:hover {
      text-decoration: none;
    }

    &__error {
      color: $color-text-danger;
      margin-top: 5px;
    }
  }
</style>
