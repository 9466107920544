import { Callback, Dict } from '@/types/utility';
import { ValuesType } from 'utility-types';
import SocketCentrifugeBase from '@/api/sockets/SocketCentrifugeBase';

export enum EventTypes {
  ON_USE_COLUMN = 'use:column',
  ON_USE_APPLICATION = 'use:application',
  ON_USE_STANDARD = 'use:standard',
  ON_USE_SOLVENT = 'use:solvent',
}

export const ResponseActions = {
  USE: 'use',
  DISMISS: 'dismiss',
  ADD_TO_SEQUENCE: 'add_to_sequence',
  ADD_TO_LAST_SEQUENCE: 'add_to_last_sequence',
  ADD_TO_CALIBRATION: 'add_to_calibration',
  ADD_TO_NEW_CALIBRATION: 'add_to_new_calibration',
} as const;

export type ResponseActionNames = ValuesType<typeof ResponseActions>;

type Response = {
  id: number;
  action: ResponseActionNames;
  data?: Dict;
};

export type SocketResponseNotifyNew = {
  type: EventTypes;
  id: number;
  data: Dict;
};

export type SocketResponseActionTaken = {
  id: number;
};

export type SocketResponseRedirect = {
  page: string;
  params: {
    id: number;
  };
};

export type SocketResponseError = {
  code: number | string;
};

export enum NotificationSocketEvents {
  NEW = 'notify.new',
  REDIRECT = 'redirect',
  ERROR = 'error',
  ACTION_TAKEN = 'notify.action_taken',
  // Internal app events. Not emitted by socket
  USE_COLUMN = 'use:column',
  USE_APPLICATION = 'use:application',
  USE_STANDARD = 'use:standard',
  USE_SOLVENT = 'use:solvent',
}

enum NotificationSocketRPC {
  ACTION = 'notify.action',
}

export default class NotificationSocket extends SocketCentrifugeBase<
  NotificationSocketEvents,
  NotificationSocketRPC
> {
  private static connections: Dict<NotificationSocket> = {};

  protected listeners: Record<NotificationSocketEvents, Callback[]> = {
    [NotificationSocketEvents.NEW]: [],
    [NotificationSocketEvents.REDIRECT]: [],
    [NotificationSocketEvents.ERROR]: [],
    [NotificationSocketEvents.ACTION_TAKEN]: [],
    [NotificationSocketEvents.USE_COLUMN]: [],
    [NotificationSocketEvents.USE_APPLICATION]: [],
    [NotificationSocketEvents.USE_SOLVENT]: [],
    [NotificationSocketEvents.USE_STANDARD]: [],
  };

  private constructor(
    private email: string,
    onConnect?: Callback<[SocketCentrifugeBase<NotificationSocketEvents, NotificationSocketRPC>]>,
  ) {
    super(`personal:notify#${email}`, {
      handleEvents: ({ data }) => {
        switch (data.event) {
          case NotificationSocketEvents.NEW: {
            this.callListeners(NotificationSocketEvents.NEW, data);

            switch (data.type) {
              case NotificationSocketEvents.USE_COLUMN:
                return this.callListeners(NotificationSocketEvents.USE_COLUMN, data);
              case NotificationSocketEvents.USE_APPLICATION:
                return this.callListeners(NotificationSocketEvents.USE_APPLICATION, data);
              case NotificationSocketEvents.USE_STANDARD:
                return this.callListeners(NotificationSocketEvents.USE_STANDARD, data);
              case NotificationSocketEvents.USE_SOLVENT:
                return this.callListeners(NotificationSocketEvents.USE_SOLVENT, data);
            }
            break;
          }
          case NotificationSocketEvents.ACTION_TAKEN: {
            this.callListeners(NotificationSocketEvents.ACTION_TAKEN, data);
            break;
          }
          case NotificationSocketEvents.REDIRECT: {
            this.callListeners(NotificationSocketEvents.REDIRECT, data);
            break;
          }
          case NotificationSocketEvents.ERROR: {
            this.callListeners(NotificationSocketEvents.ERROR, data);
            break;
          }
        }
      },
      onConnect,
    });
  }

  public static start(
    email: string,
    onConnect?: Callback<[SocketCentrifugeBase<NotificationSocketEvents, NotificationSocketRPC>]>,
  ) {
    const connection = NotificationSocket.connections[email];
    if (connection) {
      connection.addSession();
      onConnect?.(connection);
      return connection;
    }

    NotificationSocket.connections[email] = new NotificationSocket(email, onConnect);
    return NotificationSocket.connections[email];
  }

  public close(listenersGroupId?: string): boolean {
    const isClosed = super.close(listenersGroupId);

    if (isClosed) {
      delete NotificationSocket.connections[this.email];
    }

    return isClosed;
  }

  public sendResponse(data: Response) {
    this.sendNamedRPC(NotificationSocketRPC.ACTION, data);
  }
}
