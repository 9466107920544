<template>
  <div
    v-if="calibration != null"
    :key="calibration.id"
    class="paper-block-container paper-block-container--main"
  >
    <div class="paper__top">
      <div class="paper__header">
        <div>
          <InputTitle
            id="block-name"
            v-model="calibration.name"
            iconName="star"
            :maxLength="200"
            @save="saveName"
          />

          <div class="pt-1">
            <span class="mr-3">Calibration</span>

            <span
              class="span-date mr-3"
              v-html="new Date(calibration.created).toLocaleDateString()"
            />

            <StateArchived v-if="calibration.archived" />
          </div>
        </div>
        <div>
          <button v-if="calibration.archived" class="button--square-image" @click="restore">
            <i class="material-icons material-icon--18">restore</i>
            Restore
          </button>
          <button v-else-if="isEditMode" class="button--square-image" @click="finishEditing">
            <i class="material-icons material-icon--18">done</i>
            Finish <br />
            editing
          </button>

          <MoreMenuComponent v-show="!calibration.archived && !isEditMode">
            <button class="button--square-image" @click="startEditing">
              <i class="material-icons material-icon--18">edit</i>
              Edit
            </button>
            <div v-if="isPaused" v-tippy content="Calibration is paused! Stop it to archive!">
              <button class="button--square-image" data-test-id="btn-archive" disabled>
                <i class="material-icons material-icon--18">delete</i>
                Archive
              </button>
            </div>
            <button
              v-else-if="calibration.can_archive && !isInProgress"
              class="button--square-image"
              @click="archive"
            >
              <i class="material-icons material-icon--18">delete</i>
              Archive
            </button>
          </MoreMenuComponent>
        </div>
      </div>
      <div class="paper__content--margin-32">
        <ModelTextareaComponent
          ref="description"
          v-model="calibration.description"
          :canEdit="calibration.can_edit"
          :isEditMode="isEditMode"
          :label="'Description'"
          style="margin: 16px 0 0"
          @click="startEditing"
          @update:value="saveDescription"
        />

        <DeviceMethodColumnComponent
          ref="dmc"
          :editable="Object.keys(injections).length === 0"
          :column="column"
          :device="device"
          :method="method"
          :isEditMode="isEditMode"
          style="margin: 16px 0 0"
          @startEditing="startEditing"
        />
      </div>
    </div>

    <!--I change v-show to v-if, because this component conflicts with the Chart component on the calibration page.
      v-show doesn't work in case of peak highlighting -->
    <div v-if="injectionsAll">
      <CalibrationInjectionsListComponent
        :archived="showArchivedInjections"
        :calibration="calibration"
        :class="{ 'block--shadow-in': !empty && calibration.k }"
        :injectionID="injectionID"
        :injections="injections"
        :injectionHighlightedId.sync="injectionHighlightedId"
        :allowNew="calibration.sequence_id == null"
        :isInProgress="isInProgress"
        @update:archived="showArchivedInjections = $event"
        @update:injectionID="showInjection($event)"
      />
      <div v-if="!empty && calibration.k" class="p-8">
        <h4 style="margin: 0 0 16px 0">Summary</h4>
        <span v-if="calibration.k"
          ><span class="text-gray">Response factor &nbsp; </span>1 mAU×min →
          {{ calibration.k.toFixed(6) }} {{ calibration.unit }}</span
        >
        <br />
        <span v-if="calibration.r2">
          <span class="text-gray">R-Squared </span>{{ calibration.r2.toFixed(6) }}
        </span>
        <br />
        <span v-if="calibration.r2">
          <span class="text-gray">Retention time </span>
          {{ calibration.time && calibration.time.toFixed(3) }} min ±{{
            calibration.time_spread && (calibration.time_spread * 100).toFixed(0)
          }}%
        </span>
        <div style="height: fit-content; display: flex; padding-top: 16px">
          <div
            style="width: 50%; height: 300px; float: left; position: relative; padding-right: 8px"
          >
            <CalibrationsChartComponent
              :injectionHighlightedId.sync="injectionHighlightedId"
              :calibration="calibration"
              :injections="injections"
            />
          </div>
          <div
            style="width: 50%; height: 300px; float: left; position: relative; padding-left: 8px"
          >
            <ChromatogramsComponent
              :injections="injectionsWithData"
              :injectionHighlightedId="injectionHighlightedId"
              style="height: 100%; width: 100%"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-show="!injectionsAll" style="padding-bottom: 32px">
      <InjectionsNavBarComponent
        :archived="showArchivedInjections"
        :injectionID="injectionID"
        :injections="injections"
        :allowNew="calibration.sequence_id == null"
        :isInProgress="isInProgress"
        @update:archived="showArchivedInjections = $event"
        @update:injectionID="showInjection($event)"
      />

      <CalibrationInjectionComponent
        v-if="injectionData"
        v-show="!injectionNew"
        ref="injection"
        :calibration="calibration"
        :injectionID="injectionID"
        :injection-data="injectionData"
        @selectPeak="updateInjectionPeak"
      />
      <not-available-component v-else-if="!injectionNew">No data</not-available-component>

      <template v-if="injectionNew">
        <InjectionNewComponent
          v-if="calibration.can_edit"
          :injection-name="(calibration.injections_counter + 1).toString()"
          fieldAmount
          :sample-socket="calibrationSocket"
          :default-vial="defaultVial"
          :default-device="device"
          :default-method="method"
          :default-column="column"
          :defaultAmount="defaultAmount"
          :readonlyDMC="true"
          :isCalibration="true"
          :hasInjections="hasInjections"
          :doResetOnDefaultValuesUpdate="true"
          @injectionCreate="handleInjectionCreation"
        />
        <not-available-component v-else class="pt-8">
          Finished or rejected calibrations can not run new injections.
        </not-available-component>
      </template>
    </div>
  </div>
  <error-component v-else-if="errorCode != null" :code="errorCode" class="mt-16" />
  <loading-component v-else />
</template>

<script>
  import 'assets/css/base/table.scss';
  import _ from 'lodash';

  import StateArchived from '@/uikitProject/states/StateArchived';
  import CalibrationInjectionComponent from 'components/block/CalibrationInjectionComponent';
  import CalibrationInjectionsListComponent from 'components/block/CalibrationInjectionsListComponent';
  import CalibrationSocket, { CalibrationSocketEvents } from 'api/sockets/CalibrationSocket';
  import CalibrationsChartComponent from 'components/element/chart/CalibrationsChartComponent';
  import ChromatogramsComponent from 'components/element/chart/ChromatogramsComponent';
  import DeviceMethodColumnComponent from 'components/element/DeviceMethodColumnComponent';
  import ErrorComponent from 'components/element/ErrorComponent';
  import InjectionNewComponent from 'components/block/InjectionNewComponent';
  import InjectionsNavBarComponent from 'components/block/InjectionsNavBarComponent';
  import LoadingComponent from 'components/element/LoadingComponent';
  import ModelTextareaComponent from 'components/element/ModelTextareaComponent';
  import MoreMenuComponent from 'components/element/MoreMenuComponent';
  import NotAvailableComponent from 'components/element/NotAvailableComponent';
  import RouterHelper from 'utils/RouterHelper.ts';
  import { isSecondaryView } from '@/components/blocks/layouts/dual/SecondaryView';
  import InputTitle from '@/uikitProject/inputs/inputTitle/InputTitle';
  import { apiCalibrations } from '@/api/graphql/cloud/calibrations';
  import { isSampleInProgress } from '@/utils/sampleHelpers';
  import DataNotFoundError from '@/errors/DataNotFoundError';

  const EVENT_UPDATE_INJECTION_ID = 'update:injectionID';
  const EVENT_UPDATE_CALIBRATION_DATA = 'update:calibrationData';
  const EVENT_ARCHIVE = 'archive';

  export default {
    name: 'CalibrationComponent',

    inject: {
      isSecondaryView,
    },

    components: {
      InputTitle,
      ErrorComponent,
      NotAvailableComponent,
      ChromatogramsComponent,
      CalibrationsChartComponent,
      CalibrationInjectionsListComponent,
      InjectionsNavBarComponent,
      LoadingComponent,
      ModelTextareaComponent,
      MoreMenuComponent,
      DeviceMethodColumnComponent,
      StateArchived,
      InjectionNewComponent,
      CalibrationInjectionComponent,
    },

    props: {
      id: Number,
      injectionID: Number,
      calibrationData: Object,
      isPaused: {
        type: Boolean,
      },
    },

    data() {
      return {
        calibration: null,
        device: null,
        method: null,
        column: null,
        injections: {},
        injectionsWithData: {},
        injectionData: null,
        calibrationSocket: this.createSocket(this.id),
        showArchivedInjections: false,
        errorCode: null,

        injectionHighlightedId: null,

        listenersGroupId: null,

        isEditMode: false,
        wasEditingStated: false,
      };
    },

    computed: {
      injectionsAll: {
        get() {
          return this.injectionID == null;
        },
        set(b) {
          if (b) this.showInjection(null);
        },
      },
      injectionNew: {
        get() {
          return this.injectionID === -1;
        },
        set(b) {
          if (b) this.showInjection(-1);
        },
      },
      empty() {
        if (!this.injections) return true;

        const isFound = Object.keys(this.injections).find(
          (key) => this.archived || !this.injections[key].injection.archived,
        );
        return !isFound;
      },
      defaultVial() {
        if (this.injections == null) return undefined;
        const ids = Object.keys(this.injections);
        if (ids.length <= 0) return undefined;
        return this.injections[ids[ids.length - 1]].injection.vial;
      },

      defaultAmount() {
        return RouterHelper.getQuery(this.isSecondaryView, this.$route).amount;
      },

      isInProgress() {
        return isSampleInProgress(this.calibration);
      },

      hasInjections() {
        return Object.values(this.injections).length > 0;
      },
    },

    watch: {
      id(id) {
        this.errorCode = null;
        this.calibrationSocket = this.createSocket(id);
        this.initData(id);
      },
      injectionID() {
        if (this.injectionID) {
          this.updateInjectionData();
        } else {
          this.refreshInjectionsData();
        }
      },
      injections(injectionsNew) {
        this.updateInjectionData();

        const injectionList = Object.values(injectionsNew);
        if (!injectionList.length && !this.wasEditingStated) {
          this.startEditing();
        } else if (injectionList.length) {
          this.finishEditing();
        }
      },
      calibrationData(newData) {
        if (newData == null) return;
        if (newData.id !== this.id) return;
        if (this.calibration == null || this.calibration.id !== newData.id) {
          this.calibration = newData;
        }
      },
    },

    created() {
      this.initData(this.id);
    },

    beforeDestroy() {
      this.destroySocket();
    },

    methods: {
      async initData(calibrationId) {
        try {
          const data = await apiCalibrations.getCalibrationFull(calibrationId);

          if (_.has(data, 'calibration')) {
            this.setCalibration(data.calibration);
          }
          if (_.has(data, 'device')) {
            this.device = data.device;
          }
          if (_.has(data, 'method')) {
            this.method = data.method;
          }
          if (_.has(data, 'column')) {
            this.column = data.column;
          }
          if (_.has(data, 'injections')) {
            this.injections = data.injections;
            this.refreshInjectionsData();
          }
        } catch (e) {
          if (e instanceof DataNotFoundError) {
            this.resetData();
          }
          throw e;
        }
      },

      resetData() {
        this.setCalibration(undefined);
        this.device = undefined;
        this.method = undefined;
        this.column = undefined;
        this.injections = {};
      },

      setCalibration(c) {
        // eslint-disable-next-line vue/no-mutating-props
        this.calibrationData = c;
        this.calibration = c;
        this.$emit(EVENT_UPDATE_CALIBRATION_DATA, c);
      },

      destroySocket() {
        if (this.calibrationSocket) {
          this.calibrationSocket.close(this.listenersGroupId);
          this.calibrationSocket = null;
        }
      },
      createSocket(id) {
        this.destroySocket();

        const onCalibrationRefresh = (calibrationID) => {
          this.initData(calibrationID);
          this.$refs.injection.resetZoom();
        };
        const onCalibration = (c) => {
          this.setCalibration(c);
        };
        const onDevice = (d) => {
          this.device = d;
        };
        const onMethod = (m) => {
          this.method = m;
        };
        const onColumn = (c) => {
          this.column = c;
        };
        const onInjections = (i) => {
          this.injections = i;
          return this.refreshInjectionsData();
        };

        const calibrationSocket = CalibrationSocket.start(id, null, (e) => {
          this.errorCode = e.code;
        });

        const listenersGroup = calibrationSocket.createEventListenersGroup();
        this.listenersGroupId = listenersGroup.id;

        listenersGroup.addEventListener(
          CalibrationSocketEvents.CALIBRATION_REFRESH,
          onCalibrationRefresh,
        );
        listenersGroup.addEventListener(CalibrationSocketEvents.CALIBRATION, onCalibration);
        listenersGroup.addEventListener(CalibrationSocketEvents.DEVICE, onDevice);
        listenersGroup.addEventListener(CalibrationSocketEvents.METHOD, onMethod);
        listenersGroup.addEventListener(CalibrationSocketEvents.COLUMN, onColumn);
        listenersGroup.addEventListener(CalibrationSocketEvents.INJECTIONS, onInjections);

        return calibrationSocket;
      },

      updateInjectionData() {
        if (this.injections[this.injectionID] != null) {
          this.injectionData = this.injections[this.injectionID];
        }
      },

      saveName(name) {
        this.showNotificationIfRpcError(() => this.calibrationSocket.update({ name }));
      },
      saveDescription() {
        this.showNotificationIfRpcError(() =>
          this.calibrationSocket.update({ description: this.calibration.description }),
        );
      },

      archive() {
        this.showNotificationIfRpcError(() => this.calibrationSocket.archive());

        this.$emit(EVENT_ARCHIVE);
      },
      restore() {
        this.showNotificationIfRpcError(() => this.calibrationSocket.restore());
      },

      showInjection(iid) {
        this.$emit(EVENT_UPDATE_INJECTION_ID, iid);
      },

      updateDMC(device, method, column) {
        const isDeviceChanged = device.id !== this.device.id;
        const isMethodChanged = method.id !== this.method?.id;
        const isColumnChanged = column.id !== this.column?.id;

        if (isDeviceChanged || isMethodChanged || isColumnChanged) {
          this.showNotificationIfRpcError(() =>
            this.calibrationSocket.update({
              device_id: device.id,
              method_id: method.id,
              column_id: column.id,
            }),
          );
        }

        this.finishEditing();
      },

      async handleInjectionCreation(data) {
        this.injections = {
          ...this.injections,
          [data.injection.id]: {
            injection: data.injection,
            device: data.device,
            method: data.method,
            column: data.column,
          },
        };

        await this.$nextTick();
        this.showInjection(data.injection.id);
      },

      startEditing() {
        this.wasEditingStated = true;

        if (this.calibration?.can_edit) {
          this.isEditMode = true;
        }
      },
      finishEditing() {
        if (this.isEditMode) {
          this.$refs.description?.save();

          const { device, method, column } = this.$refs.dmc.dmc;
          if (!device || !method || !column) {
            this.notifyError('Device, method and column should be selected!');
          } else {
            this.isEditMode = false;
            this.updateDMC(device, method, column);
          }
        }
      },

      updateInjectionPeak(injectionPeakInformation) {
        this.injections[injectionPeakInformation.id] = {
          ...this.injections[injectionPeakInformation.id],
          injection: {
            ...this.injections[injectionPeakInformation.id].injection,
            ...injectionPeakInformation,
          },
        };
        this.updateInjectionData();
      },

      async refreshInjectionsData() {
        const injectionsWithData = await this.showNotificationIfRpcError(() =>
          this.calibrationSocket.getInjectionsWithData(),
        );
        this.injectionsWithData = injectionsWithData.injections;
      },
    },
  };
</script>
