<template>
  <div class="search-page">
    <div class="top-line--flex search-page__header">
      <template v-if="archive === true" class="header-text">
        <div>
          <h1>Archive</h1>
          <p>You have 30 days to restore archived objects</p>
        </div>
        <router-link
          class="link-button"
          :to="{ name: routeName, query: { ...currentQ, archive: undefined } }"
        >
          <i class="material-icons material-icon--16 no-text-decoration">keyboard_backspace</i>
          Back to {{ routeName }}
        </router-link>
      </template>
      <template v-else>
        <div class="search-page__title">
          <h1 style="display: inline-block">{{ pageName }}</h1>
        </div>
        <div class="search-page__actions">
          <div class="search-page__navigation">
            <router-link class="link-button" :to="{ name: 'chromatograms compare' }">
              <i class="material-icons material-icon--16" style="margin-bottom: 2px">view_agenda</i>
              View Compare
            </router-link>
            <router-link class="link-button" :to="{ query: { ...currentQ, archive: true } }">
              <i class="material-icons material-icon--16" style="margin-bottom: 2px">delete</i>
              View Archive
            </router-link>
          </div>
          <button
            :class="
              hasItemsInList || isLoading ? 'button--square-image' : 'button--square-image-primary'
            "
            data-test-id="btn-show-create-sample-modal"
            @click="showCreateModal"
          >
            <i class="material-icons material-icon--18">add</i>
            New
          </button>
        </div>
      </template>
    </div>
    <div class="top-line search-page__inputs">
      <div class="search-page__wrapper-input-name">
        <input
          id="query"
          ref="query"
          v-model="query"
          tabindex="0"
          placeholder="Keywords"
          autocomplete="off"
          data-test-id="input-search"
          class="search-page__input-name"
        />
      </div>

      <select
        v-model="deviceId"
        :class="{ 'text-gray': deviceId == null || deviceId === undefined }"
        class="search-page__input-device"
      >
        <option :value="undefined">Any device</option>
        <option v-for="device in devices" :key="device.id" style="color: #000" :value="device.id">
          {{ device.name }}
        </option>
      </select>

      <DateRangePicker
        ref="dateRangeComponent"
        :autoApply="true"
        :dateRange="dateRangeComponent.dateRange"
        :locale-data="dateRangeComponent.locale"
        :maxDate="dateRangeComponent.maxDate"
        :opens="'left'"
        class="search-block--daterange-picker search-page__input-date"
        @update="updateDateRange($event)"
      >
        <div slot="input" style="position: relative">
          <input :value="dateRangeText" placeholder="Any time" style="height: 32px; width: 100%" />
          <button
            v-show="start && end"
            style="position: absolute; right: 0; top: 0; height: 100%; width: 32px; padding: 0"
            @click="resetDateRange()"
            @click.stop
          >
            <i class="material-icons material-icon--16" style="margin: 0 0 1px 1px">close</i>
          </button>
        </div>
      </DateRangePicker>
    </div>

    <LoadingComponent v-if="isLoading" />
    <ErrorComponent v-else-if="error" :code="error" :showRetry="true" @retry="search" />
    <TableEntity
      v-else
      :groups="dataFormatted"
      :hasColumnDate="isResultsRanked"
      :hasColumnDevice="!isAppliedDeviceFilter"
      :hasColumnSequence="type !== 'sequence'"
      :hasColumnState="type === 'sequence'"
      :hasColumnTimeOnMobile="false"
      :hasBtnShowMore="hasNextPage"
      :selectedItemId="selectedItemId"
      :isLoadingMoreItems="isLoadingMoreItems"
      messageNoData="No matching results"
      :canSelectByKeyboard="true"
      @showMore="showMore"
    />

    <!--absolute positioned-->
    <SampleNewModal v-if="entity === 'sample'" @create="search" />
    <CalibrationNewModal v-if="entity === 'calibration'" @create="search" />
    <SequenceNewModal v-if="entity === 'sequence'" @create="search" />
  </div>
</template>

<script>
  import { debounce } from 'lodash';
  import DateRangePicker from 'vue2-daterange-picker';
  import SearchAPI from 'api/search';

  import ErrorComponent from 'components/element/ErrorComponent.vue';

  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
  import PageTitle from '@/mixins/page-title';
  import { DATE_RANGE_LOCALE, DATE_RANGE_RANGES } from 'utils/configs/dateRange';
  import TableEntity from '@/uikitProject/tables/entity/TableEntity';
  import TableEntityHelper from '@/uikitProject/tables/entity/utils/TableEntityHelper.ts';
  import { generateId } from 'utils/generateId.ts';

  import { endOfToday, startOfMonth, startOfDay, subMonths, endOfDay } from 'date-fns';
  import DateHelper from 'utils/DateHelper.ts';
  import RouterHelper from 'utils/RouterHelper.ts';
  import { capitalize } from 'utils/stringHelpers';
  import LoadingComponent from 'components/element/LoadingComponent';
  import SampleNewModal from 'components/block/modal/SampleNewModal';
  import CalibrationNewModal from 'components/block/modal/CalibrationNewModal';
  import SequenceNewModal from 'components/block/modal/SequenceNewModal';
  import { apiDevices } from '@/api/graphql/cloud/devices';

  /**
   * Must be the same on the backend
   */
  const MAX_ITEMS_ON_PAGE = 24;

  const minusOneSecond = (dateString) => {
    const date = new Date(dateString);
    date.setSeconds(date.getSeconds() - 1);
    return date.toJSON();
  };

  export default {
    name: 'SearchPage',

    components: {
      LoadingComponent,
      TableEntity,
      DateRangePicker,
      ErrorComponent,
      SampleNewModal,
      CalibrationNewModal,
      SequenceNewModal,
    },

    mixins: [PageTitle],

    data() {
      const today = new Date();
      const lastMonth = subMonths(today, 1);

      return {
        pageTitle: 'HPLC Cloud: Search',

        type: undefined,
        query: undefined,
        deviceId: undefined,
        start: undefined,
        end: undefined,
        archive: undefined,
        results: null,
        error: null,
        devices: [],

        isLoading: true,
        activeRequestId: null,

        isLoadingMoreItems: false,

        // We need this field, because we can't be sure that the server has data
        didServerReturnNoResults: false,

        dateRangeComponent: {
          anyDate: {
            startDate: null,
            endDate: null,
          },
          dateRange: {
            startDate: startOfMonth(lastMonth),
            endDate: endOfToday(),
          },
          maxDate: endOfToday(),
          opens: 'left', // which way the picker opens, default "center", can be "left"/"right"
          autoApply: true,
          locale: DATE_RANGE_LOCALE,
          ranges: DATE_RANGE_RANGES,
        },
      };
    },

    computed: {
      routeName() {
        return RouterHelper.isDualMode ? this.$route.name.slice(5) : this.$route.name;
      },
      pageName() {
        return capitalize(this.routeName);
      },
      entity() {
        switch (this.routeName) {
          case 'samples':
            return 'sample';
          case 'calibrations':
            return 'calibration';
          case 'sequences':
            return 'sequence';
          default:
            return null;
        }
      },

      isResultsRanked() {
        return Boolean(this.results?.[0]?.rank);
      },
      dataFormatted() {
        if (!this.results) return [];

        /**
         * There is a case when we can't be sure that a next page exists.
         * If MAX_ITEMS_ON_PAGE === this.results. So I emulate the next page.
         */
        const items = this.hasNextPage ? this.results.slice(0, -1) : this.results;

        const data = items.map((item, index) => ({
          ...item,
          device_name: this.devices.find((device) => device.id === item.device_id)?.name,
          index,
        }));

        return this.isResultsRanked
          ? TableEntityHelper.convertToTableFormatRanked(data)
          : TableEntityHelper.convertToTableFormat(data);
      },
      currentQ() {
        let queryText = this.query;
        if (queryText === '') queryText = undefined;

        return {
          query: queryText,
          device_id: this.deviceId,
          start: this.start || undefined,
          end: this.end || undefined,
          archive: this.archive ? true : undefined,
        };
      },
      dateRangeText() {
        let result = null;
        if (this.start && this.end) {
          const start = DateHelper.formatDate(
            new Date(this.start),
            this.dateRangeComponent.locale.format,
          );

          const end = DateHelper.formatDate(
            new Date(this.end),
            this.dateRangeComponent.locale.format,
          );
          const { separator } = this.dateRangeComponent.locale;
          result = `${start} ${separator} ${end}`;
        }
        return result;
      },
      isAppliedDeviceFilter() {
        return Boolean(this.$route.query.device_id);
      },
      selectedItemId() {
        if (RouterHelper.isDualMode) {
          const routeSecondary = RouterHelper.getRouteSecondary(this.$route);

          if (routeSecondary?.name === 'sequence child') {
            return routeSecondary.params.sid;
          }
          return routeSecondary?.params?.id;
        }
        return null;
      },

      hasItemsInList() {
        return Boolean(this.dataFormatted.length);
      },
      lastItemInList() {
        return this.results ? this.results[this.results.length - 1] : null;
      },
      hasNextPage() {
        const resultsCount = this.results?.length;
        return Boolean(
          !this.didServerReturnNoResults && resultsCount && resultsCount % MAX_ITEMS_ON_PAGE === 0,
        );
      },
    },

    watch: {
      entity: {
        handler() {
          this.init();
        },
        immediate: true,
      },
      type() {
        this.search();
      },
      query() {
        this.search();
      },
      currentQ() {
        this.search();
      },
      deviceId() {
        this.search();
      },
      start() {
        this.search();
      },
      end() {
        this.search();
      },
    },

    beforeRouteUpdate(to, from, next) {
      this.useRouteQuery(to.query);
      next();
    },

    created() {
      this.search();
    },

    methods: {
      async init() {
        this.useRouteQuery(this.$route.query);
        this.devices = await apiDevices.getDevices();
      },
      updateDateRange(newDateRange) {
        this.dateRangeComponent.dateRange = newDateRange;
        this.start = startOfDay(new Date(newDateRange.startDate)).toISOString();
        this.end = endOfDay(new Date(newDateRange.endDate)).toISOString();
      },
      resetDateRange() {
        this.start = null;
        this.end = null;
        this.dateRangeComponent.dateRange = this.dateRangeComponent.anyDate;
      },
      useRouteQuery(q) {
        this.type = q.type ?? this.entity;
        this.query = q.query;
        this.deviceId = q.device_id;
        this.archive = q.archive === true || q.archive === 'true';

        if (typeof q.start === 'string' && typeof q.end === 'string') {
          const parsedRange = {
            startDate: new Date(q.start),
            endDate: new Date(q.end),
          };

          this.updateDateRange(parsedRange);
        } else {
          this.resetDateRange();
        }
      },
      search: debounce(async function () {
        this.didServerReturnNoResults = false;

        const requestId = generateId();
        this.activeRequestId = requestId;

        this.error = null;
        let queryText = this.query;
        if (queryText === '') queryText = undefined;
        const q = this.currentQ;
        this.$router.replace(
          {
            name: 'search',
            query: {
              ...q,
              isOnlyQueryUpdate: 'primary',
            },
          },
          () => {
            //stub
          },
        );
        try {
          this.isLoading = true;

          await SearchAPI.search(
            this.type,
            this.query,
            true,
            this.deviceId,
            this.archive,
            this.start,
            this.end,
            (r) => {
              if (this.activeRequestId === requestId) {
                this.results = r;
              }
            },
            this.onError,
          );
        } finally {
          this.isLoading = false;
        }
      }, 25),
      async searchNextPage(dateEnd) {
        this.error = null;

        try {
          this.isLoadingMoreItems = true;
          this.didServerReturnNoResults = false;

          await SearchAPI.search(
            this.type,
            this.query,
            true,
            this.deviceId,
            this.archive,
            this.start,
            dateEnd,
            (r) => {
              if (!r?.length) {
                this.didServerReturnNoResults = true;
              }

              this.results.push(...r);
            },
            this.onError,
          );
        } finally {
          this.isLoadingMoreItems = false;
        }
      },
      onError(json, code) {
        this.results = null;
        this.error = code;
      },

      showCreateModal() {
        switch (this.entity) {
          case 'sample':
            this.$modal.show('modalSampleNew');
            break;
          case 'calibration':
            this.$modal.show('modalCalibrationNew');
            break;
          case 'sequence':
            this.$modal.show('modalSequenceNew');
        }
      },
      showMore() {
        // To exclude lastItemInList from results
        const updatedDate = minusOneSecond(this.lastItemInList.created);
        this.searchNextPage(updatedDate);
      },
    },
  };
</script>

<style scoped>
  .search-block--daterange-picker >>> .reportrange-text {
    padding: 0;
    border: 0;
  }

  .search-block--daterange-picker >>> .opensleft {
    right: -8px;
  }

  /* Note: fix moving right calendar-table below left one
    Find out at chromium 73.0.3683.75, 1366x768
  */
  @media (min-width: 564px) {
    .search-block--daterange-picker >>> .calendar-table {
      padding-right: 3px !important;
    }
  }
</style>

<style lang="scss">
  .search-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .search__inputs {
    width: 100%;
    margin-bottom: 8px;
  }

  .header-text p,
  .header-text h1 {
    display: inline-block;
  }
  .header-text h1 {
    padding-right: 24px;
  }

  .sequence-name {
    max-width: 64px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    display: inline-block;
  }
</style>

<style lang="scss" scoped>
  .search-page {
    &__actions {
      display: flex;
      align-items: center;
    }

    &__navigation {
      display: flex;

      @media (max-width: $screen-xxs-max) {
        flex-direction: column;
      }
    }

    &__inputs {
      display: grid;
      grid-template-columns: 2fr auto 1fr;
      gap: 8px;
      margin-bottom: 32px;

      @media (max-width: $screen-sm-max) {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 20px;
      }
    }

    &__wrapper-input-name {
      flex: 1 0 300px;
      display: flex;
      flex-direction: column;

      @media (max-width: $screen-sm-max) {
        grid-column: span 2;
      }
    }

    &__input-name {
      margin-bottom: 4px;
    }

    &__input-device {
      min-width: 100px;
      display: inline-block;
      height: 32px;

      @media (min-width: $screen-md) {
        max-width: 140px;
      }
    }

    &__input-date {
      min-width: 220px;

      @media (max-width: $screen-sm-max) {
        min-width: 170px;
      }
    }
  }
</style>
