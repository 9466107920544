import AccountPage from 'components/page/AccountPage.vue';
import ColumnsPage from 'components/page/ColumnsPage.vue';
import ColumnDetails from 'components/page/ColumnDetails.vue';
import CalibrationNewPage from 'components/page/CalibrationNewPage.vue';
import CalibrationPage from 'components/page/CalibrationPage.vue';
import DevicesPage from 'components/page/DevicesPage.vue';
import DevicePage from 'components/page/DevicePage.vue';
import MethodsPage from 'components/page/MethodsPage.vue';
import MethodDetails from '@/components/page/MethodDetails.vue';
import MethodPreview from '@/components/page/MethodPreview.vue';
import SamplePage from 'components/page/SamplePage.vue';
import SampleNewPage from 'components/page/SampleNewPage.vue';
import SequencePage from 'components/page/SequencePage.vue';
import SequenceNewPage from 'components/page/SequenceNewPage.vue';
import SearchPage from 'components/page/SearchPage.vue';
import ChromatogramsComparePage from 'components/page/ChromatogramsComparePage.vue';
import DeviceTerminalPage from 'components/page/DeviceTerminalPage.vue';
import OrganizationPage from '@/components/page/OrganizationPage.vue';
import InvitationToOrganizationPage from '@/components/page/InvitationToOrganizationPage.vue';
import PostprocessingTemplatesPage from '@/components/page/PostprocessingTemplatesPage.vue';

const defaultMode = {
  path: '',

  component: () => import('@/layouts/Default.vue'),
  children: [
    {
      path: '/',
      redirect: '/devices/',
    },
    {
      path: '/devices/',
      name: 'devices',
      meta: {
        colorBg: 'gray',
      },
      component: DevicesPage,
    },
    {
      path: '/devices/:id([a-zA-Z0-9]+)/',
      name: 'device',
      component: DevicePage,
      meta: {
        primaryView: 'dual.devices',
      },
      props: (route) => ({ id: route.params.id }),
    },
    {
      path: '/devices/:id([a-zA-Z0-9]+)/terminal/',
      name: 'deviceTerminal',
      component: DeviceTerminalPage,
      props: (route) => ({ id: route.params.id }),
    },
    {
      path: '/samples/',
      name: 'samples',
      component: SearchPage,
    },
    {
      path: '/samples/new/',
      name: 'sample new',
      meta: {
        primaryView: 'dual.samples',
        colorBg: 'gray',
      },
      component: SampleNewPage,
    },
    {
      path: '/samples/:id(\\d+)/',
      name: 'sample',
      component: SamplePage,
      meta: {
        primaryView: 'dual.samples',
        colorBg: 'dark-gray',
      },
      props: (route) => ({ id: parseInt(route.params.id, 10), iid: null }),
    },
    {
      path: '/samples/:id(\\d+)/injection/:iid(\\d+)/',
      name: 'sample injection',
      component: SamplePage,
      meta: {
        primaryView: 'dual.samples',
        colorBg: 'dark-gray',
      },
      props: (route) => ({
        id: parseInt(route.params.id, 10),
        iid: parseInt(route.params.iid, 10),
      }),
    },
    {
      path: '/samples/:id(\\d+)/injection/new/',
      name: 'sample injection new',
      component: SamplePage,
      meta: {
        primaryView: 'dual.samples',
        colorBg: 'dark-gray',
      },
      props: (route) => ({
        id: parseInt(route.params.id, 10),
        iid: -1,
      }),
    },
    {
      path: '/calibrations/',
      name: 'calibrations',
      component: SearchPage,
    },
    {
      path: '/calibrations/new/',
      name: 'calibration new',
      component: CalibrationNewPage,
      meta: {
        primaryView: 'dual.calibrations',
        colorBg: 'gray',
      },
    },
    {
      path: '/calibrations/:id(\\d+)/',
      name: 'calibration',
      component: CalibrationPage,
      meta: {
        primaryView: 'dual.calibrations',
        colorBg: 'dark-gray',
      },
      props: (route) => ({ id: parseInt(route.params.id, 10), iid: null }),
    },
    {
      path: '/calibrations/:id(\\d+)/injection/:iid(\\d+)/',
      name: 'calibration injection',
      component: CalibrationPage,
      meta: {
        primaryView: 'dual.calibrations',
        colorBg: 'dark-gray',
      },
      props: (route) => ({
        id: parseInt(route.params.id, 10),
        iid: parseInt(route.params.iid, 10),
      }),
    },
    {
      path: '/calibrations/:id(\\d+)/injection/new/',
      name: 'calibration injection new',
      component: CalibrationPage,
      meta: {
        primaryView: 'dual.calibrations',
        colorBg: 'dark-gray',
      },
      props: (route) => ({
        id: parseInt(route.params.id, 10),
        iid: -1,
        defaultAmount: route.query.amount,
      }),
    },
    {
      path: '/chromatograms-compare/',
      name: 'chromatograms compare',
      component: ChromatogramsComparePage,
      props: (route) => {
        let ids = null;
        try {
          ids = route.query.ids?.split('.')?.map((i) => parseInt(i, 10));
        } catch (e) {
          //stub
        }

        return {
          ids,
        };
      },
    },
    {
      path: '/sequences/',
      name: 'sequences',
      component: SearchPage,
    },
    {
      path: '/sequences/new/',
      name: 'sequence new',
      component: SequenceNewPage,
      meta: {
        primaryView: 'dual.sequences',
        colorBg: 'gray',
      },
    },
    {
      path: '/sequences/:id(\\d+)/',
      name: 'sequence',
      component: SequencePage,
      meta: {
        primaryView: 'dual.sequences',
        colorBg: 'dark-gray',
      },
      props: (route) => ({ id: parseInt(route.params.id, 10), sid: null, iid: null }),
    },
    {
      path: '/sequences/:id(\\d+)/:sid(\\d+)',
      name: 'sequence child',
      component: SequencePage,
      meta: {
        primaryView: 'dual.sequences',
        colorBg: 'dark-gray',
      },
      props: (route) => ({
        id: parseInt(route.params.id, 10),
        sid: parseInt(route.params.sid, 10),
        iid: null,
      }),
    },
    {
      path: '/sequences/:id(\\d+)/:sid(\\d+)/:iid(\\d+)',
      name: 'sequence child injection',
      component: SequencePage,
      meta: {
        primaryView: 'dual.sequences',
        colorBg: 'dark-gray',
      },
      props: (route) => ({
        id: parseInt(route.params.id, 10),
        sid: parseInt(route.params.sid, 10),
        iid: parseInt(route.params.iid, 10),
      }),
    },
    {
      path: '/sequences/:id(\\d+)/:sid(\\d+)/new',
      name: 'sequence child injection new',
      component: SequencePage,
      meta: {
        primaryView: 'dual.sequences',
        colorBg: 'dark-gray',
      },
      props: (route) => ({
        id: parseInt(route.params.id, 10),
        sid: parseInt(route.params.sid, 10),
        iid: -1,
      }),
    },
    {
      path: '/search/',
      name: 'search',
      component: SearchPage,
    },
    {
      path: '/columns/',
      name: 'columns',
      component: ColumnsPage,
    },
    {
      path: '/columns/:columnId(\\d+)/',
      name: 'columnDetails',
      meta: {
        primaryView: 'dual.columns',
      },
      component: ColumnDetails,
      props: (route) => ({
        columnId: Number(route.params.columnId),
      }),
    },
    {
      path: '/methods/',
      name: 'methods',
      component: MethodsPage,
    },
    {
      path: '/methods/:methodId(\\d+)/',
      name: 'methodDetails',
      meta: {
        primaryView: 'dual.methods',
      },
      component: MethodDetails,
      props: (route) => ({
        methodId: Number(route.params.methodId),
      }),
    },
    {
      path: '/methods/preview/:methodId(\\d+)/',
      name: 'methodPreview',
      component: MethodPreview,
      // meta: {
      //   primaryView: 'dual.methods',
      // },
      props: (route) => ({
        methodId: Number(route.params.methodId),
      }),
    },
    {
      path: '/postprocessing-templates/',
      name: 'postprocessingTemplates',
      component: PostprocessingTemplatesPage,
    },
    // {
    //   path: '/postprocessing-templates/:postprocessingTemplateId(\\d+)/',
    //   name: 'postprocessingTemplateDetails',
    //   meta: {
    //     primaryView: 'dual.postprocessingTemplates',
    //   },
    // },
    {
      path: '/account/',
      name: 'account',
      meta: {
        colorBg: 'gray',
      },
      component: AccountPage,
    },
    {
      path: '/organization/',
      name: 'organization',
      meta: {
        colorBg: 'gray',
      },
      component: OrganizationPage,
    },
    {
      path: '/invitation-to-organization/:token/',
      name: 'invitationToOrganization',
      meta: {
        colorBg: 'gray',
      },
      component: InvitationToOrganizationPage,
    },
  ],
};

export default defaultMode;
